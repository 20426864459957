import React from 'react';
import ToggleFilterIconCollapsed from '../../../../../../../assets/icons/toggle-filter-icon-collapsed.png';
import ToggleFilterIconExpanded from '../../../../../../../assets/icons/toggle-filter-icon-expanded.png';
import { Button } from '../../../../../../../components/MuiGenerals';
import { useTranslation } from '../../../../../../../hooks';

interface IProps {
  isCollapse?: boolean;
  onClick?: () => void;
}

const ToggleFilterButton = (props: IProps) => {
  const { isCollapse, onClick } = props;

  const { t } = useTranslation('menu');

  const buttonText = isCollapse ? t('toggleFilterButtonExpand') : t('toggleFilterButtonCollapse');
  const buttonIconSrc = isCollapse ? ToggleFilterIconCollapsed : ToggleFilterIconExpanded;

  return (
    <>
      <Button variant="outlined" onClick={onClick}>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={buttonIconSrc}
              alt="toggle-filter-icon"
              style={{ width: '20px', height: '20px' }}
            />
          </div>
          <div>{buttonText}</div>
        </div>
      </Button>
    </>
  );
};

export default ToggleFilterButton;
