import React from "react";
import {
    DataGrid,
    GridColDef,
    GridRowsProp,
} from "@mui/x-data-grid";

import { Container } from "../../../../components/MuiGenerals";
import { Iprefix } from "../../../../hooks/useTranslation";
import {
    CustomPagination,
    NoRowsOverlay,
    TableTab,
} from "../../../../components/Layout";
import { useListMappingTransform } from "../../../../hooks";
import { customSx } from "../../../../utils/styling";
import { useGenGridCol } from "../../../../utils/ComponentHelper";
import { LoadingDialog } from "../../../../components";
import { dataGridDefaults } from "../../../../utils/constant";

interface ITableBoardProps {
    translatePrefix: Iprefix;
    data: any;
    zusParams: any;
    columnKeys: any[];
    mappingFunction: any;
}

const TableBoard: React.FC<ITableBoardProps> = ({
    translatePrefix,
    data,
    columnKeys,
    zusParams,
    mappingFunction
}) => {

    return <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
    >
        <TableList data={data} columnKeys={columnKeys} zusParams={zusParams} translatePrefix={translatePrefix} mappingFunction={mappingFunction} />
    </Container>;
};

function TableList(props: { data: any, columnKeys: any[], zusParams: any, translatePrefix: Iprefix, mappingFunction: any }) {
    const { data, columnKeys, zusParams, translatePrefix, mappingFunction } = props;
    const listMapping = useListMapping("table", translatePrefix, mappingFunction);

    const count = data?.count || data?.length || 0;

    const omitKeys: string[] = [];

    const content: GridRowsProp = listMapping(data.rows, omitKeys);

    const columns: GridColDef[] = columnKeys.map((key) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useGenGridCol(key);
    });

    const TABLE_ROWS_PER_PAGE = 20;

    if (data?.isLoading) return <LoadingDialog forceOpen={true} />;
    return (
        <>
            <DataGrid
                {...dataGridDefaults}
                rows={content}
                rowCount={count}
                columns={columns}
                page={zusParams?.body?.page}
                onPageChange={zusParams?.setPage}
                components={{
                    NoRowsOverlay,
                    Footer: CustomPagination,
                }}
                componentsProps={{
                    footer: { totalRecords: count },
                }}
                pageSize={TABLE_ROWS_PER_PAGE}
            />
        </>
    );
}

const useListMapping = (mode: "export" | "table", translatePrefix: Iprefix, mappingFunction: any) => {
    const listMappingTransform = useListMappingTransform(mode);

    const listMapping = (array: any[], omitKeys: string[] = []) => {
        const res = mappingFunction(array, omitKeys);

        const output = res.map(listMappingTransform);
        return output;
    };
    return listMapping;
};

export default TableBoard;
