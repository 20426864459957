import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useQuery } from "react-query";

import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
} from "@mui/x-data-grid";
import {
  EnumApprovalStatus,
  EnumDepositStatus,
} from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master";

import { LoadingDialog } from "../../../components";
import { ExportBtn } from "../../../components/Button";
import {
  CustomPagination,
  NoRowsOverlay,
  TableTab,
} from "../../../components/Layout/";
import { Container } from "../../../components/MuiGenerals";
import ApprovalProgressDialog from "./components/ApprovalProgress/ApprovalProgressDialog";
import ApprovalProgressTableCell from "./components/ApprovalProgress/ApprovalProgressTableCell";
import {
  useAlerting,
  useGetMerchant,
  useListMappingTransform,
  usePermission,
  useTranslation,
} from "../../../hooks";
import { useGenGridCol } from "../../../utils/ComponentHelper";
import { dataGridDefaults } from "../../../utils/constant";
import {
  displayAmountCurrying,
  downloadFiles,
  getDecimal,
  getFullApiResponse,
  toDisplayTime,
} from "../../../utils/helper";
import { customSx } from "../../../utils/styling";
import {
  apiObj as api,
  omitKeyObj,
  PermissionKey,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from "./config";
import useCurrencyList from "../../../hooks/useCurrencyList";
import OperationsCell from "./components/OperationsCell";
import ApproveRejectDialog from "../../../features/Approval/ApproveReject/ApproveRejectDialog";
import { useAppSelector } from "../../../reducer/hooks";
import { selectProfile } from "../../../reducer/profileSlice";
import getApproveProgress from "../../../features/Approval/helpers/getApproveProgress";

function ViewTable() {
  const { alerting } = useAlerting();
  const { tc } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const [count, setCount] = useState(0);

  const listMapping = useListMapping("export");
  const { hasPermission } = usePermission();

  const onExport = async () => {
    if (count === 0) {
      return alerting("error", tc("no_data_export"));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      api.export({ ...zusParams.body, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiFn, count, true);
    if (rawRes.length === 0) return;
    const omitKeys = omitKeyObj.export;

    const res = listMapping(rawRes, omitKeys);
    const config = {};
    downloadFiles(`Merchant Deposit Request`, res, config);
  };

  return (
    <Container
      style={customSx.datagridContainer}
      maxWidth={false}
      disableGutters
    >
      <ExportBtn
        isShown={hasPermission(PermissionKey.Export)}
        onExport={onExport}
      />
      <TableTab>
        <TableList setCount={setCount} />
      </TableTab>
    </Container>
  );
}

function TableList(props: { setCount: Dispatch<SetStateAction<number>> }) {
  const { setCount } = props;

  const [isApprovalProgressDialogOpen, setIsApprovalProgressDialogOpen] =
    useState(false);

  const [isApproveRejectDialogOpen, setIsApproveRejectDialogOpen] =
    useState(false);

  const [selectedRow, setSelectedRow] = useState<any>({});

  const profile = useAppSelector(selectProfile);
  const zusParams = useZusParams();
  const { hasPermission } = usePermission();
  const { t } = useTranslation(translatePrefix);

  const isHideOperationColumn = !(
    hasPermission(PermissionKey.Approve.prefix) ||
    hasPermission(PermissionKey.Reject)
  );

  const listRes = useQuery({
    queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],
    queryFn: () => api.table(zusParams.body),
  });

  const refreshTable = () => {
    zusParams.refetch();
  };

  const { rows = [], count = 0 } =
    (listRes.data as any as { rows: any[]; count: number }) || {};

  const omitKeys = omitKeyObj.table;
  const listMapping = useListMapping("table");
  const content: GridRowsProp = listMapping(rows, omitKeys);

  const getApproveHistory = () => {
    if (!selectedRow.rawData) return [];

    const isTxRejected =
      selectedRow.rawData.status === EnumDepositStatus.Rejected;

    if (isTxRejected) {
      const { request_approvals } = selectedRow.rawData;

      return request_approvals.filter((item: any) => {
        return item.status !== EnumApprovalStatus.Pending;
      });
    }

    return selectedRow?.rawData?.request_approvals;
  };

  const approveHistory = getApproveHistory();

  const handleApprovalProgressButtonClick = (params: GridRenderCellParams) => {
    setSelectedRow(params.row);
    setIsApprovalProgressDialogOpen(true);
  };

  const closeApprovalProgressDialog = () => {
    setIsApprovalProgressDialogOpen(false);
  };

  const handleApproveRejectButtonClick = (params: GridRenderCellParams) => {
    setSelectedRow(params.row);
    setIsApproveRejectDialogOpen(true);
  };

  const closeApproveRejectDialog = () => {
    setIsApproveRejectDialogOpen(false);
  };

  useEffect(() => setCount(count), [listRes]);

  const columns: GridColDef[] = [
    useGenGridCol(TK.operations, {
      renderCell: (params) => {
        return (
          <OperationsCell
            handleApproveRejectCellClick={() =>
              handleApproveRejectButtonClick(params)
            }
            isApproveRejectCellDisabled={
              !(params.row.rawData.status === EnumDepositStatus.Pending)
            }
          />
        );
      },
    }),
    useGenGridCol(TK.merchantName, {
      minWidth: 150,
    }),
    useGenGridCol(TK.orderId, {
      minWidth: 150,
    }),
    useGenGridCol(TK.approvalProgress, {
      minWidth: 120,
      renderCell: (params) => {
        return (
          <ApprovalProgressTableCell
            params={params}
            onButtonClick={() => handleApprovalProgressButtonClick(params)}
          />
        );
      },
    }),
    useGenGridCol(TK.currency),
    useGenGridCol(TK.amount, {
      minWidth: 150,
    }),
    useGenGridCol(TK.status),
    useGenGridCol(TK.createdBy, {
      minWidth: 150,
    }),
    useGenGridCol(TK.creationTime, { minWidth: 150 }),
    useGenGridCol(TK.lastModifiedTime, {
      minWidth: 150,
    }),
  ].filter((col) => {
    if (isHideOperationColumn && col.field === TK.operations) return false;

    return true;
  });

  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;

  return (
    <>
      <ApprovalProgressDialog
        title={t("approvalProgressDialogTitle")}
        isOpen={isApprovalProgressDialogOpen}
        closeDialog={closeApprovalProgressDialog}
        approveHistory={approveHistory}
      />
      <ApproveRejectDialog
        title={t("approve_reject_dialog_title")}
        isOpen={isApproveRejectDialogOpen}
        closeDialog={closeApproveRejectDialog}
        refreshTable={refreshTable}
        PermissionKey={PermissionKey}
        approveFn={api.approve}
        rejectFn={api.reject}
        rowHeaders={[
          t(TK.merchantName),
          t(TK.currency),
          t(TK.amount),
        ]}
        data={{
          rows: [
            selectedRow.merchant_name,
            selectedRow.currency,
            selectedRow.amount,
          ],
          createdBy: selectedRow.created_by,
          operator: profile.email,
          numberOfApproved: selectedRow.rawData?.approvalsCount,
          numberOfApprover: selectedRow.rawData?.approvalsRequired,
          approveHistory: approveHistory,
        }}
      />

      <DataGrid
        {...dataGridDefaults}
        rows={content}
        rowCount={count}
        columns={columns}
        page={zusParams.body.page}
        onPageChange={zusParams.setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: count },
        }}
      />
    </>
  );
}

const useListMapping = (mode: "table" | "export") => {
  const { t } = useTranslation("enumConstants");

  const listMappingTransform = useListMappingTransform(mode);

  const merchantObj = useGetMerchant();
  const { currencyList } = useCurrencyList();

  const listMapping = (
    array: any[],

    omitKeys: string[] = []
  ) => {
    const res = array.map((item: any) => {
      const decimal = getDecimal(item.currency, currencyList);
      const toDisplayAmount = displayAmountCurrying(decimal);

      const approvalProgress = getApproveProgress(item.approvalsRequired, item.approvalsCount);

      const mappedResult = [
        [TK.merchantName, merchantObj[item.merchantId]],
        [TK.orderId, item.orderId],
        [TK.approvalProgress, approvalProgress],
        [TK.currency, item.currency],
        [TK.amount, toDisplayAmount(item.amount)],
        [TK.status, t(EnumDepositStatus[item.status])],
        [TK.createdBy, item.createdBy],
        [TK.creationTime, toDisplayTime(item.createdDate)],
        [TK.lastModifiedTime, toDisplayTime(item.lastModifiedDate)],
        ["rawData", item],
      ].filter(([key]) => !omitKeys.includes(key));
      return mappedResult;
    });

    const output = res.map(listMappingTransform);
    return output;
  };
  return listMapping;
};

export default ViewTable;
