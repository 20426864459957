import { Box } from "../../../../../../../components/MuiGenerals";

const Row = ({ children }: { children: React.ReactNode }) => {
  const rowStyle = {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    marginBottom: "12px",
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiInputLabel-shrink": {
      display: "none",
    },
  };

  return <Box sx={rowStyle}>{children}</Box>;
};

export default Row;
