import { ReactNode, useState } from "react";

import { TextFieldProps } from "@mui/material/TextField";

import { Box } from "../MuiGenerals";
import MpTextField from "./MpTextField";
import MpTextFieldNumberOnly, {
  MpTextFieldNumberProps,
} from "./MpTextFieldNumberOnly";

type Props = (
  | ({ mode: "number" } & MpTextFieldNumberProps)
  | ({ mode: "free" } & TextFieldProps)
) & {
  endAdornmentNode: ReactNode;
  isHideAdornment?: boolean;
};

export default function MpTextFieldWithEndAdornment({
  value,
  endAdornmentNode,
  mode,
  isHideAdornment,
  ...props
}: Props) {
  const [isFocus, setIsFocus] = useState(false);

  const commonProps: TextFieldProps = {
    value,
    onFocus: (e) => {
      setIsFocus(true);
      props.onFocus && props.onFocus(e);
    },
    onBlur: (e) => {
      setIsFocus(false);
      props.onBlur && props.onBlur(e);
    },
    InputProps: {
      endAdornment: (value || isFocus) && !isHideAdornment && (
        <Box sx={{ position: "relative", width: "36px" }}>
          <Box
            sx={{ position: "absolute", right: 0, top: 0, userSelect: "none" }}
          >
            {endAdornmentNode}
          </Box>
        </Box>
      ),
      ...props.InputProps,
    },
  };

  return mode === "number" ? (
    <MpTextFieldNumberOnly {...props} {...commonProps} />
  ) : (
    <MpTextField {...props} {...commonProps} />
  );
}
