import BigNumber from "bignumber.js";
import { addHours, endOfDay, endOfSecond } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import dateFormat from "date-fns/format";
import isValidTime from "date-fns/isValid";
import dateParse from "date-fns/parseISO";
import { saveAs } from "file-saver";
import { parse as parseCsv } from "json2csv";
import JSZip from "jszip";
import jwt_decode from "jwt-decode";

import { Currency } from "../reducer/currencyListSlice";
import { store } from "../reducer/store";
import { endLoading, startLoading } from "../reducer/stuffSlice";
import { dateTimeInFormat } from "./config";
import { csvRecordDivisor, csvRecordPageSize } from "./constant";

export function generateNonce() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function checkIsValidJSON(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const readRefreshToken = () =>
  localStorage.getItem("refreshToken") || "";

export const readAccessToken = () => localStorage.getItem("accessToken") || "";

export const writeTokens = ({ accessToken = "", refreshToken = "" }) => {
  localStorage.setItem(`accessToken`, accessToken);
  localStorage.setItem(`refreshToken`, refreshToken);
};

export const clearTokens = () => {
  localStorage.setItem(`accessToken`, "");
  localStorage.setItem(`refreshToken`, "");
};
export const decodeJwt = (token: string) => {
  try {
    return jwt_decode<{
      appUuid: string;
      loginId: number;
      email: string;
      username: string;
      status: number;
      role: number[];
      merchantId: string;
      ip: string;
      iat: number;
      exp: number;
      sessionExpires?: number;
      features?: Record<string, boolean>;
    }>(token);
  } catch (err) {
    console.error(err);
    return null;
  }
};

type ValueOf<T> = T[keyof T];

export function enumMapping<T extends Record<string, string>>(
  target: T
): T & Record<ValueOf<T>, keyof T> & Record<string, string> {
  let preObj: any = {};
  for (let key in target) {
    let value = String(target[key as keyof typeof target]);
    preObj[key] = value;
    preObj[value] = key;
  }
  return preObj;
}

export function sortAlphabetically(arr: any[]) {
  return arr.sort((a: any, b: any) => a[0].localeCompare(b[0]));
}

//only allow decimal/int
export function containsOnlyNumbers(str: string) {
  return /^[0-9]{0,}(,[0-9]{3})*(([\\.]{1}[0-9]*)|())$/.test(str);
}

export const containsOnlyInteger = (input: string) => {
  return /^$|^[0-9]+$/.test(input);
};

export function strToStrArrForFilter(str: string) {
  return str
    .split(",")
    .filter((item) => item)
    .map((item) => item.trim());
}

export function toTime(timeAny: any): Date | false {
  if (!timeAny) return false;
  const maybeTime = typeof timeAny === "string" ? dateParse(timeAny) : timeAny;
  if (!isValidTime(maybeTime)) {
    console.warn(`${maybeTime} is not valid Time`);
    return false;
  }
  return maybeTime;
}

export function searchEndOfSecond(timeAny: any) {
  const time = toTime(timeAny);
  if (!time) return "";
  return endOfSecond(time);
}
export function searchEndOfDay(timeAny: any) {
  const time = toTime(timeAny);
  if (!time) return "";
  return endOfDay(time);
}

export function toDBTime(timeAny: Date | string | null): string {
  const time = toTime(timeAny);
  if (!time) return "";
  const newTime = zonedTimeToUtc(time, "UTC");
  return newTime.toISOString();
}

type apiFn = (page: number, pageSize: number, signal?: any) => any;

async function getFullApiMap(
  apiFn: apiFn,
  times: number,
  pageSize: number,
  exportApi?: boolean
) {
  store.dispatch(startLoading(0));
  let resArray: any = [];
  if (exportApi) {
    for (let i = 0; i < times; i++) {
      resArray = [...resArray, ...(await apiFn(i, pageSize))];
      store.dispatch(startLoading((i + 1) / times));
    }
    return resArray;
  } else {
    for (let i = 0; i < times; i++) {
      resArray.push(await apiFn(i, pageSize));
      store.dispatch(startLoading((i + 1) / times));
    }
    return resArray.flatMap((res: any) => res.rows);
  }
  // const apiArray = new Array(times)
  //   .fill(null)
  //   .map((_, page) => delayFn(() => apiFn(page, pageSize), delay * page));
  // return axios.all(apiArray);
}

export async function getFullApiResponse(
  rawApiFn: apiFn,
  total: number,
  exportApi?: boolean
): Promise<any[]> {
  const pageSize = csvRecordPageSize;
  const times = Math.ceil(total / pageSize);
  // const estimatedTime = (times - 1) * delay;
  const controller = new AbortController();
  const apiFn = (page: number, pageSize: number) =>
    rawApiFn(page, pageSize, controller.signal);
  let allRes = [];
  try {
    allRes = await getFullApiMap(apiFn, times, pageSize, exportApi);
  } catch (err) {
    controller.abort();
  } finally {
    store.dispatch(endLoading());
  }
  return allRes;
  // const resFn = async () =>
  //   (await getFullApiMap(apiFn, times, pageSize)).flatMap(
  //     (res: any) => res?.rows
  //   );
  // const onError = () => {
  //   controller.abort();
  //   store.dispatch(endLoading());
  //   return [];
  // };
  // store.dispatch(startLoading(estimatedTime));
  // return await resFn().catch(onError);
}

export function todaysDateForFileName() {
  return dateFormat(new Date(), "yyyyMMddhhmmss");
}

export function toCsv(data: any) {
  let csv: BlobPart[] = [];
  try {
    csv = [parseCsv(data)];
  } catch (err) {
    console.error(err);
  }
  return new Blob(csv, { type: "text/csv;charset=utf-8" });
}

export function downloadCsv(filename: string, data: any, config?: any) {
  if(config.isRawString){
    const name = `${filename}.csv`;
    saveAs(new Blob([data], { type: "text/csv;charset=utf-8" }), name);
    return;
  }
  const blob = toCsv(data);
  const name = `${filename}.csv`;
  saveAs(blob, name);
}
/**
 example
    const data = [
      { a: 1, b: 2 },
      { a: 3, b: 4 },
    ];
    const config={
      prefix: "custom name",
    }
    downloadInZip("exam", prepareCsv(data), config);
 */
interface zipFace {
  name: string;
  data: any;
}
export function downloadInZip(
  zipName: string,
  list: zipFace[],
  config: any = {}
) {
  const { prefix = zipName, date = "" } = config;
  const zip = new JSZip();
  list.forEach(({ name, data }) => {
    const dateStr = date ? dateFormat(date, "yyyy-MM-dd") : "";
    const fileName = [prefix, dateStr, name].filter((s) => s).join("-");
    zip.file(fileName, data);
  });
  zip
    .generateAsync({ type: "blob" })
    .then((content) => saveAs(content, `${zipName}.zip`));
}

export function prepareCsv(data: any[] | string, divisor: number = csvRecordDivisor) {
  // handle raw csv string
  if (typeof data === "string"){
    const csvs = divideStringLines(data, divisor);
    return csvs.map((c, id) => ({ name: `${String(id)}.csv`, data: c }));
  }

  const csvs = divideToCsv(divisor, data);
  return csvs.map((c, id) => ({ name: `${String(id)}.csv`, data: c }));
}

function divideStringLines(str: string, divisor: number) {
  if (divisor <= 0) throw Error("divisor must >0");
  if (str.length === 0) throw Error("str");

  const lines = str.split("\n");
  const times = Math.ceil(lines.length / divisor);
  let array = [];
  for (let i = 0; i < times; i++) {
    const piece = lines.splice(-divisor).join("\n");
    array.push(piece);
  }
  return array;
}
function divideToCsv(divisor: number, data: any[]) {
  if (divisor <= 0) throw Error("divisor must >0");
  if (data?.length === 0) throw Error("empty data");
  const times = Math.ceil(data.length / divisor);
  let array = [];
  for (let i = 0; i < times; i++) {
    const piece = toCsv(data.splice(-divisor));
    array.push(piece);
  }
  return array;
}

export function downloadFiles(
  rawFilename: string,
  data: any[] | string,
  inputConfig?: {}
) {
  const config = { needDate: true, ...inputConfig };
  const timeStamp = todaysDateForFileName();
  const filename = config.needDate
    ? `${rawFilename}_${timeStamp}`
    : rawFilename;
  const divisor = csvRecordDivisor;
  if (data.length <= divisor) return downloadCsv(filename, data, config);
  return downloadInZip(filename, prepareCsv(data, divisor), config);
}

function timeTransform(timeAny: Date | string | null) {
  const time = toTime(timeAny);
  if (!time) return "";
  const localUTCdiff = Number(store.getState().profile.timezone) || 0;
  return addHours(time, localUTCdiff);
}

export function toDisplayTime(timeAny: Date | string | null): string {
  const time = timeTransform(timeAny);
  if (!time) return "";
  const newTime = utcToZonedTime(time, "UTC");
  return dateFormat(newTime, dateTimeInFormat);
}

export function copyToClipboard(textToCopy: string) {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(textToCopy);
  } else {
    // text area method
    let textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    // make the textarea out of viewport
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise<void>((res, rej) => {
      document.execCommand("copy") ? res() : rej();
      textArea.remove();
    });
  }
}

export function hexToRGBObj(hexCode: string) {
  const hexCodeNoHash = hexCode.substring(1);

  return {
    red: parseInt(hexCodeNoHash.substring(0, 2), 16),
    green: parseInt(hexCodeNoHash.substring(2, 4), 16),
    blue: parseInt(hexCodeNoHash.substring(4, 6), 16),
  };
}
export function hexToRGB(hexCode: string) {
  const hexCodeNoHash = hexCode.substring(1);

  return (
    parseInt(hexCodeNoHash.substring(0, 2), 16) +
    ", " +
    parseInt(hexCodeNoHash.substring(2, 4), 16) +
    ", " +
    parseInt(hexCodeNoHash.substring(4, 6), 16)
  );
}

export function rgbaFn(hexCode: string) {
  return (alphaValue: number) => `rgba(${hexToRGB(hexCode)}, ${alphaValue})`;
}

export function importantStyle(value: string | number) {
  return `${value} !important`;
}

export function amountDivideDecimals(number: string, decimals: number = 10) {
  if (Number.isNaN(decimals)) return "-";
  return BigNumber(number).dividedBy(Math.pow(10, decimals)).toString();
}

export function displayAmountCurrying(
  decimal: number | string,
  displayDecimal?: number
) {
  return (amount: string | number) => {
    const strAmount = String(amount);
    const numberDecimal = Number(decimal);
    const val = amountDivideDecimals(strAmount, numberDecimal);
    if (Number.isNaN(Number(val))) return "";
    return BigNumber(val).toFormat(displayDecimal || numberDecimal);
  };
}

export function listMappingTransform(
  mode: "table" | "export",
  t: (key: string, params?: {}) => string
) {
  return (item: any, index: number) => {
    const initValue = mode === "table" ? { index } : {};
    const reduceFn = (acc: any, [key, value]: [string, any]) => {
      const newObjKey = mode === "table" ? key : t(key);
      return { ...acc, [newObjKey]: value };
    };
    return item.reduce(reduceFn, initValue);
  };
}

export const getDecimal = (currency: string, currencyList: Currency[]) => {
  const currencyFound = currencyList.find((item) => item.currency === currency);

  if (!currencyFound) {
    return 0;
  }

  return currencyFound.decimal;
};

export const createThrottleFunc = (callback: any, delay = 1000) => {
  let timerId: any;

  return function (...args: any) {
    if (timerId) {
      return;
    }
    timerId = setTimeout(() => {
      callback(...args);
      timerId = null;
    }, delay);
  };
};

export const setClientDateTimeDiff = (serverTime: number = 0) => {
  let clientDateTimeDiff;

  if (!serverTime) {
    clientDateTimeDiff = 0;
  } else {
    clientDateTimeDiff = new Date().getTime() - serverTime * 1000;
  }

  localStorage.setItem("clientDateTimeDiff", String(clientDateTimeDiff));

  return clientDateTimeDiff;
};

export const getClientDateTimeDiffFromLocalStorage = () => {
  return Number(localStorage.getItem("clientDateTimeDiff")) || 0;
};

export function containsOnlySignedNumbers(onlyNegative?: boolean) {
  return function (str: string) {
    const regex = onlyNegative
      ? /^[-]?[0-9]{0,}(,[0-9]{3})*(([\\.]{1}[0-9]*)|())$/
      : /^[+-]?[0-9]{0,}(,[0-9]{3})*(([\\.]{1}[0-9]*)|())$/;

    return regex.test(str);
  };
}

export const containsOnlySignedInteger =
  (onlyNegative?: boolean) => (input: string) => {
    const regex = onlyNegative ? /^$|^[-]?[0-9]+$/ : /^$|^[-+]?[0-9]+$/;

    return regex.test(input);
  };

export const convertTrueFalseToYesNo = (value: boolean) => {
  return value ? "Yes" : "No";
}
