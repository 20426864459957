import React from 'react';

const Row = ({ title, content }: { title: string; content?: string | React.ReactNode }) => {  
    return (
      <div
        style={{
          display: "flex",
          marginBottom: "24px",
          width: "70%",
        }}
      >
        <div
          style={{
            display: "flex",
            marginRight: "48px",
            whiteSpace: "nowrap",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "50%",
          }}
        >
          {title}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            flex: 1,
            textAlign: "right",
            width: "50%",
          }}
        >
          {content || ""}
        </div>
      </div>
    );
  };
  
  export default Row;
  