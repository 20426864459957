import React from "react";
import { useTranslation } from "../../../hooks";
import { Iprefix } from "../../../hooks/useTranslation";
import { useQuery } from "react-query";
import { convertDateStringToYearMonthDayString } from "../../../utils/dateStringUtils";

interface IProps {
  translatePrefix: Iprefix;
  zusParams: any;
  columnsKey: string[];
  graphApi: any;
  dataApi: any;
  tableMapping: any;
  reloadDataFn?: any;
  reloadTableTarget?: any;
  reloadTableDataFn?: any;
}
const useDataAnalysis = ({
  translatePrefix,
  zusParams,
  columnsKey,
  graphApi,
  dataApi,
  tableMapping,
  reloadDataFn,
  reloadTableTarget,
  reloadTableDataFn,
}: IProps) => {
  const { t } = useTranslation(translatePrefix);

  const [data, setData] = React.useState<any>({
    rows: [],
    count: 0,
  });
  const [graphData, setGraphData] = React.useState<any>([]);
  const [isGraphDataLoading, setIsGraphDataLoading] =
    React.useState<boolean>(true);
  const [isDataLoading, setIsDataLoading] = React.useState<boolean>(true);

  const { refetch: refetchData } = useQuery({
    queryKey: [
      `${translatePrefix}`,
      zusParams.body[reloadTableTarget],
      zusParams.body.page,
      zusParams.body.settlementDateFrom,
      zusParams.body.settlementDateTo,
    ],
    queryFn: () => {
      setIsDataLoading(true);
      let params = {
        settlementDateFrom:
          convertDateStringToYearMonthDayString(
            zusParams.body.settlementDateFrom
          ) || "",
        settlementDateTo:
          convertDateStringToYearMonthDayString(
            zusParams.body.settlementDateTo
          ) || "",
        limit: 20,
        offset: 20 * zusParams.body.page,
      };
      if (zusParams.body[reloadTableTarget]) {
        params = {
          ...params,
          [reloadTableTarget]: zusParams.body[reloadTableTarget],
        };
      }
      return dataApi(params);
    },
    onSuccess: (res) => {
      if (!res) {
        return;
      }
      setData(res);
      setIsDataLoading(false);
    },

    enabled: true,
  });

  const { refetch: refetchGraph } = useQuery({
    queryKey: [
      `${translatePrefix}Graph`,
      zusParams.body.settlementDateFrom,
      zusParams.body.settlementDateTo,
    ],
    queryFn: () => {
      setIsGraphDataLoading(true);
      return graphApi({
        settlementDateFrom:
          convertDateStringToYearMonthDayString(
            zusParams.body.settlementDateFrom
          ) || "",
        settlementDateTo:
          convertDateStringToYearMonthDayString(
            zusParams.body.settlementDateTo
          ) || "",
      });
    },
    onSuccess: (res) => {
      if (!res) {
        return;
      }

      // convert negative values to positive for the graph display
      res?.amountData?.forEach((item: any) => {
        item.amount = Math.abs(item.amount);
        item.purchase = Math.abs(item.purchase);
        item.withdraw = Math.abs(item.withdraw);
      });
      res?.amountGraph?.forEach((item: any) => {
        item.result = Math.abs(item.result);
      });

      // sort the graph data, the graph need to sort from the highest to the lowest
      res?.amountGraph?.sort((a: any, b: any) => b.result - a.result);

      // convert negative values to positive for the graph display
      res?.countData?.forEach((item: any) => {
        item.transactionCount = Math.abs(item.transactionCount);
        item.purchase = Math.abs(item.purchase);
        item.withdraw = Math.abs(item.withdraw);
      });
      res?.countGraph?.forEach((item: any) => {
        item.result = Math.abs(item.result);
      });

      // sort the graph data, the graph need to sort from the highest to the lowest
      res?.countGraph?.sort((a: any, b: any) => b.result - a.result);
      setGraphData(res);
      setIsGraphDataLoading(false);
    },
    enabled: true,
  });

  const tableResultMappingFunction = (data: any, omitKeys: any) => {
    if (!data) return [];
    const res = data.map((item: any) => {
      let temp = tableMapping(item);
      const mappedResult = [...temp, ["rawData", item]].filter(
        ([key]) => !omitKeys.includes(key)
      );
      return mappedResult;
    });
    return res;
  };

  const reloadData = (settlementDateFrom: any, settlementDateTo: any) => {
    if (reloadDataFn) {
      reloadDataFn(settlementDateFrom, settlementDateTo);
      return;
    }

    zusParams.setBody({ settlementDateFrom, settlementDateTo, page: 0 });
    // setIsDataLoading(true);
    // setIsGraphDataLoading(true);
  };

  const reloadTableData = (filterValue: string) => {
    if (reloadTableDataFn) {
      reloadTableDataFn(filterValue);
      return;
    }
    if (reloadTableTarget) {
      zusParams.setBody({
        ...zusParams.body,
        [reloadTableTarget]: filterValue,
        page: 0,
      });
      setIsDataLoading(true);
    }
  };

  return {
    t,
    data,
    graphData,
    isGraphDataLoading,
    isDataLoading,
    tableResultMappingFunction,
    reloadData,
    reloadTableData,
    refetchData,
    refetchGraph,
  };
};

export default useDataAnalysis;
