import { GridRenderCellParams } from "@mui/x-data-grid";
import { Box, Tooltip } from "../../../../../components/MuiGenerals";

import { OpIconButton } from "../../../../../components";
import { useTranslation } from "../../../../../hooks";
import getDisplayApprovalProgress from "../../helpers/getDisplayApprovalProress";
import { OpRedirect } from "../../../../../assets/icons";
import { translatePrefix } from "../../config";
import getIsAutoApprove from "../../helpers/getIsAutoApprove";

interface ApprovalProgressTableCellProps {
  params: GridRenderCellParams;
  onButtonClick: () => void;
}

const ApprovalProgressTableCell = (props: ApprovalProgressTableCellProps) => {
  const { onButtonClick, params } = props;
  const { t } = useTranslation(translatePrefix);

  const isAutoApprove = getIsAutoApprove({
    numberOfApproved: params.row.rawData?.approvalsCount,
    numberOfApprover: params.row.rawData?.approvalsRequired,
    txStatus: params.row.rawData.status,
  });

  const cellText = getDisplayApprovalProgress({
    numberOfApproved: params.row.rawData?.approvalsCount,
    numberOfApprover: params.row.rawData?.approvalsRequired,
    isAuto: isAutoApprove,
  });

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Tooltip title={cellText}>
        <Box sx={{ marginRight: "12px" }}>{cellText}</Box>
      </Tooltip>

      {!isAutoApprove && (
        <OpIconButton
          title={t("approvalProgressDialogTitle")}
          size="1rem"
          svgUrl={OpRedirect}
          onClick={onButtonClick}
        />
      )}
    </Box>
  );
};

export default ApprovalProgressTableCell;
