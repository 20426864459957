const enumPortalUserYubiKeyStatus = { Unbound: "Unbound" }; // node-types

export const initStateForEdit = {
  id: "",
  chain_name: "",
  chain_id: "",
  chain_type: "",
  asset_name: "",
  // threshold_amount: "",
  hourly_auto_approval_limit: "",
  mx_hourly_limit: "",
  mx_addr_hrly_count: "",
  mx_addr_hrly_limit: "",
  configTime: "",
  // withdraw_approval_regulations: [],
  rawData: {
    chain_id: "",
    chain_type: "",
    // threshold_amount: "",
    hourly_auto_approval_limit: "",
    mx_addr_hrly_count: "",
    mx_addr_hrly_limit: "",
    mx_hourly_limit: "",
    withdraw_approval_regulations: [],
  },
};

export const initStateForWatch = {
  id: "",
  chain_id: "",
  chain_type: "",
  chain_name: "",
  asset_name: "",
  // existing_amount: "",
  // edited_amount: "",
  status: "",
  created_by: "",
  creation_time: "",
  operated_by: "",
  operation_time: "",
  hourly_limit: "",
  existing_hourly_limit: "",
  reqEditMaxNoTrxs: "",
  reqEditMaxLimitWithin1Hr: "",
  reqEditMaxNoTrxAmt: "",
  existing_to_addr_hourly_limit: "",
  existing_to_addr_hourly_count: "",
  existing_hourly_auto_approval_limit: "",
  hourly_auto_approval_limit: "",

  existing_mx_hourly_limit: "",
  withdraw_approval_regulation_requests: [],
};
export const initOnPortalFields = {
  api_url: "",
  req_date: "",
  req_header: "",
  req_body: "",
  res: "",
  res_date: "",
};
export const initMyYubiDetails = {
  id: "",
  pub_id: "",
  pub_id_in_dec: "",
  oper_status: "",
  yubi_key_status: enumPortalUserYubiKeyStatus.Unbound,
  date_last_yubi_key_updated: "",
  merchant_id: "",
  email: "",
  date_created: "",
  date_last_modified: "",
  proposals: [],
};

export const initAssetListContent = {
  chain_name: "",
  withdraw_status: "",
  chain_type: "",
  chain_id: "",
  asset_name: "",
  push_deposit_message: "",
  asset_config_id: "",
  rawData: { chain_type: "", chain_id: "" },
};
