import { SingleSelection } from "../../../../../../components";
import { useGetCardProfileName, useTranslation } from "../../../../../../hooks";
import { translatePrefix } from "../../../config";

interface IProps {
  value: any;
  onChange: (e: any) => void;
  onClearSelect: () => void;
  merchantId: string;
  programName: string;
}

const CardProfileNameSingleSelection = (props: IProps) => {
  const {
    value,
    onChange,
    onClearSelect,
    merchantId = "",
    programName = "",
  } = props;

  let CardProfileListEnum =
    useGetCardProfileName({ merchantId, programName }) || {};

  const { tc } = useTranslation(translatePrefix);

  return (
    <SingleSelection
      label={tc("phSelection", { fieldName: tc("cardProfileName") })}
      value={value}
      onChange={onChange}
      clearSelect={onClearSelect}
      enumData={CardProfileListEnum}
      nameFn={(item) => item}
      sx={{ formControl: { width: "70%" } }}
    />
  );
};

export default CardProfileNameSingleSelection;
