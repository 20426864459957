import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { Menu as RaMenu, MenuItemLink, useTranslate } from "react-admin";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDownOutlined";


import { usePermission } from "../../hooks";
import { useZusListStore } from "../../zustand/store";
import { COLOR } from "../../utils/config";


const sx = {
  menuPadding: {
    paddingLeft: "10px",
  },
  expanded: {
    color: COLOR.text.primary.hex,
    boxShadow: "none",
  },
  notExpanded: {
    color: COLOR.text.secondary.hex,
    boxShadow: "none",
  },
  muiAccordion: {
    "&.MuiAccordion-root:before": { content: "none" },
    "& .MuiAccordionSummary-expandIconWrapper": {
      transform: "rotate(270deg)",
      color: COLOR.text.secondary.hex,
    },
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(360deg)",
      ">svg": { color: `${COLOR.text.primary.hex} !important` },
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      maxHeight: "30px",
      margin: "5px 0px",
    },
  },
  accordionSummary: {
    "&.Mui-expanded": {
      minHeight: 0,
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "0px",
    },
  },
  accordionDetails: {
    padding: "5px",
    a: {
      color: `rgba(${COLOR.text.secondary.rgb}, 0.7)`,
      "&.RaMenuItemLink-active": {
        color: COLOR.text.primary.hex,
      },
    },
    "div": {
      "div:hover": {
        a: {
          backgroundColor: `${COLOR.background.secondary.hex} !important`,
          color: COLOR.text.secondary.hex,
          "&.RaMenuItemLink-active": {
            color: COLOR.text.primary.hex,
          },
        },
      },
    }
  },
};

function MenuStackWithSubTitle(props: any) {
  const { hasPermission } = usePermission();
  const zusListStore = useZusListStore();
  const resetZusList = () => zusListStore.clear();

  const translate = useTranslate();
  const t = (key: string) => translate(`menu.${key}`);

  const { title, list, selected, setSelected } = props;

  const expanded = selected === title;
  const [selectedPagePathname, setSelectedPagePathname] = useState(
    window.location.pathname.substring(1)
  );

  const toggle = () =>
    setSelected((key: string) => {
      if (key === title) return "";
      return title;
    });

  const canRender = (key: string) => {
    if (key === "*****") return true; //for temporary use
    return hasPermission(key);
  };

  const genItem = ([text, to, permission]: any = []) => {
    return (
      canRender(permission) && (
        <Box sx={{ width: "calc(100% - .5rem)", display: "flex" }} key={text}>
          <MenuItemLink
            to={to}
            primaryText={t(text)}
            sx={{ flex: "1" }}
            onClick={
              selectedPagePathname !== to
                ? () => {
                  resetZusList();
                  setSelectedPagePathname(to);
                }
                : undefined
            }
          />
        </Box>
      )
    );
  };

  const generateSubMenu = (item: any) => item.list.map(genItem).filter((subItem: any) => subItem !== false);

  const subMenus = list.map(generateSubMenu);
  const flattedSubMenu = subMenus.flat();
  const isHidden = flattedSubMenu.length === 0;
  if (isHidden) return <></>;

  return (
    <Accordion
      sx={{
        color: expanded ? sx.expanded : sx.notExpanded,
        ...sx.muiAccordion,
      }}
      expanded={expanded}
      onChange={toggle}
    >
      <AccordionSummary
        expandIcon={<ExpandCircleDownIcon />}
        aria-controls={title}
        sx={sx.accordionSummary}
      >
        <Typography variant="h6">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={sx.accordionDetails}>
        {
          list.map((item: any, index: number) => {
            const subMenuItems = generateSubMenu(item);
            if (subMenuItems.length > 0) {
              return (
                <Box key={item.title} sx={{
                  padding: "10px",
                }}>
                  <Typography>{item.title}</Typography>
                  <Box sx={{
                    borderBottom: `1px solid ${COLOR.text.secondary.hex}`,
                  }} />
                  <Typography variant="h6">{item.list.map(genItem)}</Typography>
                </Box>
              )
            }
          })
        }
      </AccordionDetails>
    </Accordion>
  );
}

export default MenuStackWithSubTitle;
