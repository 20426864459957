import { COLOR } from "../../utils/config";
import { Box } from "../MuiGenerals";
import DivideLine from "./DivideLine";

function FilterTable(props: {
  filter: JSX.Element;
  table: JSX.Element;
  divideLineBackgroundColor?: string;
}) {
  const { filter, table, divideLineBackgroundColor } = props;
  return (
    <Box
      sx={{
        backgroundColor: COLOR.background.secondary.hex,
        paddingTop: "2rem",
        paddingLeft: "2rem",
        paddingRight: "2rem",
        border: `1px solid ${COLOR.text.secondary.rgba(0.5)}`,
      }}
    >
      {filter}

      <DivideLine backgroundColor={divideLineBackgroundColor} />
      {table}
    </Box>
  );
}

export default FilterTable;
