import { ChangeEvent, cloneElement, useEffect, useState } from "react";
import { useTranslation } from "../../../../../../hooks";
import { initZusParams, translatePrefix } from "../../../config";
import { useDatePicker } from "../../../../../../components/DatePicker";
import {
  Box,
  Container,
  SelectChangeEvent,
} from "../../../../../../components/MuiGenerals";
import { useGenFilterField } from "../../../../../../utils/ComponentHelper";
import { customSx } from "../../../../../../utils/styling";
import {
  GeneralBtn,
  MpTextField,
  SingleSelection,
} from "../../../../../../components";
import {
  createZusInstance,
  useZusDialogStore,
} from "../../../../../../zustand/store";
import { Ipage } from "../../../../../../api/types";
import { get2400Date } from "../utls/get2400Date";

interface IFields {
  fromDate: string;
  toDate: string;
  limitResultTo: string;
}

interface IGetAllReq {
  fromDate: string;
  toDate: string;
  limitResultTo: string;
  customerNumber: string;
}

const initFields = {
  fromDate: "",
  toDate: "",
  limitResultTo: "25",
};

export const useZusParams = createZusInstance<Ipage & IGetAllReq>({
  ...initFields,
  ...initZusParams,
  customerNumber: "",
});

function ViewFilter() {
  const { t, tc } = useTranslation(translatePrefix);
  const [fields, setFields] = useState<IFields>(initFields);

  const [isSearchBtnDisabled, setIsSearchBtnDisabled] = useState(true);

  const zusDialog = useZusDialogStore();

  const zusParams = useZusParams();

  const DateObj = {
    FromDate: useDatePicker({
      start: {
        // yesterday 2400 oclock
        defaultDate: get2400Date("yesterday"),
      },
    }),
    ToDate: useDatePicker({
      end: {
        defaultDate: get2400Date("todayInclusive"),
      },
    }),
  };

  const checkIsSearchBtnDisabled = () => {
    if (!DateObj || Object.keys(DateObj).length === 0) {
      return setIsSearchBtnDisabled(true);
    }

    if (
      !DateObj.FromDate?.start ||
      !DateObj.ToDate?.end ||
      !fields.limitResultTo
    ) {
      return setIsSearchBtnDisabled(true);
    }

    setIsSearchBtnDisabled(false);
  };

  const getParams: () => IGetAllReq = () => {
    return {
      ...initZusParams,
      fromDate: fields.fromDate,
      toDate: fields.toDate,
      limitResultTo: fields.limitResultTo,
      customerNumber: zusDialog.meta.customerNumber,
    };
  };

  const apiParams = getParams();

  useEffect(() => {
    zusParams.setBody({
      fromDate: DateObj.FromDate.start,
      toDate: DateObj.ToDate.end,
      limitResultTo: fields.limitResultTo,
      customerNumber: zusDialog.meta.customerNumber,
      page: 0,
    });
    zusParams.refetch();
  }, []);

  useEffect(() => {
    checkIsSearchBtnDisabled();
  }, [fields]);

  useEffect(() => {
    setFields((fields) => {
      return {
        ...fields,
        fromDate: DateObj.FromDate.start,
        toDate: DateObj.ToDate.end,
      };
    });
  }, [DateObj.FromDate.start, DateObj.ToDate.end]);

  useEffect(() => {
    return () => {
      zusParams.clear();
      setFields(initFields);
    };
  }, []);

  // Set default for customer number
  useEffect(() => {
    setFields((fields) => {
      return {
        ...fields,
        customerNumber: zusDialog.meta.customerNumber,
      };
    });
  }, [zusDialog.meta.customerNumber]);

  const onSearch = () => {
    zusParams.setBody({ ...apiParams, page: 0 });
    zusParams.refetch();
  };

  const onReset = () => {
    setFields((fields) => {
      return {
        ...fields,
        limitResultTo: initFields.limitResultTo,
      };
    });
    DateObj.FromDate.resetDate();
    DateObj.ToDate.resetDate();
  };

  const onChange =
    (field: keyof typeof fields) =>
      (
        e:
          | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          | SelectChangeEvent<string>
      ) => {
        setFields((fields) => ({ ...fields, [field]: e.target.value }));
      };

  const fieldEleArr = useGenFilterField([
    [
      "fromDate",
      <DateObj.FromDate.SingleDatePicker
        type="dateTime"
        startLabel={t("fromDate")}
      />,
    ],
    [
      "toDate",
      <DateObj.ToDate.SingleDatePicker
        type="dateTime"
        startLabel={t("toDate")}
      />,
    ],
    [
      "limitResultTo",
      <SingleSelection
        label={tc("phSelection", { fieldName: t("limitResultTo") })}
        value={fields.limitResultTo}
        onChange={onChange("limitResultTo")}
        clearSelect={() =>
          setFields((fields) => ({ ...fields, limitResultTo: "" }))
        }
        nameFn={(name) => name}
        isNoSorting
        enumData={{
          25: "25",
          50: "50",
          100: "100",
          250: "250",
        }}
      />,
    ],
  ]);

  return (
    <Container disableGutters maxWidth={false}>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {fieldEleArr.map((ele, index) => cloneElement(ele, { key: index }))}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn
          label="search"
          onClick={onSearch}
          rest={{ disabled: isSearchBtnDisabled }}
        />
        <GeneralBtn label="reset" onClick={onReset} />
      </Box>
    </Container>
  );
}

export default ViewFilter;
