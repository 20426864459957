const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const getLineChartXAxisDate = (
  dateString: string,
  isMonthOnlyMode?: boolean
): string => {
  const date = new Date(dateString);
  const fullYear = String(date.getFullYear());
  const shortYear = fullYear.slice(-2);

  let day = date.getDate().toString().padStart(2, "0");
  let month = MONTHS[date.getMonth()];
  let year = shortYear;

  if (isMonthOnlyMode) {
    day = "";
    year = fullYear;
  }

  return `${day} ${month} ${year}`;
};
