import { ValidationConfigProps, ValidationResult } from "../types";
import { normalizeConfig } from "./normalizeConfig";

export const getInitValidationResult = (config: ValidationConfigProps) => {
  const validationConfig = normalizeConfig(config);

  const result = validationConfig.reduce((acc: ValidationResult, curr) => {
    acc[curr.key] = {
      isValid: true,
      errorMessage: [""],
    };
    return acc;
  }, {});

  return result;
};
