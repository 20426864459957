import Tooltip from "@mui/material/Tooltip";
import { SxProps } from "@mui/system";

import { Box } from "../MuiGenerals";
import { sxOpTextButton } from "./OpTextButton";

export { sxOpTextButton };
export default function OpIconButton(p: {
  title: string;
  svgUrl: string;
  onClick: () => void;
  isDisabled?: boolean;
  isHidden?: boolean;
  size?: "1rem" | "1.2rem" | "1.5rem" | "1.9rem" | "2rem";
  sxSVG?: SxProps;
  sxBox?: SxProps;
}) {
  const {
    title,
    isHidden = false,
    isDisabled = false,
    size = "2rem",
    svgUrl,
    onClick,
    sxBox,
  } = p;
  const sx = {
    box: {
      width: size,
      cursor: isDisabled ? "" : "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&:hover": {
        filter: isDisabled ? "brightness(100%)" : "brightness(87%)",
      },
      ...sxBox,
    },

    img: {
      filter: isDisabled ? "grayscale(100%)" : "grayscale(0)",
      width: "100%",
    },
  };
  if (isHidden) return null;
  return (
    <Tooltip title={title}>
      <Box sx={sx.box} onClick={() => !isDisabled && onClick()}>
        <img style={sx.img} src={svgUrl} />
      </Box>
    </Tooltip>
  );
}
