import React from "react";
import {
  Container,
  Row,
  RowContent,
  RowHeader,
} from "../../../../../components/Layout/DialogContentLayout";
import { useZusTranslatePrefixStore } from "../../../../../zustand/store";
import { useTranslation } from "../../../../../hooks";
import { translateKeyObj } from "../../config";

interface ContentProps {
  merchantName: string;
  orderId: string;
  customerNumber: string;
  adjustmentType: string;
  currency: string;
  amount: string;
}

const Content = (props: ContentProps) => {
  const {
    merchantName,
    orderId,
    customerNumber,
    adjustmentType,
    currency,
    amount,
  } = props;

  const { translatePrefix } = useZusTranslatePrefixStore();
  const { t } = useTranslation(translatePrefix);

  return (
    <Container>
      <Row>
        <RowHeader>{t(translateKeyObj.merchantName)}</RowHeader>
        <RowContent>{merchantName}</RowContent>
      </Row>
      <Row>
        <RowHeader>{t(translateKeyObj.orderId)}</RowHeader>
        <RowContent>{orderId}</RowContent>
      </Row>
      <Row>
        <RowHeader>{t(translateKeyObj.customerNo)}</RowHeader>
        <RowContent>{customerNumber}</RowContent>
      </Row>
      <Row>
        <RowHeader>{t(translateKeyObj.adjustmentType)}</RowHeader>
        <RowContent>{adjustmentType}</RowContent>
      </Row>
      <Row>
        <RowHeader>{t(translateKeyObj.currency)}</RowHeader>
        <RowContent>{currency}</RowContent>
      </Row>
      <Row>
        <RowHeader>{t(translateKeyObj.adjustmentAmount)}</RowHeader>
        <RowContent>{amount}</RowContent>
      </Row>
    </Container>
  );
};

export default Content;
