import { useQuery } from 'react-query';
import { translatePrefix } from "../../config";
import { useZusDialogStore } from '../../../../../zustand/store';
import api from "../../../../../api";
import { useState } from 'react';

export const TABLE_ROWS_PER_PAGE = 25

export const useApiCall = () => {
  const [page, setPage] = useState(0);
  const zusDialog = useZusDialogStore();

  const listRes = useQuery({
    queryKey: [translatePrefix, page],
    queryFn: async () => {
      const isParamNotFullyFilled =
        !zusDialog.meta.merchantId ||
        !zusDialog.meta.programName ||
        !zusDialog.meta.customerNumber

      // Only call API if all the param are filled
      if (isParamNotFullyFilled) return;

      return await api.CreditManagement.customerAccount.getMonthlyStatement({
        merchantId: zusDialog.meta.merchantId,
        programName: zusDialog.meta.programName,
        customerNumber: zusDialog.meta.customerNumber,
        limit: TABLE_ROWS_PER_PAGE,
        offset: page * TABLE_ROWS_PER_PAGE
      });
    }
  })

  const downloadAsPdf = (data: Blob, filename: string) => {
    const blob = new Blob([data], { type: "application/pdf" });
    const objUrl = URL.createObjectURL(blob);

    const element = document.createElement("a");

    document.body.appendChild(element); // Required for this to work in FireFox

    element.href = objUrl;
    element.download = filename;

    element.click();
  };

  const download = async (monthlyStatementId: string, reportName: string) => {
    zusDialog.openExtra("loadingDialog");
    const res: any = await api.CreditManagement.customerAccount.downloadMonthlyStatement({
      monthlyStatementId: monthlyStatementId
    });
    zusDialog.closeExtra();

    downloadAsPdf(res, reportName)
  }

  return {
    page,
    listRes,
    setPage,
    download
  }
}
