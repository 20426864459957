import { ReactNode } from "react";
import { useTranslate } from "react-admin";

import { useTabs } from "../../hooks";

export default function TableTab(p: { children: ReactNode }) {
  const translate = useTranslate();
  const tc = (key: string) => translate(`common.${key}`);
  const tabsArr = [{ name: tc("table"), value: "" }];
  const { Tabs } = useTabs(tabsArr);
  return <Tabs children={p.children} />;
}
