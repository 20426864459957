import React from "react";

import { Theme } from "@mui/system/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

import { useTranslation } from "../../hooks";
import { COLOR } from "../../utils/config";
import { useZusTranslatePrefixStore } from "../../zustand/store";
import GridBox from "./GridBox";

export default function GridBoxSections(props: {
  sectionHeaderList: Array<string>;
  labelElePairArrList: Array<Array<[string, JSX.Element]>>;
  columnCount?: number;
  containerSx?: SxProps<Theme>;
}) {
  const { labelElePairArrList, sectionHeaderList, columnCount, containerSx } =
    props;
  const { translatePrefix } = useZusTranslatePrefixStore();
  const { t } = useTranslation(translatePrefix);

  return (
    // <Container disableGutters maxWidth={false} sx={{ marginBottom: "12px" }}>
    <>
      {sectionHeaderList.map((sectionHeader, i) => (
        <React.Fragment key={i}>
          <h3 style={{ color: COLOR.text.primary.hex }}>{t(sectionHeader)}</h3>
          <GridBox
            labelElePairArr={labelElePairArrList[i]}
            columnCount={columnCount}
            containerSx={containerSx}
          />
        </React.Fragment>
      ))}
    </>
    // </Container>
  );
}
