import { FunctionComponent, SVGProps } from "react";

import Tooltip from "@mui/material/Tooltip";
import { SxProps } from "@mui/system";

import { Box } from "../MuiGenerals";
import { sxOpTextButton } from "./OpTextButton";

export { sxOpTextButton };
export default function OpIconButtonAdjustable(p: {
  title: string;
  SvgElement: FunctionComponent<
    SVGProps<SVGSVGElement> & { title?: string | undefined }
  >;
  onClick: () => void;
  isDisabled?: boolean;
  isHidden?: boolean;
  size?: "1rem" | "1.2rem" | "1.5rem" | "1.9rem" | "2rem";
  sxSVG?: SxProps;
  sxBox?: SxProps;
}) {
  const {
    title,
    isHidden = false,
    isDisabled = false,
    size = "2rem",
    SvgElement,
    onClick,
    sxSVG,
    sxBox,
  } = p;
  const sx = {
    box: {
      width: size,
      cursor: isDisabled ? "" : "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&:hover": {
        filter: isDisabled ? "brightness(100%)" : "brightness(87%)",
      },
      ...sxBox,

      svg: {
        filter: isDisabled ? "grayscale(100%)" : "grayscale(0)",
        width: "100%",
        height: "100%",
        ...sxSVG,
      },
    },

    // img: {
    //   filter: isDisabled ? "grayscale(100%)" : "grayscale(0)",
    //   "*": { fill: importantStyle(COLOR.text.secondary.hex) },
    //   width: "100%",
    // },
  };
  if (isHidden) return null;
  return (
    <Tooltip title={title}>
      <Box sx={sx.box} onClick={() => !isDisabled && onClick()}>
        <SvgElement /* style={{ ...sx.img }} */ />
      </Box>
    </Tooltip>
  );
}
