import { convertDateStringToYearMonthDayString } from "../../../../../../utils/dateStringUtils";
import { IAmountData } from "../../../../types/IGraphData";
import { getDatesInRange } from "../../../../utils/getDatesInRange";

// grouping data by settlementDate
export const getTransformedAmountData = (
  filteredAmountData: IAmountData[] | undefined,
  dateFrom: string,
  dateTo: string
) => {
  const transformedAmountData: { [key: string]: { [key: string]: number } } =
    {};

  filteredAmountData?.forEach((item: IAmountData) => {
    const { category, amount, settlementDate } = item;

    let convertedSettlementDate =
      convertDateStringToYearMonthDayString(settlementDate);

    if (!transformedAmountData[convertedSettlementDate]) {
      transformedAmountData[convertedSettlementDate] = {};
    }

    if (!transformedAmountData[convertedSettlementDate][category]) {
      transformedAmountData[convertedSettlementDate][category] = 0;
    }

    transformedAmountData[convertedSettlementDate][category] += Number(amount);
  });

  // Fill date range with empty data
  if (Object.keys(transformedAmountData).length > 0) {
    const dateInRange = getDatesInRange(dateFrom, dateTo);

    dateInRange.forEach((date: string) => {
      if (!transformedAmountData[date]) {
        transformedAmountData[date] = {};
      }
    });
  }

  return transformedAmountData;
};
