import React from "react";

import { Box } from "../../../../components/MuiGenerals";
import { useTranslation } from "../../../../hooks";
import { Iprefix } from "../../../../hooks/useTranslation";
import SearchInputField from "./SearchInputField";

interface ITableSearchBarProps {
    onChange: (e: any) => void;
    searchFunction?: (e: any) => void;
    translatePrefix: Iprefix;
}

const TableSearchBar: React.FC<ITableSearchBarProps> = ({
    searchFunction,
    onChange,
    translatePrefix
}) => {
    const { tc, t } = useTranslation(translatePrefix);
    return (
        <Box sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
        }}>
            <Box sx={{
                marginTop: 1,
                fontSize: "1.2rem",
            }}>
                {tc("table")}
            </Box>
            <SearchInputField
                onChange={onChange}
                placeholder={tc("search") + " " + t("searchItem")}
                onKeyDown={(e) => { if (searchFunction && e.key === "Enter") searchFunction(e.target.value) }}
            />
        </Box >
    );
};

export default TableSearchBar;
