export function customerInfoSearchReturnText(
  loading: boolean,
  merchant: any | null,
  newCardFields: any,
  t: (key: string) => string
) {
  let returnObj = { merchantName: "", programName: "" };
  if (loading) {
    return { merchantName: t("searching"), programName: t("searching") };
  }
  if (merchant && merchant.merchantName) {
    returnObj.merchantName = merchant.merchantName;
  }
  if (merchant && merchant.programName) {
    returnObj.programName = merchant.programName;
  }

  return returnObj;
}
