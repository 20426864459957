import { getLineChartXAxisDate } from "../../../../utils/getXAxisDate";

export const getAmountGraphData = (
  transformedAmountData: Record<string, Record<string, number>>,
  topCategories: string[]
) => {
  const amountGraphData = Object.keys(transformedAmountData).map(
    (settlementDate) => {
      const notShownCategories = topCategories.filter((category) => {
        return !Object.keys(transformedAmountData[settlementDate]).includes(
          category
        );
      });

      const data = transformedAmountData[settlementDate];
      // if there is no data for a category, add it with 0 value
      return {
        name: settlementDate,
        ...data,
        ...notShownCategories.reduce((acc: any, category: any) => {
          acc[category] = 0;
          return acc;
        }, {}),
        xAxisName: getLineChartXAxisDate(
          settlementDate,
          Object.keys(transformedAmountData).length > 14
        ),
      };
    }
  );

  amountGraphData.sort((a, b) => {
    return new Date(a.name).getTime() - new Date(b.name).getTime();
  });

  return amountGraphData;
};
