import { ValidatorsResponse } from "../types";

export const executeValidators = (props: {
  value: any;
  validators: Array<Function>;
}): ValidatorsResponse => {
  const { value, validators } = props;
  const errors = [];

  for (let i = 0; i < validators.length; i++) {
    const validator = validators[i];
    const { isValid, errorMessage } = validator(value);

    if (!isValid) {
      errors.push(errorMessage);
    }
  }

  return { isValid: errors.length === 0, errorMessage: errors };
};
