import { IAmountData } from "../../../../types/IGraphData";

export const getCategoriesCountTotals = (
  countData: IAmountData[] | undefined
) => {
  const categoriesCountTotals: { [key: string]: number } = {};

  countData?.forEach((item: IAmountData) => {
    const { category, amount } = item;
    if (!categoriesCountTotals[category]) {
      categoriesCountTotals[category] = 0;
    }
    categoriesCountTotals[category] += Number(amount);
  });

  return categoriesCountTotals;
};
