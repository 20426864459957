import { GeneralBtn } from '../../../../../../../components';
import { Box } from '../../../../../../../components/MuiGenerals';
import { customSx } from '../../../../../../../utils/styling';

interface IProps {
  onSearch: () => void;
  onReset: () => void;
}

const ActionButtons = (props: IProps) => {
  const { onSearch, onReset } = props;
  
  return (
    <Box sx={customSx.filterB}>
      <GeneralBtn label="search" onClick={onSearch} />
      <GeneralBtn label="reset" onClick={onReset} />
    </Box>
  );
};

export default ActionButtons;
