import { IAmountData } from "../../../../types/IGraphData";

export function getAmountDataFilteredByTopCategories(
  amountData: IAmountData[] | undefined,
  topCategories: string[]
) {
  const filteredAmountData = amountData?.filter((item: IAmountData) =>
    topCategories.includes(item.category)
  );

  return filteredAmountData;
}
