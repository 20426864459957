import { ValidatorResponse } from "../../hooks/useValidation/types";

export const requiredValidator =
  (errorMessage: string) =>
  (value: any): ValidatorResponse => {
    const isEmpty = value === undefined || value === null || value === "";
    const isEmptyArray = Array.isArray(value) && value.length === 0;
    const isEmptyObject =
      typeof value === "object" && Object.keys(value).length === 0;

    const isInvalid = isEmpty || isEmptyArray || isEmptyObject;

    if (isInvalid) {
      return { isValid: false, errorMessage };
    }

    return { isValid: true, errorMessage: "" };
  };
