import { EnumDepositStatus } from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master";

interface IProps {
  numberOfApproved: number;
  numberOfApprover: number;
  txStatus: number;
}

const getIsAutoApprove = (props: IProps) => {
  const { numberOfApproved, numberOfApprover, txStatus } = props;

  const isTxApproved = txStatus === EnumDepositStatus.Approved;

  return isTxApproved && numberOfApproved === 0 && numberOfApprover === 0;
};

export default getIsAutoApprove;
