import { EPtAdjustmentRequest } from "@wallet-manager/pfh-node-def-types/dist/src/Endpoint/CreditPortal";

import axios from "../axiosInstance";

async function getAll(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(
    EPtAdjustmentRequest.EPtAdjustmentRequest.getAll,
    input
  );
}
async function getExport(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(
    EPtAdjustmentRequest.EPtAdjustmentRequest.export,
    input
  );
}

async function retry(
  params: {
    requestId: number;
  },
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };

  return await axios.post(
    EPtAdjustmentRequest.EPtAdjustmentRequest.retry,
    input
  );
}

async function rebroadcastCallbackPmpMessage(
  params: {
    merchantId: number;
    merchantOrderId: string;
  },
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };

  return await axios.post(
    EPtAdjustmentRequest.EPtAdjustmentRequest.reBroadcast,
    input
  );
}

export default {
  getAll,
  getExport,
  retry,
  rebroadcastCallbackPmpMessage,
};
