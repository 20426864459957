import { EnumSettlementDateRange } from "../types/EnumSettlementDateRange";

export function getTimeRangeFromRangeEnum(datePeriod:EnumSettlementDateRange): {settlementDateFrom: string, settlementDateTo: string}{
    let settlementDateFrom = "";
    let settlementDateTo = "";
    let startDate = new Date();
    let endDate = new Date();
    
    // TODO: double confirm the date range
    switch (datePeriod) {
        case "lastWeek":
            startDate = new Date();
            endDate = new Date();
            endDate.setDate(endDate.getDate() - 1);
            settlementDateTo = endDate.toISOString();

            startDate.setDate(startDate.getDate() - 7);
            settlementDateFrom = startDate.toISOString();
            break;

        case "lastMonth":
            startDate = new Date();
            endDate = new Date();
            startDate.setDate(startDate.getDate() - 30);
            settlementDateFrom = startDate.toISOString();

            endDate.setDate(endDate.getDate() - 1);
            settlementDateTo = endDate.toISOString();
            break;

        case "last3Month":
            startDate = new Date();
            endDate = new Date();
            startDate.setDate(startDate.getDate() - 90);
            settlementDateFrom = startDate.toISOString();

            endDate.setDate(endDate.getDate() - 1);
            settlementDateTo = endDate.toISOString();
            break;
        default:
            break;
    }

    return { settlementDateFrom, settlementDateTo };
}