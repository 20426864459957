import { GridValidRowModel } from "@mui/x-data-grid";

export function generateTotalRow(calculateRowNames: string[], rows: GridValidRowModel[], decimalPlace: number | undefined = 2) {
    if(rows.length === 0) return {};

    const totalRow: any = {};
    // Initialize totalRow with empty string by original row keys
    Object.entries(rows[0]).forEach(([key, value]) => {
        return totalRow[key] =  "";
    });

    calculateRowNames.forEach((rowName) => {
        totalRow[rowName] = rows.reduce((acc, row) => {
            return parseFloat((acc + Number(row[rowName])).toFixed(decimalPlace))
        }, 0);
    totalRow[rowName].toFixed(decimalPlace);
    });
    return totalRow;
}
