import Row from "../components/Row"
import SingleSelection from "../../../../../../components/Selection/SingleSelection";

import { EnumCardReissueReason } from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master/Enum/CardReissueReason"
import { SelectChangeEvent } from "../../../../../../components/MuiGenerals";
import { EnumYesNo } from "../../../../../../utils/constant";

export default function getReissueReasonLogicField(
    reissueReason: string | number,
    translateFunction: any, 
    translateCommonFunction: any,
    reissueField: IReissueInput, 
    updateField: (fieldName:string, value: string | boolean) => void
) {
    switch (reissueReason) {
        case EnumCardReissueReason.Lost.toString():
        case EnumCardReissueReason.Damaged.toString():
        case EnumCardReissueReason.Expired.toString():
        case EnumCardReissueReason.Fraud.toString():
            return (
                <>
                    <Row title={translateFunction('samePin')} content={translateCommonFunction(reissueField.samePin === true ? "yes" : "no")}/>
                    <Row title={translateFunction('samePan')} content={translateCommonFunction(reissueField.samePan === true ? "yes" : "no")}/>
                    <Row title={translateFunction('deactivate_original_card')} content={translateCommonFunction(reissueField.deactivateCurrent === true ? "yes" : "no")}/>
                    <Row title={translateFunction('deactivated_original_card_on_activation')} content={translateCommonFunction(reissueField.deactivateOldCardOnActivation === true ? "yes" : "no")}/>
                </>
            );
        case EnumCardReissueReason.Replaced.toString():
            return (
                <>
                    <Row title={translateFunction('samePin')} content={translateCommonFunction(reissueField.samePin === true ? "yes" : "no")}/>
                    <Row title={translateFunction('samePan')} content={
                        <>
                            <SingleSelection
                                sx={{ formControl: { width: "100%" } }}
                                label={translateFunction('pan')}
                                value={reissueField.samePan ? "yes" : "no"}
                                onChange={(e: SelectChangeEvent)=>{
                                    updateField("samePan", e.target.value === "yes")
                                }}
                                clearSelect={() =>{}}
                                enumData={EnumYesNo}
                            />
                        </>
                    }/>
                    <Row title={translateFunction('deactivate_original_card')} content={
                        <>
                            <SingleSelection
                                sx={{ formControl: { width: "100%" } }}
                                label={translateFunction('deactivate_original_card')}
                                value={reissueField.deactivateCurrent ? "yes" : "no"}
                                onChange={(e: SelectChangeEvent)=>{
                                    updateField("deactivateCurrent", e.target.value === 'yes')
                                }}
                                clearSelect={() =>{}}
                                enumData={EnumYesNo}
                            />
                        </>
                    }
                    />
                    <Row title={translateFunction('deactivated_original_card_on_activation')} content={
                        <>
                            <SingleSelection
                                sx={{ formControl: { width: "100%" } }}
                                label={translateFunction('deactivated_original_card_on_activation')}
                                value={reissueField.deactivateOldCardOnActivation ? "yes" : "no"}
                                onChange={(e: SelectChangeEvent)=>{
                                    updateField("deactivateOldCardOnActivation", e.target.value === 'yes')
                                }}
                                clearSelect={() =>{}}
                                enumData={EnumYesNo}
                            />
                        </>
                        }
                    />
                </>
            );
        default:
            <></>
            break;
    }
}