import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IpartnerList } from "../api/types";
import { RootState } from "./store";

const initialState: { list: Record<string, string> } = {
  list: {},
};

export const partnerListSlice = createSlice({
  name: "partnerList",
  initialState,
  reducers: {
    setPartnerList: (state, action: PayloadAction<IpartnerList[]>) => {
      if (!action.payload) {
        return;
      }
      state.list = action.payload.reduce(
        (acc: Record<string, string>, { partnerName }) => ({
          ...acc,
          [partnerName]: partnerName,
        }),
        {}
      );
    },
  },
});

export const { setPartnerList } = partnerListSlice.actions;

export const selectPartnerList = (state: RootState) => state.partnerList;
export default partnerListSlice.reducer;
