import { EPtMerchantDepositRequest } from "@wallet-manager/pfh-node-def-types/dist/src/Endpoint/CreditPortal";

import axios from "../axiosInstance";

const endPoint = { ...EPtMerchantDepositRequest.EPtMerchantDepositRequest };

async function getAll(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(endPoint.getAll, input);
}
async function getExport(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(endPoint.export, input);
}

interface IApproveWithdrawParams {
  id: string;
  remark: string;
}
async function approveWithdraw(params: IApproveWithdrawParams, config?: any) {
  const input = {
    ...params,
    ...config,
  };

  return await axios.post(endPoint.approve, input);
}

interface IRejectWithdrawParams {
  id: string;
  remark: string;
}

async function rejectWithdraw(params: IRejectWithdrawParams, config?: any) {
  const input = {
    ...params,
    ...config,
  };

  return await axios.post(endPoint.reject, input);
}

interface ICreateWithdrawRequestParams {
  merchantId: number;
  currency: string;
  amount: string;
}

async function createWithdrawRequest(
  params: ICreateWithdrawRequestParams,
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };

  return await axios.post(endPoint.createRequest, input);
}

export default {
  getAll,
  getExport,
  approveWithdraw,
  rejectWithdraw,
  createWithdrawRequest,
};
