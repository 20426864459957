import React from "react";
import { Box } from "@mui/material";

interface IDashboardFooterProps {
    children: React.ReactNode;
}

const DashboardFooter: React.FC<IDashboardFooterProps> = ({ children }) => {
    return (
        <Box sx={{
            borderTop: "1px solid #707B94",
            width: "100%",
            height: "100%",
            paddingLeft: "1rem",
            flex: 1,
            maxHeight: "3rem",
        }}>
            {children}
        </Box>
    );
};

export default DashboardFooter;
