export function combineInterestAndCharge (interest: string, charges: string) {
    if (isNaN(Number(interest)) && isNaN(Number(charges))) {
        return "";
    }
    if (isNaN(Number(interest))) {
        return Number(charges);
    }
    if (isNaN(Number(charges))) {
        return Number(interest);
    }
    return Number(interest) + Number(charges);
}
