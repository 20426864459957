import { useState } from "react";

import DialogInOne, {
  DialogInOneProps,
} from "../../../../../../components/DialogInOne";
import { useTranslation } from "../../../../../../hooks";
import { useZusDialogStore } from "../../../../../../zustand/store";

export default function NetworkMessageRawDialog() {
  const { t } = useTranslation("customerAccount");

  const zusDialog = useZusDialogStore();

  const data = zusDialog.extraMeta.rawNetworkMessage;

  const handleCloseDialog = () => {
    zusDialog.closeExtra();
  };

  const dialogConfig: DialogInOneProps = {
    title: t("networkMessages"),
    self: {
      open: zusDialog.matchExtra("networkMessages"),
      onClose: handleCloseDialog,
    },

    content: (
      <div
        style={{
          whiteSpace: "pre-wrap",
          lineHeight: "1.5",
          fontFamily: "monospace",
          fontSize: "16px",
          padding: "10px",
          overflowX: "auto",
        }}
      >
        {JSON.stringify(data, null, 2)}
      </div>
    ),
    onConfirm: () => {},
    onCancel: handleCloseDialog,
    isLoadingDialog: true,
    isConfirmHidden: true,
    size: "md",
    maxContentHeight: "70dvh",
  };

  return (
    <>
      {/* <LoadingDialog forceOpen={isShowLoading} /> */}
      <DialogInOne {...dialogConfig} />
    </>
  );
}
