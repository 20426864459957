import { DialogInOne } from "../../../components";
import { DialogInOneProps } from "../../../components/DialogInOne";
import { GridBox } from "../../../components/Layout";
import { Box } from "../../../components/MuiGenerals";
import { useTranslation } from "../../../hooks";
import useCurrencyList from "../../../hooks/useCurrencyList";
import { useZusDialogStore } from "../../../zustand/store";
import { TableApiRes, translateKeyObj as TK, translatePrefix } from "./config";

export default function DetailsDialog() {
  const zusDialog = useZusDialogStore();
  const { t, tb } = useTranslation(translatePrefix);
  const { currencyList } = useCurrencyList();

  const { rawData, field } = (zusDialog.meta || {}) as {
    rawData: TableApiRes | undefined;
    field: (typeof TK)["transactionDetails" | "cardAcceptorDetails"]; // | "acquirerDetails"
  };

  if (!rawData) {
    return <></>;
  }

  // const acquirerDecimal = getDecimal(rawData.acquirerCurrency, currencyList);
  // const toDisplayAcquirerAmount = displayAmountCurrying(0, 2);

  // const acquirerDetailsLabelElePairArr: [string, JSX.Element][] = [
  // [TK.acquiringInstitutionId, <Box>{rawData.acquiringInstitutionId}</Box>],
  // [TK.acquirerCurrency, <Box>{rawData.acquirerCurrency}</Box>],
  // [
  //   TK.acquirerAmount,
  //   <Box>{toDisplayAcquirerAmount(rawData.acquirerAmount)}</Box>,
  // ],
  // ];

  const transactionDetailsLabelElePairArr: [string, JSX.Element][] = [
    [TK.acquiringInstitutionId, <Box>{rawData.acquiringInstitutionId}</Box>],
    [TK.isAtm, <Box>{tb(rawData.isAtm)}</Box>],
    [TK.isInCountry, <Box>{tb(rawData.isInCountry)}</Box>],
    [TK.onlineTransaction, <Box>{tb(rawData.isOnline)}</Box>],
    [TK.posTransaction, <Box>{tb(rawData.isPos)}</Box>],
    [TK.isReversal, <Box>{tb(rawData.reversal)}</Box>],
  ];

  const cardAcceptorDetailsLabelElePairArr: [string, JSX.Element][] = [
    [TK.merchantCategoryCodes, <Box>{rawData.mcc}</Box>],
    [TK.cardAcceptorId, <Box>{rawData.cardAcceptorId}</Box>],
    [TK.cardAcceptorName, <Box>{rawData.cardAcceptorName}</Box>],
    [TK.cardAcceptorCity, <Box>{rawData.cardAcceptorCity}</Box>],
    [TK.cardAcceptorCountryCode, <Box>{rawData.cardAcceptorCountryCode}</Box>],
  ];

  const fieldLabelElePairingArrMapping = {
    // [TK.acquirerDetails]: acquirerDetailsLabelElePairArr,
    [TK.transactionDetails]: transactionDetailsLabelElePairArr,
    [TK.cardAcceptorDetails]: cardAcceptorDetailsLabelElePairArr,
  };

  const dialogConfig: DialogInOneProps = {
    title: t(field),
    self: {
      open: zusDialog.match("detailsDialog"),
      onClose: zusDialog.close,
    },
    content: (
      <GridBox
        labelElePairArr={field ? fieldLabelElePairingArrMapping[field] : []}
        columnCount={1}
      />
    ),
    onConfirm: () => {},
    isConfirmHidden: true,
    size: "sm",
    onCancel: zusDialog.close,
  };

  return <DialogInOne {...dialogConfig} />;
}
