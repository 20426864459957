import FlexCenterBox from "../../../../../../../components/Layout/FlexCenterBox";
import { Box } from "../../../../../../../components/MuiGenerals";

const RowTitle = ({ children }: { children: React.ReactNode }) => {
  const rowTitleStyle = {
    whiteSpace: "nowrap",
    fontSize: "12px",
    marginRight: "24px",
    height: "100%",
    marginTop: "8px",
  };

  return (
    <FlexCenterBox style={{ minWidth: "100px", justifyContent: "flex-start" }}>
      <Box sx={rowTitleStyle}>{children}</Box>
    </FlexCenterBox>
  );
};

export default RowTitle;
