import React from "react";
import { Box } from "@mui/material";
import { Typography } from "../../../../MuiGenerals";

interface IDashboardHeaderProps {
    title?: string;
    customComponent?: React.ReactNode;
    sx?: any;
}

const DashboardHeader: React.FC<IDashboardHeaderProps> = ({
    title,
    customComponent,
    sx,
}) => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingLeft: "1rem",
                paddingTop: "0.9rem",
                paddingBottom: "0.9rem",
                flexDirection: "row",
                whiteSpace: "nowrap",
                ...sx,
            }}
        >
            <Box>
                <Typography sx={{
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    overflowWrap: "break-word",
                    whiteSpace: "pre-wrap",
                }}>
                    {title}
                </Typography>
            </Box>
            <Box>
                {customComponent}
            </Box>
        </Box>
    );
};

export default DashboardHeader;
