import { Box } from "../../../../../../../components/MuiGenerals";

const FilterWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        position: "relative",
        paddingBottom: "12px",
        "& .MuiFormControl-root": {
          width: "100%",
        },
        "& .datePickerObj .MuiFormControl-root": {
          width: "calc(50% - 1rem)",
        },
      }}
    >
      {children}
    </Box>
  );
};

export default FilterWrapper;
