import { useNavigate } from "react-router-dom";

function CreditCardLogo() {
  const navigate = useNavigate();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="145.577"
      height="40.28"
      viewBox="0 0 145.577 40.28"
      style={{ cursor: "pointer" }}
      onClick={() => navigate("/")}
    >
      <g
        id="Group_12436"
        data-name="Group 12436"
        transform="translate(1798 9226.28)"
      >
        <g
          id="Group_12434"
          data-name="Group 12434"
          transform="translate(-1847 -9256.707)"
        >
          <text
            id="CREDIT"
            transform="translate(95.577 47.293)"
            fill="#ffe8b4"
            fontSize="16"
            fontFamily="Helvetica-Light, Helvetica"
            fontWeight="300"
            letterSpacing="0.2em"
          >
            <tspan x="0" y="0">
              CREDIT
            </tspan>
          </text>
          <text
            id="MANAGER"
            transform="translate(95.577 66.707)"
            fill="#ffe8b4"
            fontSize="16"
            fontFamily="Helvetica-Light, Helvetica"
            fontWeight="300"
            letterSpacing="0.2em"
          >
            <tspan x="0" y="0">
              MANAGER
            </tspan>
          </text>
        </g>
        <g
          id="Group_12435"
          data-name="Group 12435"
          transform="translate(-1798 -9226.28)"
        >
          <g id="Group_12423" data-name="Group 12423">
            <path
              id="Path_10214"
              data-name="Path 10214"
              d="M37.885,22.57a12.57,12.57,0,1,0,0,6.285H41.11a15.712,15.712,0,1,1,0-6.285Z"
              transform="translate(-6.072 -6.072)"
              fill="none"
              stroke="#ffe8b4"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeWidth="1"
            />
            <line
              id="Line_220"
              data-name="Line 220"
              x2="9.456"
              transform="translate(7.274 19.64)"
              fill="none"
              stroke="#ffe8b4"
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeWidth="1"
            />
            <path
              id="Path_10215"
              data-name="Path 10215"
              d="M20.855,36.567v3.142A18.855,18.855,0,1,1,20.855,2V5.142"
              transform="translate(-1.214 -1.214)"
              fill="none"
              stroke="#ffe8b4"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeWidth="1"
            />
            <circle
              id="Ellipse_124"
              data-name="Ellipse 124"
              cx="2.91"
              cy="2.91"
              r="2.91"
              transform="translate(16.73 16.73)"
              fill="none"
              stroke="#ffe8b4"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeWidth="1"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CreditCardLogo;
