import { ValidatorResponse } from "../../hooks/useValidation/types";

export const checkValueDecimalValidator =
  (errorMessage: string, data: { decimal: number }) =>
  (value: string | number): ValidatorResponse => {
    const { decimal } = data;
    const valueDecimal = String(value).split(".")[1]?.length;

    if (!valueDecimal) {
      return { isValid: true, errorMessage: "" };
    }

    if (Number(valueDecimal) > decimal) {
      return { isValid: false, errorMessage };
    }

    return { isValid: true, errorMessage: "" };
  };
