export const get2400Date = (
  day: "yesterday" | "todayExclusive" | "todayInclusive"
) => {
  // display: yesterday 2400 = data: the day before yesterday's 2400
  const daysBefore = day === "yesterday" ? 2 : 1;

  let date = new Date();

  if (day === "yesterday" || day === "todayExclusive") {
    date.setDate(date.getDate() - daysBefore);
    date.setHours(24, 0, 0, 0);
  } else {
    date.setHours(23, 59, 59, 0);
  }

  return date;
};
