import React from 'react';
import { Box } from '../../../../../../components/MuiGenerals';
import { useTranslation } from '../../../../../../hooks';
import { displayAmountCurrying } from '../../../../../../utils/helper';
import { COLOR } from '../../../../../../utils/config';
import FinancialTabBlock from './FinancialTabBlock';

interface IBalanceBlockProps {
    balanceData: any;
}

const BalanceBlock: React.FC<IBalanceBlockProps> = ({ balanceData }) => {
    const { t } = useTranslation('customerAccount');
    const displayHkdAmount = displayAmountCurrying(0, 2);

    return (
        <Box>
            <FinancialTabBlock title={t('balance')}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: {
                            xs: 'column',
                            sm: 'row'
                        },
                        marginTop: '1rem',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                        }}
                    >
                        <Box sx={{

                        }}>
                            <Box sx={{
                                fontSize: '1.2rem',
                            }}>
                                {t('total_balance')}
                            </Box>
                            <Box
                                sx={{
                                    fontSize: '2rem',
                                    fontWeight: 'bold',
                                    color: COLOR.text.primary.hex,
                                    marginTop: '0.8rem',
                                }}
                            >
                                {`${t("hkd")} ${displayHkdAmount(balanceData?.totalBalance || 0)}`}
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginTop: '0.8rem',
                            }}
                        >
                            <Box
                                sx={{
                                    fontSize: '0.7rem',
                                    color: "rgba(255, 255, 255, 0.5)",
                                }}
                            >
                                {t('available_balance')}:
                            </Box>
                            <Box
                                sx={{
                                    fontSize: '0.7rem',
                                    color: "rgba(255, 255, 255, 0.5)",
                                    marginLeft: '0.5rem',
                                }}
                            >
                                {`${t("hkd")} ${displayHkdAmount(balanceData?.availableCredit || 0)}`}
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginTop: '1rem',
                            }}
                        >
                            <Box sx={{
                                fontSize: '0.7rem',
                                color: "rgba(255, 255, 255, 0.5)",
                            }}>
                                {t('excess_credit_of')}:
                            </Box>
                            <Box
                                sx={{
                                    fontSize: '0.7rem',
                                    color: "rgba(255, 255, 255, 0.5)",
                                    marginLeft: '0.5rem',
                                }}
                            >
                                {`${t("hkd")} ${displayHkdAmount(balanceData?.excessBalance || 0)}`}
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Box>
                            <Box
                                sx={{
                                    fontSize: '1.2rem',
                                }}
                            >
                                {t('credit_limit')}
                            </Box>
                            <Box
                                sx={{
                                    fontSize: '2rem',
                                    fontWeight: 'bold',
                                    color: COLOR.text.primary.hex,
                                    marginTop: '0.8rem',
                                }}
                            >
                                {`${t("hkd")} ${displayHkdAmount(balanceData?.overallCreditLimit || 0)}`}
                            </Box>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: '0.5rem',
                        }}>
                            <Box sx={{
                                fontSize: '0.7rem',
                                color: "rgba(255, 255, 255, 0.5)",
                            }}>
                                {t('over_limit_allowance')}:
                            </Box>
                            <Box sx={{
                                fontSize: '0.7rem',
                                color: "rgba(255, 255, 255, 0.5)",
                                marginLeft: '0.5rem',
                            }}>
                                {`${balanceData?.overCreditLimitEnabled ? t("enabled") : t("disabled")}`}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </FinancialTabBlock>
        </Box>
    );
}

export default BalanceBlock;
