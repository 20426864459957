import { EptDailyReport } from '@wallet-manager/pfh-node-def-types/dist/src/Endpoint/CreditPortal';
import axios from '../axiosInstance';

const { DailyReport: endPoint } = EptDailyReport;

async function getAll(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(endPoint.getAll, input);
}

async function getExport(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(endPoint.export, input);
}

export default {
    getAll,
    getExport,
};
