import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  IconButton,
  SelectChangeEvent,
  Tooltip,
} from "../../../../components/MuiGenerals";
import { SingleSelection } from "../../../../components";
import useTranslation, { Iprefix } from "../../../../hooks/useTranslation";
import { maybeDate, useDatePicker } from "../../../../components/DatePicker";

import ReplayIcon from "@mui/icons-material/Replay";
import { EnumSettlementDateRange } from "./types/EnumSettlementDateRange";
import { getTimeRangeFromRangeEnum } from "./helper/getTimeRangeFromRangeEnum";

interface SettlementDateBarProps {
  translatePrefix: Iprefix;
  reloadFunction: any;
}

const SettlementDateBar: React.FC<SettlementDateBarProps> = ({
  translatePrefix,
  reloadFunction,
}) => {
  const { t, tc } = useTranslation(translatePrefix);
  const [fields, setFields] = useState({
    settlementDateRange: "lastWeek",
  });
  const recordDateRange = useDatePicker({
    isTimezoneConvert: false,
  });

  // set default value for date range ("lastWeek" value)
  useEffect(() => {
    updateRecordDateRange(
      fields.settlementDateRange as EnumSettlementDateRange
    );
  }, []);

  const onConfirmReload = () => {
    let settlementDateFrom = "";
    let settlementDateTo = "";

    if (recordDateRange.start && recordDateRange.end) {
      settlementDateFrom = recordDateRange.start;
      settlementDateTo = recordDateRange.end;
    } else {
      const result = getTimeRangeFromRangeEnum(
        fields.settlementDateRange as EnumSettlementDateRange
      );
      settlementDateFrom = result.settlementDateFrom;
      settlementDateTo = result.settlementDateTo;
    }
    reloadFunction(settlementDateFrom, settlementDateTo);
  };

  const createMaybeDate = (dateStr: string) => {
    // for type casting and default value
    return new Date(dateStr || "") as maybeDate;
  };

  const updateRecordDateRange = (dateRangeType: EnumSettlementDateRange) => {
    const { settlementDateFrom, settlementDateTo } = getTimeRangeFromRangeEnum(
      dateRangeType as EnumSettlementDateRange
    );

    recordDateRange.setDateStart(createMaybeDate(settlementDateFrom));
    recordDateRange.setDateEnd(createMaybeDate(settlementDateTo));
  };

  const onChange =
    (field: keyof typeof fields) =>
    (
      e:
        | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<string>
    ) => {
      setFields((fields) => ({ ...fields, [field]: e.target.value }));
      //   recordDateRange.clearDate();
      updateRecordDateRange(e.target.value as EnumSettlementDateRange);
    };

  const onDatePickerChange = (date: any) => {
    setFields({ settlementDateRange: "" });
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <Box
        sx={{
          marginRight: 2,
          minWidth: 200,
        }}
      >
        <SingleSelection
          label={tc("custom")}
          value={fields.settlementDateRange}
          sx={{ formControl: { width: "100%" } }}
          onChange={onChange("settlementDateRange")}
          clearSelect={() => {
            // setFields({ settlementDateRange: "" });
          }}
          isNoSorting
          enumData={EnumSettlementDateRange}
        />
      </Box>

      <recordDateRange.Picker type="date" onChange={onDatePickerChange} />

      <Tooltip title={tc("reload")}>
        <IconButton onClick={onConfirmReload}>
          <ReplayIcon
            sx={{
              "&:hover": {
                "@keyframes spin": {
                  "0%": { transform: "rotate(0deg)" },
                  "100%": { transform: "rotate(-90deg)" },
                },
                animation: "spin 0.4s linear",
              },
            }}
          />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default SettlementDateBar;
