import { Box } from "../MuiGenerals";

const flexCenterStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

interface IProps {
  isColumn?: boolean;
  children: React.ReactNode;
  style?: Record<string, unknown>;
}

const FlexCenterBox = (props: IProps) => {
  const { isColumn, children, style = {} } = props;

  return (
    <Box
      sx={{
        ...flexCenterStyle,
        ...(isColumn && { flexDirection: "column" }),
        ...style,
      }}
    >
      {children}
    </Box>
  );
};

export default FlexCenterBox;
