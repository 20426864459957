import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../reducer/hooks";
import api from "../api";
import {
  Currency,
  selectCurrencyList,
  setCurrencyList,
} from "../reducer/currencyListSlice";
import { ICurrencyList } from "../api/types";

const createList = (data: ICurrencyList[]): Currency[] => {
  const list = data.map((item) => {
    const { id, currency, decimal } = item;
    return {
      id,
      currency,
      decimal,
    };
  });
  return list;
};

const createEnumForSelection = (list: Currency[]) => {
  const result = list.reduce(
    (acc: Record<string, string>, { id, currency }, index) => ({
      ...acc,
      [currency]: currency,
    }),
    {}
  );
  return result;
};

interface UseCurrencyListProps {
  shouldFetch?: boolean;
}

const useCurrencyList = (props: UseCurrencyListProps = {}) => {
  const { shouldFetch = false } = props;

  const dispatch = useAppDispatch();
  const { list: currencyList } = useAppSelector(selectCurrencyList);

  useEffect(() => {
    const updateCurrencyList = async () => {
      const res = await api.CreditPortal.getCurrencyList();
      const list = createList(res);
      dispatch(setCurrencyList(list));
    };

    if (shouldFetch) {
      updateCurrencyList();
    }
  }, [dispatch, shouldFetch]);

  return {
    CurrencyListEnum: createEnumForSelection(currencyList),
    currencyList,
  };
};

export default useCurrencyList;
