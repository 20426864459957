import React, { useEffect, useState } from "react";
import { Admin, CustomRoutes, localStorageStore, Resource } from "react-admin";
import { Route } from "react-router-dom";

import { ensureAccessToken } from "./api/axiosInstance";
import i18nProvider from "./components/i18nProvider";
import { LayoutBar } from "./components/Layout";
import { Box } from "./components/MuiGenerals";
import { useRoutePermission } from "./hooks";
import { useAppDispatch, useAppSelector } from "./reducer/hooks";
import { selectProfile } from "./reducer/profileSlice";
import { selectStuff, setIsRefreshingToken } from "./reducer/stuffSlice";
import { COLOR, queryClient } from "./utils/config";
import { versionNumber } from "./utils/constant";
import { theme } from "./utils/theme";
import AccountSetting from "./views/AccountSetting";
import {
  CardList,
  CreditAdjustmentRequest,
  CustomerAccount,
  CustomerWallet,
  FeeWallet,
  MerchantDepositRequest,
  MerchantWallet,
  PreCreateCardRequest,
  RepaymentSchedule,
  WalletTransaction,
} from "./views/CreditManagement";
import BalanceAdjustmentRequest from "./views/CreditManagement/BalanceAdjustmentRequest";
import CardOrderRequest from "./views/CreditManagement/CardOrderRequest";
import CardTransaction from "./views/CreditManagement/CardTransaction";
import Dashboard from "./views/Dashboard";
import ByCountryConsumption from "./views/DataAnalysis/Consumption/ByCountry";
import ByMccConsumption from "./views/DataAnalysis/Consumption/ByMCC";
import ByPaymentMethodConsumption from "./views/DataAnalysis/Consumption/ByPaymentMethod";
import ByPfhNoConsumption from "./views/DataAnalysis/Consumption/ByPfhNo";
import ByProgramConsumption from "./views/DataAnalysis/Consumption/ByProgram";
import ByCountryWithdraw from "./views/DataAnalysis/Withdraw/ByCountry";
import ByPfhNoWithdraw from "./views/DataAnalysis/Withdraw/ByPfhNo";
import ByProgramWithdraw from "./views/DataAnalysis/Withdraw/ByProgram";
import LoadingDialogProvider from "./views/LoadingDialogProvider";
import NoPermissionElement from "./views/NoPermissionElement";
import CustomerSpendingReport from "./views/Report/CustomerSpendingReport";
import DailyTransaction from "./views/Report/DailyTransaction";

//###add-new-page
const rawList = {
  // example: withdrawRequest: <WithdrawRequest />,

  accountSetting: <AccountSetting />,

  customerAccount: <CustomerAccount />,
  merchantWallet: <MerchantWallet />,
  customerWallet: <CustomerWallet />,
  feeWallet: <FeeWallet />,
  merchantDepositRequest: <MerchantDepositRequest />,
  creditAdjustmentRequest: <CreditAdjustmentRequest />,
  repaymentSchedule: <RepaymentSchedule />,
  walletTransaction: <WalletTransaction />,
  cardTransaction: <CardTransaction />,
  cardList: <CardList />,
  cardOrderRequest: <CardOrderRequest />,
  preCreateCardRequest: <PreCreateCardRequest />,
  balanceAdjustmentRequest: <BalanceAdjustmentRequest />,
  dailyTransaction: <DailyTransaction />,
  customerSpendingReport: <CustomerSpendingReport />,
  byProgramConsumption: <ByProgramConsumption />,
  byCountryConsumption: <ByCountryConsumption />,
  byPfhNoConsumption: <ByPfhNoConsumption />,
  byMccConsumption: <ByMccConsumption />,
  byPaymentMethodConsumption: <ByPaymentMethodConsumption />,
  byProgramWithdraw: <ByProgramWithdraw />,
  byPfhNoWithdraw: <ByPfhNoWithdraw />,
  byCountryWithdraw: <ByCountryWithdraw />,
};
const App = () => {
  useEffect(() => console.log("versionNumber", versionNumber), []);

  const dispatch = useAppDispatch();
  const { hasRoutePermission } = useRoutePermission();
  const { timezone } = useAppSelector(selectProfile);
  const { isRefreshingToken } = useAppSelector(selectStuff);

  // prevent isRefreshingToken dead lock
  useEffect(() => {
    const TIMEOUT_SECOND = 10 * 1000;

    let timeoutId: ReturnType<typeof setTimeout>;

    if (isRefreshingToken) {
      timeoutId = setTimeout(() => {
        dispatch(setIsRefreshingToken(false));
      }, TIMEOUT_SECOND);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isRefreshingToken]);

  const list: { [key: string]: any } =
    Object.entries(rawList).map(([key, comp]) => {
      const element = hasRoutePermission(key) ? comp : <NoPermissionElement />;
      return [key, element];
    }) || {};

  const isTokenReady = useToken();

  if (!isTokenReady) return <></>;

  return (
    <Box
      sx={{
        main: {
          backgroundColor: COLOR.background.primary.hex,
          color: COLOR.text.secondary.hex,
        },
      }}
    >
      <LoadingDialogProvider isLoadingDialogOpen={isRefreshingToken} />
      <Admin
        theme={theme}
        store={localStorageStore("0.1")}
        i18nProvider={i18nProvider}
        dashboard={Dashboard}
        queryClient={queryClient}
        layout={LayoutBar}
      >
        <Resource name="notExist" />
        <CustomRoutes>
          <Route>
            {list.map(([path, element]: [string, JSX.Element], i: number) => (
              <Route
                key={i}
                path={path}
                element={React.cloneElement(element, { timezone })}
              />
              // <Route path="/batchSweep" element={<BatchSweep />} />
              // OR
              // <Route path="/batchSweep" element={<></>} />
            ))}
          </Route>
        </CustomRoutes>
      </Admin>
    </Box>
  );
};
function useToken() {
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    async function fetch() {
      const isTokenValid = await ensureAccessToken();
      if (isTokenValid) {
        setIsReady(true);
      }
    }
    fetch();
  }, []);

  return isReady;
}
export default App;
