import { Box } from "../../../../components/MuiGenerals";
import SearchIcon from '@mui/icons-material/Search';

interface IProps {
    onChange: (e: any) => void;
    placeholder?: string;
    onKeyDown: (e: any) => void;
}

const SearchInputField: React.FC<IProps> = (
    {
        onChange,
        onKeyDown,
        placeholder
    }
) => {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#adb7c3",
            minWidth: 200,
        }}>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                paddingLeft: 0.8,
                paddingRight: 0.8,
                paddingTop: 0.5,
                paddingBottom: 0.5,
            }}>
                <SearchIcon
                    sx={{
                        fill: "#000000",
                    }}
                />
            </Box>
            <input
                style={{
                    backgroundColor: "#adb7c3",
                    border: "none",
                    fontSize: "0.8rem",
                    outline: "none",
                    width: "100%",
                }}
                type="text"
                onChange={onChange}
                placeholder={placeholder}
                onKeyDown={onKeyDown}
            />
        </Box>
    );
};

export default SearchInputField;