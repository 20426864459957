import { FeatureCreditManagement } from "@wallet-manager/pfh-node-def-types/dist/src/FeatureCode";

import api from "../../../api";
import { Ipage } from "../../../api/types";
import { Iprefix } from "../../../hooks/useTranslation";
import { initZusParams } from "../../../utils/config";
import { createZusInstance } from "../../../zustand/store";

const translatePrefix: Iprefix = "customerWallet";

const PermissionKey = FeatureCreditManagement.CustomerWallet;

interface Ifield {
  merchantId: string;
  walletName: string;
  currencies: string[];
}

const initFields: Ifield = {
  merchantId: "",
  walletName: "",
  currencies: [],
};

interface IsearchParam {
  merchantId: string;
  walletNames: string[];
  currencies: string[];
  createdDateFrom: string;
  createdDateTo: string;
  lastModifiedDateFrom: string;
  lastModifiedDateTo: string;
}

const useZusParams = createZusInstance<Ipage & Partial<IsearchParam>>(
  initZusParams
);

const apiObj: {
  export: (params: any, config?: any) => any;
  table: (params: any, config?: any) => any;
} = Object.freeze({
  export: api.CreditManagement.customerWallet.getExport,
  table: api.CreditManagement.customerWallet.getAll,
});

const translateKeyObj = Object.freeze({
  merchantName: "merchant_name",
  walletName: "wallet_name",
  walletType: "wallet_type",
  currency: "currency",
  credit: "credit",
  createdBy: "created_by",
  creationTime: "creation_time",
  lastModifiedTime: "last_modified_time",
});

const omitKeyObj = Object.freeze({
  export: [],
  table: [],
});

export {
  translatePrefix,
  PermissionKey,
  type Ifield,
  type IsearchParam,
  initFields,
  initZusParams,
  useZusParams,
  apiObj,
  translateKeyObj,
  omitKeyObj,
};
