import { generateTotalRow } from "./generateTotalRows";
import {translateKeyObj as  TK} from "../config"

export function createExport(t: (name: string) => string, res: any[]){
    const columnGroup = [t("e6"),"","","","","","","",""/* for separate E6, visa data */, t("visa"),"" ,"","","","","",""];

    const header = Object.keys(res[0]);
    header.shift();
    header.forEach((row, index) => {
        header[index] = t(row);
    })
    // for separate E6, visa data
    header.splice(8, 0, "");

    const data = res.map((row) => Object.values(row));
    // for separate E6, visa data
    data.forEach((row: any) => {row.shift(); row.splice(8, 0, "")});

    const total = generateTotalRow(
        [ TK.e6OriginalTransactionAmount,
            TK.exchangeRateFee,
            TK.fees,
            TK.interestCharges, 
            TK.netSettlementAmount, 
            TK.total, 
            TK.ukAdjustmentFee, 
            TK.visaOriginalTransactionAmount,
            TK.visaReimbursementFees,
            TK.visaCharges,
        ]
        , [...res]);
    // hardcode to add total
    total[TK.e6ProcessingDate] = t("total");
    total[TK.visaProcessingDate] = t("total");
    const totalRows = Object.values(total);
    // for separate E6, visa data
    totalRows.splice(9, 0, "");
    totalRows.shift();

    let rawCSV = "";
    for (let i = 0; i < columnGroup.length; i++) {
        rawCSV += columnGroup[i] + ",";
    }
    rawCSV += "\n";
    for (let i = 0; i < header.length; i++) {
        rawCSV += header[i] + ",";
    }
    rawCSV += "\n";
    for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].length; j++) {
            rawCSV += data[i][j] + ",";
        }
        rawCSV += "\n";
    }
    for (let i = 0; i < totalRows.length; i++) {
        rawCSV += totalRows[i] + ",";
    }

    return rawCSV;
}
