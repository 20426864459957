import { defaultTheme } from "react-admin";

import { COLOR } from "./config";
import { importantStyle } from "./helper";

import type { RaThemeOptions } from "react-admin";
export const theme: RaThemeOptions = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,

    primary: {
      main: COLOR.button.primary.hex,
      contrastText: COLOR.text.primary.hex,
    },
    secondary: {
      main: COLOR.button.primary.hex,
      contrastText: COLOR.text.secondary.hex,
    },

    warning: {
      main: COLOR.background.table.hex,
      contrastText: COLOR.text.secondary.hex,
    },

    info: {
      main: COLOR.text.secondary.hex,
      contrastText: COLOR.text.secondary.hex,
    },
  },
  typography: {
    fontSize: 11,
  },
  sidebar: {
    width: 320,
    closedWidth: 320,
  },
  components: {
    ...defaultTheme.components,
    // @ts-ignore react-admin doesn't add its own components
    RaLocalesMenuButton: {
      styleOverrides: {
        root: {
          ".css-azl7ko-MuiButtonBase-root-MuiButton-root": {
            fontSize: "0.5rem",
          },
          svg: {
            "&.MuiSvgIcon-fontSizeMedium": {
              display: "none",
            },
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          "&.MuiMenu-list": {
            color: COLOR.text.secondary.rgba(0.7),
            ":has(.languageSelection)": {
              backgroundColor: COLOR.layoutBar.primary.hex,
            },
            "& .Mui-focusVisible": {
              color: COLOR.text.secondary.hex,
              "&.Mui-selected": {
                color: COLOR.text.primary.hex,
              },
            },
            ":hover": {
              li: { color: COLOR.text.secondary.rgba(0.7) },
            },
            li: {
              backgroundColor: COLOR.background.secondary.hex,
              "&.languageSelection": {
                backgroundColor: COLOR.layoutBar.primary.hex,
              },
              ":hover": {
                color: COLOR.text.secondary.hex,
              },
              "&.Mui-selected": {
                color: COLOR.text.primary.rgba(0.85),
                backgroundColor: COLOR.background.secondary.hex,
                "&.languageSelection": {
                  backgroundColor: COLOR.layoutBar.primary.hex,
                },
                ":hover": {
                  color: COLOR.text.primary.hex,
                },
              },
            },
          },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: importantStyle(COLOR.background.secondary.hex),
          // "&.MuiFilledInput-root": {
          //   backgroundColor: COLOR.background.secondary.hex,
          // },
          color: COLOR.text.secondary.hex,
          ":before": {
            borderBottom: importantStyle(
              `1px solid ${COLOR.text.secondary.hex}`
            ),
            opacity: 0.5,
          },
          ":not(:focus)": {
            ":after": {
              borderBottom: importantStyle(
                `1px solid ${COLOR.text.secondary.hex}`
              ),
            },
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: { color: `${COLOR.text.secondary.hex} !important`, opacity: 0.8 },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: COLOR.text.secondary.rgba(0.7),
          ":hover": {
            color: COLOR.text.secondary.hex,
          },
          "&.Mui-disabled": {
            color: importantStyle(COLOR.text.secondary.rgba(0.3)),
          },
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          button: {
            color: importantStyle(COLOR.text.secondary.rgba(0.87)),
            ":hover": { color: importantStyle(COLOR.text.secondary.hex) },
          },
          "& .Mui-selected": {
            color: importantStyle(COLOR.text.primary.hex),
            ":hover": { color: importantStyle(COLOR.text.primary.hex) },
          },
          "& .MuiTabs-indicator": {
            backgroundColor: `${COLOR.text.primary.hex}`,
          },
        },
      },
    },

    MuiInputAdornment: {
      styleOverrides: {
        root: { color: COLOR.text.secondary.hex, opacity: 0.8 },
      },
    },
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          "&.RaMenuItemLink-active": {
            color: COLOR.text.primary.hex,
          },
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        root: {
          "& .MuiFormControl-root": {
            marginTop: 0,
            marginBottom: 0,
            "& .MuiSelect-select:focus": {
              backgroundColor: "inherit",
            },
            ":hover": { ">label": { color: COLOR.text.secondary.hex } },
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "0px 20px 10px 20px",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          ".MuiTableCell-head": {
            padding: "15px 5px 5px 5px",
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          color: COLOR.text.secondary.hex,
          "&.Mui-selected": {
            backgroundColor: COLOR.background.table.rgba(0.5),
            ":hover": {
              backgroundColor: COLOR.background.table.hex,
            },
          },
        },
      },
    },

    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          backgroundColor: importantStyle(COLOR.background.secondary.hex),
          color: COLOR.text.secondary.rgba(0.87),
          // ":hover": {
          //   backgroundColor: COLOR.background.secondary.rgba(0.7),
          // },
          // "& .Mui-selected": {
          //   backgroundColor: COLOR.background.secondary.rgba(0.7),
          //   color: COLOR.text.primary.hex,
          // },
          "& .MuiPickersDay-root": {
            backgroundColor: COLOR.background.secondary.hex,
            color: COLOR.text.secondary.hex,
            "&.Mui-selected": {
              color: COLOR.text.primary.hex,
              backgroundColor: importantStyle(COLOR.background.table.rgba(0.5)),
              ":hover": {
                backgroundColor: importantStyle(COLOR.background.table.hex),
              },
            },
            "&.MuiPickersDay-today": {
              border: importantStyle(`1px solid ${COLOR.text.secondary.hex}`),
              ":hover": { borderColor: importantStyle(COLOR.text.primary.hex) },
              "&.Mui-selected": {
                borderColor: importantStyle(COLOR.text.primary.hex),
              },
            },
            ":hover": {
              backgroundColor: COLOR.background.primary.rgba(0.14),
            },
            "&.Mui-disabled": {
              color: importantStyle(COLOR.text.secondary.rgba(0.4)),
            },
          },
          "& .MuiDayPicker-weekDayLabel:first-of-type": {
            color: importantStyle(COLOR.text.primary.hex),
          },
        },
      },
    },
    MuiDateTimePickerTabs: {
      styleOverrides: {
        root: {
          "& .MuiSvgIcon-root": {
            color: importantStyle(COLOR.text.secondary.rgba(0.7)),
          },
          "& .Mui-selected > .MuiSvgIcon-root": {
            color: importantStyle(COLOR.text.primary.hex),
          },
          ":hover": {
            "& .MuiSvgIcon-root": {
              color: importantStyle(COLOR.text.secondary.hex),
            },
            "& .Mui-selected > .MuiSvgIcon-root": {
              color: importantStyle(COLOR.text.primary.hex),
            },
          },
        },
      },
    },

    MuiPopper: {
      styleOverrides: {
        root: {
          "& .MuiDataGrid-panelWrapper": {
            background: COLOR.background.secondary.hex,
          },
          "& .MuiFormLabel-root": {
            zIndex: 99,
          },
          "& .MuiDataGrid-panelFooter": {
            button: {
              color: COLOR.text.secondary.hex,
              ":last-of-type": {
                color: COLOR.text.primary.hex,
              },
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: importantStyle(COLOR.background.secondary.hex),
          color: importantStyle(COLOR.text.secondary.hex),
        },
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          "& .MuiButtonBase-root:not(.inheritColor)": {
            color: COLOR.text.secondary.hex,
            ":last-of-type": {
              color: COLOR.text.primary.hex,
            },
          },
        },
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": { color: COLOR.text.primary.hex },
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        root: {
          color: importantStyle(COLOR.text.secondary.rgba(0.87)),
          "&.PrivatePickersToolbarText-root": {
            color: importantStyle(COLOR.text.secondary.rgba(0.7)),
            ":hover": {
              color: importantStyle(COLOR.text.secondary.hex),
            },
            "&.MuiDateTimePickerToolbar-separator": {
              color: importantStyle(COLOR.text.secondary.hex),
            },
          },
          "&.Mui-selected": {
            color: importantStyle(COLOR.text.primary.hex),
            ":hover": {
              color: importantStyle(COLOR.text.primary.hex),
            },
          },
        },
      },
    },
    MuiClockPicker: {
      styleOverrides: {
        root: {
          "& .MuiClock-clock": {
            background: COLOR.background.table.rgba(0.5),
            "& .MuiClockNumber-root": {
              color: importantStyle(COLOR.text.secondary.hex),

              "&.Mui-selected": {
                color: importantStyle(COLOR.text.primary.hex),
              },
            },
          },
        },
      },
    },

    MuiButtonBase: {
      styleOverrides: {
        root: {
          "&.MuiButton-outlined": {
            ":hover": {
              backgroundColor: COLOR.background.primary.hex,
              borderColor: COLOR.background.primary.hex,
            },
          },
          "& .MuiAccordionSummary-content": {
            "&.Mui-expanded": {
              h6: { color: importantStyle(COLOR.text.primary.hex) },
            },
          },
          "&.Mui-checked": {
            color: importantStyle(COLOR.text.primary.hex),
          },
        },
      },
    },

    MuiDataGrid: {
      styleOverrides: {
        ".MuiDataGrid-columnSeparator": {
          display: "none",
        },
        root: {
          border: "none",
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: COLOR.background.secondary.hex,
            color: COLOR.text.secondary.hex,
            boxShadow: "none",
            height: "40px",
            "& .MuiDataGrid-columnHeader": {
              outline: importantStyle("none"),
            },
          },
          "& .MuiDataGrid-row": {
            backgroundColor: importantStyle(COLOR.background.table.rgba(0.5)),
            color: COLOR.text.secondary.hex,
            ":hover": {
              backgroundColor: importantStyle(COLOR.background.table.rgba(0.8)),
            },
            "& .MuiDataGrid-cell": {
              borderTop: `0.5px solid ${COLOR.background.secondary.hex}`,
              borderBottom: `0.5px solid ${COLOR.background.secondary.hex}`,
              ":focus": {
                outline: "none",
                border: importantStyle(
                  `1px solid ${COLOR.background.secondary.hex}`
                ),
              },
            },
            // ":first-of-type": {
            //   "& .MuiDataGrid-cell": {
            //     borderTop: 0,
            //   },
            // },
          },

          "& .MuiDataGrid-footerContainer": {
            backgroundColor: COLOR.background.secondary.hex,
          },

          "& .MuiDataGrid-virtualScrollerContent": {
            marginBottom: "1.2px",
          },

          svg: {
            "&.MuiDataGrid-iconSeparator": {
              display: "none",
            },
          },
        },
      },
    },
  },
};
