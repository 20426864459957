import Collapse from '@mui/material/Collapse';

interface IProps {
  isCollapse?: boolean;
  children: React.ReactNode;
}

const CollapseArea = ({ isCollapse, children }: IProps) => {
  return (
    <div>
      <Collapse in={!isCollapse}>
        <div>{children}</div>
      </Collapse>
    </div>
  );
};

export default CollapseArea;
