import { useState } from 'react';

type UseCollapsibleFilter = () => {
  isCollapse: boolean;
  toggleFilterCollapse: () => void;
};

const useCollapsibleFilters: UseCollapsibleFilter = () => {
  const defaultState = true;
  const [isCollapse, setIsCollapse] = useState(defaultState);

  const toggleFilterCollapse = () => {
    setIsCollapse(!isCollapse);
  };

  return {
    isCollapse,
    toggleFilterCollapse,
  };
};

export default useCollapsibleFilters;
