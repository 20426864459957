import { AxiosError } from "axios";
import polyglotI18nProvider from "ra-i18n-polyglot";

import { forceLogout } from "../api/axiosInstance";
import en from "../components/i18nProvider/en";
import zh_cn from "../components/i18nProvider/zh_cn";
import { alerting } from "../reducer/alerterSlice";
import { store } from "../reducer/store";
import { logoutCodes } from "./constant";

interface ResponseFace {
  code?: number;
  message: string;
  data?: any;
  requestId: string;
}
interface generalResponse {
  result: object;
  error: any;
  requestId?: string;
}
export function appError(response: ResponseFace) {
  const { code, data, requestId, message: responseErrorMsg } = response || {};
  // TODO: revert back to error with locale once error code mapping is defined
  // const errorMessage = translateErrorCode(code || "", data);
  const errorMessage = getAppErrorMessage(responseErrorMsg, code || "");
  console.warn({ code, errorMessage }, data);
  const isForceLogout = logoutCodes.includes(String(code));
  if (isForceLogout) {
    forceLogout("", { from: "appError", code });
  }
  const requestIdMessage = requestId ? `requestId: ${requestId}` : "";
  const message = [requestIdMessage, errorMessage].filter((m) => m).join("\n");
  store.dispatch(alerting("warning", message));

  return;
}
export function networkError(result: AxiosError<Partial<generalResponse>>) {
  const data = result?.response?.data || {};
  const { requestId, error } = data;
  const requestIdMessage = requestId ? `requestId: ${requestId}` : "";
  const errorMessage = translateErrorCode(error?.code, error?.data);
  const message = [requestIdMessage, errorMessage].filter((m) => m).join("\n");
  store.dispatch(alerting("warning", message));
  console.error(result);
  if (result?.response?.status === 401) {
    //jwt unauthroized
    return forceLogout("unauthroized access", { from: "networkError" });
  }
}

export type Ilocale = "en" | "zh_cn";

const translations: Record<Ilocale, any> = { en, zh_cn };
export const translateErrorCode = (inputKey: string | number, params = {}) => {
  const key = String(inputKey || "");
  const keyWithPath = key ? `(${key})` : "";
  const lang = store.getState().profile.lang;
  const defaultMessage = { en, zh_cn }[lang].errorCode.default;
  const defaultTranslatedMessage = `${defaultMessage}${keyWithPath}`;
  return polyglotI18nProvider(
    (locale) => translations[locale as Ilocale],
    lang,
    { allowMissing: true }
  ).translate(`errorCode.${key}`, {
    _: defaultTranslatedMessage,
    ...params,
  });
};

export const getAppErrorMessage = (message: string, code: string | number) => {
  const errorCode = String(code || "");
  const lang = store.getState().profile.lang;
  const defaultMessage = { en, zh_cn }[lang].errorCode.default;
  const defaultTranslatedMessage = `${defaultMessage} (${errorCode})`;

  if (!message) {
    return defaultTranslatedMessage;
  }

  return `${message} (${errorCode})`;
};
