import {
  EPtAuth,
  EPtGeneral,
  EPtPortalUserProfile,
} from "@wallet-manager/pfh-node-def-types/dist/src/Endpoint/CreditPortal";

import { enumLanguage } from "../../utils/constant";
import { IYubiDetails } from "../../zustand/types";
import axios from "../axiosInstance";
import {
  IcardProfileNameList,
  ICurrencyList,
  IloginResult,
  ImerchantList,
  IpartnerList,
  IprogramList,
  Itimezone,
} from "../types";

// import { IYubiDetails } from "../zustand/types";
// let refreshTokenTime: Date | undefined;
// function setRefreshTokenTime(now: Date) {
//   refreshTokenTime = addMinutes(now, 37); // hard limit is 36 mins
// }

const endpointQuery = { ...EPtGeneral.EPtQuery };
const endpointAuth = { ...EPtAuth.auth };
const endpointUserProfile = { ...EPtPortalUserProfile.EPtPortalUserProfile };

const getAccessToken = async (authToken: string, config = {}) => {
  const params = { accessToken: authToken };
  const res = await axios.post<never, IloginResult>(
    endpointAuth.verifyAccessToken,
    params,
    config
  );
  return res;
};

const getNewTokens = async (refresh_token: string, config = {}) => {
  return await axios.post<never, IloginResult>(
    endpointAuth.refreshToken,
    { refreshToken: refresh_token },
    {
      ...config,
      ignoreToken: true,
    } as any
  );
};

const getMyYubiKeyStaus = async () => {
  const res = await axios.get<never, IYubiDetails>(
    "/merchant_portal/portal_users/my_details"
  );
  return res;
};

const getMerchantList = async () => {
  const res = await axios.get<never, ImerchantList[]>(endpointQuery.merchants);
  return res;
};

const getCustomerInfo = async (customerNo: string) => {
  const res = await axios.get<never, ImerchantList[]>(
    endpointQuery.customerInfo,
    {
      params: {
        customerNumber: customerNo,
      },
    }
  );
  return res;
};

const getPartnerList = async () => {
  const res = await axios.get<never, IpartnerList[]>(endpointQuery.partnerName);
  return res;
};
const getProgramList = async () => {
  const res = await axios.get<never, IprogramList[]>(
    endpointQuery.merchantProgram
  );
  return res;
};

const getCurrencyList = async () => {
  const res = await axios.get<never, ICurrencyList[]>(endpointQuery.currency);
  return res;
};

const getUserProfile = async (config?: any) => {
  const res = await axios.get<
    never,
    {
      adminPortalId: string;
      id: string | null;
      timezone: Itimezone;
      lang: keyof typeof enumLanguage;
      email: string;
      dateCreated: Date;
      dateLastModified: Date;
    }
  >(endpointUserProfile.getAdmin, config);
  return res;
};

const upsertUserProfile = async (
  params: { lang: keyof typeof enumLanguage; timezone?: string },
  config?: any
) => {
  const input = { ...params, ...config };
  const res = await axios.post<
    never,
    {
      adminPortalId: string;
      id: string | null;
      timezone: Itimezone;
      lang: keyof typeof enumLanguage;
      email: string;
      dateCreated: Date;
      dateLastModified: Date;
    }
  >(endpointUserProfile.upsert, input);
  return res;
};

async function getCardProfileName(
  params: { merchantId: string; programName: string },
  config?: any
) {
  const input = {
    params,
    ...config,
  };
  return await axios.get<never, IcardProfileNameList[]>(
    endpointQuery.cardProfile,
    input
  );
}

export default {
  getAccessToken,
  getNewTokens,
  getMyYubiKeyStaus,
  getMerchantList,
  getCurrencyList,
  getPartnerList,
  getProgramList,
  getUserProfile,
  upsertUserProfile,
  getCardProfileName,
  getCustomerInfo,
};
