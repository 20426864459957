import { useEffect } from 'react';

import { usePermission, useTabs } from '../../hooks';
import { Iprefix } from '../../hooks/useTranslation';
// import { COLOR_THEME } from '../../style/colorTheme';
import { useZusTranslatePrefixStore } from '../../zustand/store';
import { Box } from '../MuiGenerals';

function PermissionTab(props: {
    contentArr: { translatePrefix: Iprefix; element: JSX.Element }[];
    tabsArr: {
        name: string;
        value: Iprefix;
        permissionCode: string;
    }[];
    forceTranslatePrefix?: Iprefix;
    forcedTabValue?: Iprefix;
}) {
    const { contentArr, tabsArr, forcedTabValue, forceTranslatePrefix } = props;

    const { hasPermission } = usePermission();

    const filteredTabArr = tabsArr.filter(({ permissionCode }) => hasPermission(permissionCode));

    const { Tabs, tabValue, setTabIndex } = useTabs(filteredTabArr);
    const { setTranslatePrefix, translatePrefix } = useZusTranslatePrefixStore();
    useEffect(() => {
        // prevent using component in dialog that will change the translate prefix and cause locale bug
        if (forceTranslatePrefix) {
            setTranslatePrefix(forceTranslatePrefix);
        }
        else {
            setTranslatePrefix(tabValue as Iprefix);
        }
    }, [tabValue]);

    useEffect(() => {
        if (!forcedTabValue) {
            return;
        }

        setTabIndex(filteredTabArr.findIndex(({ value }) => value === forcedTabValue));
    }, [forcedTabValue]);

    return (
        <Box
            sx={{
                paddingTop: '2rem',
                paddingLeft: '2rem',
                paddingRight: '2rem',
                position: 'relative',
            }}
        >
            <Tabs sx={{ margin: '0 1.5rem', borderBottom: 'solid #E1E4EA 1px' }}>
                {contentArr.find(({ translatePrefix }) => translatePrefix === tabValue)?.element}
            </Tabs>
        </Box>
    );
}

export default PermissionTab;
