import { IAmountData } from "../../../../types/IGraphData";

export const getCategoryTotals = (amountData: IAmountData[] | undefined) => {
  const categoryTotals: { [key: string]: number } = {};

  amountData?.forEach((item: IAmountData) => {
    const { category, amount } = item;
    if (!categoryTotals[category]) {
      categoryTotals[category] = 0;
    }
    categoryTotals[category] += Number(amount);
  });

  return categoryTotals;
};
