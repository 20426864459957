import React from "react";
import { OpIconButton } from "../../../../../../components";
import { OpApprove, OpRedirect } from "../../../../../../assets/icons";
import { useTranslation } from "../../../../../../hooks";
import { translatePrefix } from "../../../config";

interface IProps {
  params?: any;
  onClick: () => void;
  isDisabled: boolean;
}

const ApproveRejectCell = (props: IProps) => {
  const { onClick, isDisabled } = props;
  const { t } = useTranslation(translatePrefix);

  return (
    <div>
      <OpIconButton
        title={t("approve_reject_request_btn")}
        svgUrl={OpApprove}
        onClick={onClick}
        size="1.5rem"
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default ApproveRejectCell;
