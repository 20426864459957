import { FeatureCreditManagement } from "@wallet-manager/pfh-node-def-types/dist/src/FeatureCode";

import api from "../../../api";
import { Ipage } from "../../../api/types";
import { Iprefix } from "../../../hooks/useTranslation";
import { initZusParams } from "../../../utils/config";
import { createZusInstance } from "../../../zustand/store";

const translatePrefix: Iprefix = "repaymentSchedule";

const PermissionKey = FeatureCreditManagement.RepaymentSchedule;

interface Ifield {
  merchantId: string;
  orderId: string;
  customerNo: string;
  currencies: string[];
  statuses: string[];
}

const initFields: Ifield = {
  merchantId: "",
  orderId: "",
  customerNo: "",
  currencies: [],
  statuses: [],
};

interface IsearchParam {
  merchantId: string;
  orderId: string;
  customerNumbers: string[];
  currencies: string[];
  statuses: string[];
  createdDateFrom: string;
  createdDateTo: string;
  lastModifiedDateFrom: string;
  lastModifiedDateTo: string;
  scheduleDateFrom: string;
  scheduleDateTo: string;
}

const useZusParams = createZusInstance<Ipage & Partial<IsearchParam>>(
  initZusParams
);

const { getAll, getExport } = api.CreditManagement.repaymentSchedule;

const apiObj: {
  export: (params: any, config?: any) => any;
  table: (params: any, config?: any) => any;
} = Object.freeze({
  export: getExport,
  table: getAll,
});

const translateKeyObj = Object.freeze({
  merchantName: "merchant_name",
  repaymentOrderId: "repayment_order_id",
  creditAdjustmentOrderId: "credit_adjustment_order_id",
  customerNo: "customer_no",
  currency: "currency",
  amount: "amount",
  step: "step",
  status: "status",
  scheduledRepaymentTime: "scheduled_repayment_time",
  createdBy: "created_by",
  creationTime: "creation_time",
  lastModifiedTime: "last_modified_time",
  operations: "operations",
  retry: "retry",
  rebroadcastCallbackPmpMessage: "rebroadcast_callback_pmp_message",
});

const omitKeyObj = Object.freeze({
  export: ['rawData'],
  table: [],
});

export {
  translatePrefix,
  PermissionKey,
  type Ifield,
  type IsearchParam,
  initFields,
  initZusParams,
  useZusParams,
  apiObj,
  translateKeyObj,
  omitKeyObj,
};
