import { Box } from "../../MuiGenerals";

const Title = ({ children }: { children: string }) => {
  return (
    <Box sx={{ marginLeft: "-8px", paddingBottom: "12px" }}>
      <Box sx={{ color: "white", fontSize: "14px" }}>{children}</Box>
    </Box>
  );
};

export default Title;
