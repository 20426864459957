import ICustomerGraphData, {
  IAmountData,
} from "../../types/ICustomerGraphData";
import { getAmountGraphData } from "./helpers/getAmountGraphData";
import { getCustomerTotals } from "./helpers/getCustomerTotals";
import { getFilteredAmountData } from "./helpers/getFilteredAmountData";
import { getTopCustomers } from "./helpers/getTopCustomers";
import { getTransformedAmountData } from "./helpers/getTransformedAmountData";

interface IOtherData {
  resultKey: keyof IAmountData;
  [key: string]: any;
}

export function convertCustomerGraphDataToLineChartData(
  graphData: ICustomerGraphData,
  otherData: IOtherData
) {
  const { amountData } = graphData;
  const { resultKey, zusParams } = otherData || {};

  // Filter top 5 customers for amount data
  const customerTotals = getCustomerTotals(amountData, resultKey);

  const topCustomers = getTopCustomers(customerTotals);

  const filteredAmountData = getFilteredAmountData(amountData, topCustomers);

  // grouping data by settlementDate
  const transformedAmountData = getTransformedAmountData({
    filteredAmountData,
    resultKey,
    dateFrom: zusParams.body.settlementDateFrom,
    dateTo: zusParams.body.settlementDateTo,
  });

  // if there is no data for a customer, add it with 0 value
  const amountGraphData = getAmountGraphData(
    transformedAmountData,
    topCustomers
  );

  const amountGraphLine =
    topCustomers.map((customerNumber) => {
      return {
        valueKey: customerNumber,
        name: customerNumber,
      };
    }) || [];

  console.log("convertCustomerGraphDataToLineChartData", {
    amountData,
    transformedAmountData,
    amountGraphData,
  });

  return {
    amountGraphData: amountGraphData || [],
    amountGraphLine: amountGraphLine || [],
  };
}
