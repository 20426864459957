import ApproveRejectCell from "../ApproveReject/ApproveRejectCell";

interface IProps {
  handleApproveRejectCellClick: () => void;
  isApproveRejectCellDisabled: boolean;
}

const OperationsCell = (props: IProps) => {
  const { handleApproveRejectCellClick, isApproveRejectCellDisabled } = props;

  return (
    <div>
      <ApproveRejectCell
        onClick={handleApproveRejectCellClick}
        isDisabled={isApproveRejectCellDisabled}
      />
    </div>
  );
};

export default OperationsCell;
