import { useQuery } from 'react-query';

import api from '../api';
import { initMyYubiDetails } from '../zustand/initialStatesFactory';
import { useYubiKeysStoreForReg } from '../zustand/store';
import { IYubiDetails } from '../zustand/types';
import usePermission from './usePermission';

export default function useYubikeyDetail(p = { isCache: false }): IYubiDetails {
  const { myDetail, setYuBiDetails } = useYubiKeysStoreForReg();
  const { isCache } = p;
  const hasRegisterYubikey = usePermission().hasPermission(
    "TOOLS/YUBI_KEY_REGISTER" // node types
  );
  useQuery("getMyYubiKeyStaus", api.CreditPortal.getMyYubiKeyStaus, {
    onSuccess: (res) => {
      setYuBiDetails(res);
    },
    enabled: hasRegisterYubikey && !isCache,
  });
  if (!hasRegisterYubikey) return initMyYubiDetails;
  return myDetail;
}
