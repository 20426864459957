import { useListMappingTransform } from "../../../../../hooks";
import { toDisplayTime } from "../../../../../utils/helper";
import { translateKeyObj as TK } from "../../config";

export const useListMapping = () => {
  const listMappingTransform = useListMappingTransform('table');
  const listMapping = (array: any[]) => {
    const res = array.map((item: any) => {
      const mappedResult = [
        ['rawData', item],
        [TK.programName, item.programName],
        [TK.month, item.month],
        [TK.statementDate, toDisplayTime(item.statementDate)],
        [TK.reportName, item.reportName],
        [TK.creationTime, toDisplayTime(item.createdAt)],
        [TK.lastModifiedTime, toDisplayTime(item.updatedAt)],
      ]
      return mappedResult;
    })
    const output = res.map(listMappingTransform)
    return output
  }
  return listMapping;
}