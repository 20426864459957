import React, { ReactNode } from "react";
import { Box } from "../../MuiGenerals";
import { COLOR } from "../../../utils/config";

export const innerBoxStyle = {
  backgroundColor: COLOR.background.secondary.hex,
  padding: "14px 40px",
};

interface IProps {
  children: ReactNode;
}

const InnerBox = ({ children }: IProps) => {
  return <Box sx={innerBoxStyle}>{children}</Box>;
};

export default InnerBox;
