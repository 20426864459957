import { FeatureCreditManagement } from "@wallet-manager/pfh-node-def-types/dist/src/FeatureCode";

import api from "../../../api";
import { Ipage } from "../../../api/types";
import { Iprefix } from "../../../hooks/useTranslation";
import { initZusParams } from "../../../utils/config";
import { createZusInstance } from "../../../zustand/store";

const translatePrefix: Iprefix = "walletTransaction";

const PermissionKey = FeatureCreditManagement.WalletTransaction;

interface Ifield {
  merchantId: string;
  orderId: string;
  customerNo: string;
  direction: string;
  walletType: string;
  transactionTypes: string[];
  currencies: string[];
  fromCustomerNo: string;
  toCustomerNo: string;
}

const initFields: Ifield = {
  merchantId: "",
  orderId: "",
  customerNo: "",
  direction: "",
  walletType: "",
  transactionTypes: [],
  currencies: [],
  fromCustomerNo: "",
  toCustomerNo: "",
};

interface IsearchParam {
  merchantId: string;
  orderId: string;
  customerNumbers: string[];
  direction: string;
  walletType: string;
  transTypes: string[];
  currencies: string[];
  fromCustomers: string[];
  toCustomers: string[];
  createdDateFrom: string;
  createdDateTo: string;
}

const useZusParams = createZusInstance<Ipage & Partial<IsearchParam>>(
  initZusParams
);

const apiObj: {
  export: (params: any, config?: any) => any;
  table: (params: any, config?: any) => any;
} = Object.freeze({
  export: api.CreditManagement.walletTransaction.getExport,
  table: api.CreditManagement.walletTransaction.getAll,
});

const translateKeyObj = Object.freeze({
  merchantName: "merchant_name",
  orderId: "order_id",
  walletType: "wallet_type",
  customerNo: "customer_no",
  direction: "direction",
  transactionType: "transaction_type",
  currency: "currency",
  previousCredit: "previous_credit",
  creditChange: "credit_change",
  postCredit: "post_credit",
  balanceChange: "balance_change",
  postBalance: "post_balance",
  from: "from",
  to: "to",
  createdBy: "created_by",
  creationTime: "creation_time",
});

const omitKeyObj = Object.freeze({
  export: [],
  table: [],
});

export {
  translatePrefix,
  PermissionKey,
  type Ifield,
  type IsearchParam,
  initFields,
  initZusParams,
  useZusParams,
  apiObj,
  translateKeyObj,
  omitKeyObj,
};
