import { ChangeEvent, useState } from "react";

import { Container } from "@mui/system";
import {
  EnumE6TransactionSubType,
  EnumE6TransactionType,
} from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Statement";
import { EnumTransactionSource } from "@wallet-manager/pfh-node-def-types/dist/src/E6Enum";

import { MpTextField, SingleSelection } from "../../../components";
import { useDatePicker } from "../../../components/DatePicker";
import { SelectChangeEvent } from "../../../components/MuiGenerals";
import CollapsibleFilters from "../../../features/common/filterTable/components/Filters/CollapsibleFilters";
import ToggleFilterButton from "../../../features/common/filterTable/components/Filters/CollapsibleFilters/components/ToggleFilterButton";
import useCollapsibleFilters from "../../../features/common/filterTable/components/Filters/CollapsibleFilters/hooks/useCollapsibleFilters";
import FilterSectionActionRow from "../../../features/common/filterTable/layout/FilterSectionActionRow";
import { Filter } from "../../../features/common/filterTable/types";
import { useGetMerchant, useGetProgram, useTranslation } from "../../../hooks";
import useCurrencyList from "../../../hooks/useCurrencyList";
import { EnumYerOrNo } from "../../../utils/constant";
import {
  Ifield,
  initFields,
  initZusParams,
  IsearchParam,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from "./config";
import ProgramNameMultipleSelection from "../../../features/common/filterTable/components/Filters/ProgramNameMultipleSelection";

const availableEnumE6TransactionType = Object.fromEntries(
  Object.entries(EnumE6TransactionType).filter(
    ([key, value]) =>
      String(key) !== String(EnumE6TransactionType.Fee) &&
      String(value) !== String(EnumE6TransactionType.Fee)
  )
);

function ViewFilter() {
  const { t, tc } = useTranslation(translatePrefix);
  const [fields, setFields] = useState<Ifield>(initFields);

  const merchantObj = useGetMerchant();
  const programObj = useGetProgram();

  const { CurrencyListEnum } = useCurrencyList();
  const { isCollapse, toggleFilterCollapse } = useCollapsibleFilters();

  const DateObj = {
    CreationTime: useDatePicker(),
  };

  const zusParams = useZusParams();

  const getParams: () => IsearchParam = () => {
    const { start: createdDateFrom, end: createdDateTo } = DateObj.CreationTime;
    const {
      programName: programNames,
      subType: transactionSubType,
      ...rest
    } = fields;

    return {
      ...initZusParams,
      ...rest,
      transactionSubType,
      createdDateFrom,
      createdDateTo,
      programNames,
    };
  };

  const apiParams = getParams();

  const onSearch = () => {
    zusParams.setBody({ ...apiParams, page: 0 });
    zusParams.refetch();
  };

  const onReset = () => {
    setFields(initFields);
    DateObj.CreationTime.clearDate();
  };

  const onChange =
    (field: keyof typeof fields) =>
    (
      e:
        | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<string>
    ) => {
      setFields((fields) => ({ ...fields, [field]: e.target.value }));
    };

  const isSubTypeFilterDisabled = !fields.transactionType;

  const filters: Filter[] = [
    {
      labelKey: TK.creationTime,
      filter: <DateObj.CreationTime.Picker type="dateTime" />,
    },
    {
      labelKey: TK.merchantName,
      filter: (
        <SingleSelection
          label={tc("phSelection", { fieldName: t(TK.merchantName) })}
          value={fields.merchantId}
          onChange={onChange("merchantId")}
          clearSelect={() =>
            setFields((fields) => ({ ...fields, merchantId: "" }))
          }
          nameFn={(name) => name}
          isNoSorting
          enumData={merchantObj}
        />
      ),
    },
    {
      labelKey: TK.programName,
      filter: (
        <ProgramNameMultipleSelection
          value={fields.programName}
          onChange={(value: string[]) =>
            setFields((fields) => ({ ...fields, programName: value }))
          }
          onClear={() =>
            setFields((fields) => ({ ...fields, programName: [] }))
          }
        />
      ),
    },
    {
      labelKey: TK.customerNumber,
      filter: (
        <MpTextField
          label={tc("phInputField", { fieldName: t(TK.customerNumber) })}
          value={fields.customerNumber}
          onChange={onChange("customerNumber")}
        />
      ),
    },
    {
      labelKey: TK.cardNumber,
      filter: (
        <MpTextField
          label={tc("phInputField", { fieldName: t(TK.cardNumber) })}
          value={fields.cardNumber}
          onChange={onChange("cardNumber")}
        />
      ),
    },
    {
      labelKey: TK.transactionId,
      filter: (
        <MpTextField
          label={tc("phInputField", { fieldName: t(TK.transactionId) })}
          value={fields.transactionId}
          onChange={onChange("transactionId")}
        />
      ),
    },
    {
      labelKey: TK.transactionType,
      filter: (
        <SingleSelection
          label={tc("phSelection", { fieldName: t(TK.transactionType) })}
          value={fields.transactionType}
          onChange={(e) => {
            onChange("transactionType")(e);
            setFields((fields) => ({ ...fields, subType: "" }));
          }}
          clearSelect={() =>
            setFields((fields) => ({ ...fields, transactionType: "" }))
          }
          enumData={availableEnumE6TransactionType}
        />
      ),
    },
    {
      labelKey: TK.subType,
      filter: (
        <SingleSelection
          label={tc("phSelection", { fieldName: t(TK.subType) })}
          value={fields.subType}
          onChange={onChange("subType")}
          clearSelect={() =>
            setFields((fields) => ({ ...fields, subType: "" }))
          }
          enumData={
            isSubTypeFilterDisabled
              ? { [t("ph_transaction_sub_type")]: "" }
              : EnumE6TransactionSubType[
                  Number(fields.transactionType) as EnumE6TransactionType
                ]
          }
          nameFn={isSubTypeFilterDisabled ? (name) => name : undefined}
        />
      ),
    },
    {
      labelKey: TK.transactionSource,
      filter: (
        <SingleSelection
          label={tc("phSelection", { fieldName: t(TK.transactionSource) })}
          value={fields.transactionSource}
          onChange={onChange("transactionSource")}
          clearSelect={() =>
            setFields((fields) => ({ ...fields, transactionSource: "" }))
          }
          enumData={EnumTransactionSource}
        />
      ),
    },
    {
      labelKey: TK.retrievalReferenceNumber,
      filter: (
        <MpTextField
          label={tc("phInputField", {
            fieldName: t(TK.retrievalReferenceNumber),
          })}
          value={fields.retrievalReferenceNumber}
          onChange={onChange("retrievalReferenceNumber")}
        />
      ),
    },
    {
      labelKey: TK.isReversal,
      filter: (
        <SingleSelection
          label={tc("phSelection", { fieldName: t(TK.isReversal) })}
          value={fields.isReversal}
          onChange={onChange("isReversal")}
          clearSelect={() =>
            setFields((fields) => ({ ...fields, isReversal: "" }))
          }
          isNoSorting={true}
          enumData={EnumYerOrNo}
        />
      ),
    },
    {
      labelKey: TK.isAuthorization,
      filter: (
        <SingleSelection
          label={tc("phSelection", { fieldName: t(TK.isAuthorization) })}
          value={fields.isAuthorization}
          onChange={onChange("isAuthorization")}
          clearSelect={() =>
            setFields((fields) => ({ ...fields, isAuthorization: "" }))
          }
          isNoSorting={true}
          enumData={EnumYerOrNo}
        />
      ),
    },
    {
      labelKey: TK.completedTransaction,
      filter: (
        <SingleSelection
          label={tc("phSelection", { fieldName: t(TK.completedTransaction) })}
          value={fields.completedTransaction}
          onChange={onChange("completedTransaction")}
          clearSelect={() =>
            setFields((fields) => ({ ...fields, completedTransaction: "" }))
          }
          isNoSorting={true}
          enumData={EnumYerOrNo}
        />
      ),
    },
    {
      labelKey: TK.programAgentId,
      filter: (
        <MpTextField
          label={tc("phInputField", { fieldName: t(TK.programAgentId) })}
          value={fields.programAgentId}
          onChange={onChange("programAgentId")}
        />
      ),
    },
    {
      labelKey: TK.distributorAgentId,
      filter: (
        <MpTextField
          label={tc("phInputField", { fieldName: t(TK.distributorAgentId) })}
          value={fields.distributorAgentId}
          onChange={onChange("distributorAgentId")}
        />
      ),
    },
    {
      labelKey: TK.hidden,
      filter: (
        <SingleSelection
          label={tc("phSelection", { fieldName: t(TK.hidden) })}
          value={fields.hidden}
          onChange={onChange("hidden")}
          clearSelect={() => setFields((fields) => ({ ...fields, hidden: "" }))}
          isNoSorting={true}
          enumData={EnumYerOrNo}
        />
      ),
    },
  ];

  return (
    <Container disableGutters maxWidth={false}>
      <FilterSectionActionRow>
        <ToggleFilterButton
          isCollapse={isCollapse}
          onClick={toggleFilterCollapse}
        />
      </FilterSectionActionRow>

      <CollapsibleFilters
        isCollapse={isCollapse}
        filters={filters}
        onSearch={onSearch}
        onReset={onReset}
      />
    </Container>
  );
}

export default ViewFilter;
