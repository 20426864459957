import { useEffect, useState } from "react";

import { DialogInOne, LoadingDialog } from "../../../../components";
import { DialogInOneProps } from "../../../../components/DialogInOne";
import { GridBox } from "../../../../components/Layout";
import { Box } from "../../../../components/MuiGenerals";
import { useAlerting, useTranslation } from "../../../../hooks";
import { useZusDialogStore } from "../../../../zustand/store";
import {
    apiObj as api,
    translateKeyObj as TK,
    translatePrefix,
    useZusParams,
} from "../config";

export type DialogModeType = "retry" | "confirmFailure";

export default function OperationDialog() {
    const [isShowLoading, setIsShowLoading] = useState(false);
    const [isReady, setIsReady] = useState(false);

    const { alerting } = useAlerting();
    const zusParams = useZusParams();
    const zusDialog = useZusDialogStore();
    const {
        requestId,
        merchantName,
        orderId,
        customerNumber,
        adjustmentType,
        currency,
        amount,
        mode,
    } = zusDialog.meta as {
        requestId: string;
        merchantName: string;
        orderId: string;
        customerNumber: string;
        adjustmentType: string;
        currency: string;
        amount: string;
        mode: DialogModeType;
    };

    const isRetry = mode === "retry";

    useEffect(() => setIsReady(true), [mode]);

    const { t } = useTranslation(translatePrefix);

    const labelElePairArr: [string, JSX.Element][] = [
        [TK.merchantName, <Box>{merchantName}</Box>],
        [TK.orderId, <Box>{orderId}</Box>],
        [TK.customerNumber, <Box>{customerNumber}</Box>],
        [TK.adjustmentType, <Box>{adjustmentType}</Box>],
        [TK.currency, <Box>{currency}</Box>],
        [TK.amount, <Box>{amount}</Box>],
    ];

    const handleCloseDialog = () => {
        setIsReady(false);
        zusDialog.closeAll();
    };


    const handleSubmit = async () => {
        const apiFn = isRetry ? api.retry : api.confirmFailure;

        const successMessage = t(
            isRetry ? TK.retrySuccess : TK.confirmFailureSuccess
        );

        setIsShowLoading(true);

        const res = await apiFn({ requestId }); // TODO: integrate with confirm failure api when it is ready

        if (!res) {
            return setIsShowLoading(false);
        }

        setIsShowLoading(false);
        handleCloseDialog();
        zusParams.refetch();
        alerting("success", successMessage);
    };

    const dialogConfig: DialogInOneProps = {
        title: t(
            isRetry? TK.retryDialogTitle : TK.confirmFailureDialogTitle
        ),
        self: {
            open: zusDialog.match("operationDialog"),
            onClose: handleCloseDialog,
        },

        content: <GridBox labelElePairArr={labelElePairArr} columnCount={1} />,
        onConfirm: handleSubmit,
        onCancel: handleCloseDialog,
        isLoadingDialog: true,
        size: "sm",
    };

    if (!isReady) {
        return <></>;
    }

    return (
        <>
            <LoadingDialog forceOpen={isShowLoading} />
            <DialogInOne {...dialogConfig} />
        </>
    );
}
