import PermissionTab from "../../../components/Layout/PermissionTab";
import useTranslation, { Iprefix } from "../../../hooks/useTranslation";
import TabDailyReport from "./TabDailyReport";
import { PermissionKey as TabDailyReportPermissionKey } from "./TabDailyReport/config";
import TabMonthlyReport from "./TabMonthlyReport";
import { PermissionKey as TabMonthlyReportPermissionKey } from "./TabMonthlyReport/config";

export default function CustomerSpendingReport() {
  const { t } = useTranslation("customerSpendingReport");
  const contentArr: {
    translatePrefix: Iprefix;
    element: JSX.Element;
  }[] = [
    {
      translatePrefix: "customerSpendingDailyReport",
      element: <TabDailyReport />,
    },
    {
      translatePrefix: "customerSpendingMonthlyReport",
      element: <TabMonthlyReport />,
    },
  ];
  const tabsArr: {
    name: string;
    value: Iprefix;
    permissionCode: string;
  }[] = [
    {
      value: "customerSpendingDailyReport",
      name: t("daily_report_title"),
      permissionCode: TabDailyReportPermissionKey.prefix,
    },
    {
      value: "customerSpendingMonthlyReport",
      name: t("monthly_report_title"),
      permissionCode: TabMonthlyReportPermissionKey.prefix,
    },
  ];

  return <PermissionTab contentArr={contentArr} tabsArr={tabsArr} />;
}
