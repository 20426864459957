import React from "react";

import { MpTextField } from "../../../../../../components";
import { Box, Typography } from "../../../../../../components/MuiGenerals";
import { useTranslation } from "../../../../../../hooks";
import { translatePrefix } from "../../../config";
import { customerInfoSearchReturnText } from "../helpers/newCardDialogUiHelper";
import CardProfileNameSingleSelection from "./CardProfileNameSingleSelection";

interface FormProps {
  newCardFields: any;
  setNewCardFields: React.Dispatch<React.SetStateAction<any>>;
  loading: boolean;
  merchant: any;
}

const Form: React.FC<FormProps> = ({
  newCardFields,
  setNewCardFields,
  loading,
  merchant,
}) => {
  const { t, tc } = useTranslation(translatePrefix);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        "& .MuiInputLabel-shrink": {
          display: "none",
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          marginBottom: "14px",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            width: "30%",
            display: "flex",
            alignItems: "center",
          }}
        >
          {t("customer_no")}
        </Typography>
        <Box
          sx={{
            // display: "flex",
            width: "70%",
            // flexDirection: "column",
            position: "relative",
          }}
        >
          <MpTextField
            sx={{ width: "100%" }}
            label={tc("phInputField", { fieldName: t("customer_no") })}
            value={newCardFields.customerNumber}
            onChange={(e) => {
              setNewCardFields({
                ...newCardFields,
                customerNumber: e.target.value,
              });
            }}
          />
          <Typography
            sx={{
              //   mt: 0.2,
              //   ml: 1.5,
              color: "#808080",
              fontSize: "0.65rem",
              position: "absolute",
              bottom: -12,
              left: 12,
            }}
          >
            {t("merchant_name") +
              ": " +
              customerInfoSearchReturnText(loading, merchant, newCardFields, t)
                .merchantName}
          </Typography>
          <Typography
            sx={{
              // mt: 0.2,
              // ml: 1.5,
              color: "#808080",
              fontSize: "0.65rem",
              position: "absolute",
              bottom: -24,
              left: 12,
            }}
          >
            {t("program_name") +
              ": " +
              customerInfoSearchReturnText(loading, merchant, newCardFields, t)
                .programName}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Typography
          variant="h6"
          sx={{ width: "30%", display: "flex", alignItems: "center" }}
        >
          {t("card_profile_name")}
        </Typography>
        <CardProfileNameSingleSelection
          value={newCardFields.cardProfileName}
          onChange={(e) => {
            setNewCardFields({
              ...newCardFields,
              cardProfileName: e.target.value,
            });
          }}
          onClearSelect={() => {
            setNewCardFields({
              ...newCardFields,
              cardProfileName: "",
            });
          }}
          merchantId={merchant?.merchantId}
          programName={merchant?.programName}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Typography
          variant="h6"
          sx={{ width: "30%", display: "flex", alignItems: "center" }}
        >
          {t("remark")}
        </Typography>
        <MpTextField
          sx={{ width: "70%" }}
          label={tc("phInputField", { fieldName: t("remark") })}
          value={newCardFields.remark}
          onChange={(e) => {
            setNewCardFields({
              ...newCardFields,
              remark: e.target.value,
            });
          }}
        />
      </Box>
    </Box>
  );
};

export default Form;
