import React from 'react';
import DialogWithOuterBox from '../../../../../components/DialogWithOuterBox';
import Content from './components/Content';


interface CreateCardOrderRequestDialogProps{
    title: string;
    isOpen: boolean;
    closeDialog: () => void;
    refreshTable: () => void;
}

const CreateCardOrderRequestDialog:React.FC<CreateCardOrderRequestDialogProps> = ({
    title,
    isOpen,
    closeDialog,
    refreshTable
}) => {
    return (
        <>
            <DialogWithOuterBox
                isOpen={isOpen}
                title={title}
                closeDialog={closeDialog}
                maxWidth="600px"
            >
                <Content closeDialog={closeDialog} refreshTable={refreshTable}/>
            </DialogWithOuterBox>
        </>
    );
}

export default CreateCardOrderRequestDialog;
