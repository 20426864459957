import {
  Dispatch,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useQuery } from "react-query";

import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import {
  EnumCreditAdjustmentStatus,
  EnumCreditAdjustmentType,
  EnumRepaymentMode,
} from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master";

import apis from "../../../api";
import { LoadingDialog } from "../../../components";
import { ButtonMenu, ExportBtn } from "../../../components/Button";
import {
  CustomPagination,
  NoRowsOverlay,
  TableTab,
} from "../../../components/Layout";
import { Container } from "../../../components/MuiGenerals";
import {
  useAlerting,
  useGetMerchant,
  useListMappingTransform,
  usePermission,
  useTranslation,
} from "../../../hooks";
import useCurrencyList from "../../../hooks/useCurrencyList";
import { useGenGridCol } from "../../../utils/ComponentHelper";
import { dataGridDefaults } from "../../../utils/constant";
import {
  displayAmountCurrying,
  downloadFiles,
  getDecimal,
  getFullApiResponse,
  toDisplayTime,
} from "../../../utils/helper";
import { customSx } from "../../../utils/styling";
import {
  apiObj as api,
  omitKeyObj,
  PermissionKey,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from "./config";
import RetryDialog from "./Dialogs/RetryDialog";

function ViewTable() {
  const { alerting } = useAlerting();
  const { tc } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const [count, setCount] = useState(0);
  const listMapping = useListMapping("export");

  const { hasPermission } = usePermission();

  const onExport = async () => {
    if (count === 0) {
      return alerting("error", tc("no_data_export"));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      api.export({ ...zusParams.body, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiFn, count, true);
    if (rawRes.length === 0) return;
    const omitKeys = omitKeyObj.export;

    const res = listMapping(rawRes, omitKeys);
    const config = {};
    downloadFiles(`Credit Adjustment Request`, res, config);
  };
  return (
    <Container
      style={customSx.datagridContainer}
      maxWidth={false}
      disableGutters
    >
      <ExportBtn
        isShown={hasPermission(PermissionKey.Export)}
        onExport={onExport}
      />
      <TableTab>
        <TableList setCount={setCount} />
      </TableTab>
    </Container>
  );
}

function TableList(props: { setCount: Dispatch<SetStateAction<number>> }) {
  const { setCount } = props;

  const [isRetryDialogOpen, setIsRetryDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [isHideOperationColumn, setIsHideOperationColumn] = useState(false);
  const [isShowLoading, setIsShowLoading] = useState(false);

  const zusParams = useZusParams();
  const { hasPermission } = usePermission();

  const { t } = useTranslation(translatePrefix);

  const listMapping = useListMapping("table");

  const { alerting } = useAlerting();

  const hasRetryPermission = hasPermission(PermissionKey.Retry);
  const hasRebroadcastPermission = hasPermission(PermissionKey.Rebroadcast);

  useLayoutEffect(() => {
    // hide operation column if don't have both permission
    if (!hasRetryPermission && !hasRebroadcastPermission) {
      setIsHideOperationColumn(true);
    }
  }, [hasRetryPermission, hasRebroadcastPermission]);

  const listRes = useQuery({
    queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],
    queryFn: () => api.table(zusParams.body),
  });

  const { rows = [], count = 0 } =
    (listRes.data as any as { rows: any[]; count: number }) || {};

  const omitKeys = omitKeyObj.table;
  const content: GridRowsProp = listMapping(rows, omitKeys);

  useEffect(() => setCount(count), [listRes]);

  const refreshTable = () => {
    zusParams.refetch();
  };

  const renderOperationCell = (params: any) => {
    const rawData = params.row.rawData;

    const isStatus = (status: string | number) =>
      String(rawData.status) === String(status);

    const isStatusStucking = isStatus(EnumCreditAdjustmentStatus.Stucking);

    const isStatusSuccess = isStatus(
      EnumCreditAdjustmentStatus.ExecutedSuccess
    );

    const isStatusFailed = isStatus(EnumCreditAdjustmentStatus.ExecutedFailed);

    const isRetryButtonEnabled = isStatusStucking;

    const isRebroadcastCallbackPmpMessageButtonEnabled =
      isStatusSuccess || isStatusFailed;

    const handleRetryButtonClick = () => {
      setSelectedRow(params.row);
      setIsRetryDialogOpen(true);
    };

    const handleRebroadcastCallbackPmpMessageButtonClick = async () => {
      setIsShowLoading(true);

      const res =
        await apis.CreditManagement.creditAdjustmentRequest.rebroadcastCallbackPmpMessage(
          { merchantId: rawData.merchantId, merchantOrderId: rawData.orderId }
        );

      setIsShowLoading(false);

      if (!res) return;

      alerting("success", t("rebroadcastSuccess"));
    };

    const buttonMenuOptions = [
      {
        name: t(TK.retry),
        onClickFn: handleRetryButtonClick,
        isDisabled: !isRetryButtonEnabled,
        noShow: !hasRetryPermission,
      },
      {
        name: t(TK.rebroadcastCallbackPmpMessage),
        onClickFn: handleRebroadcastCallbackPmpMessageButtonClick,
        isDisabled: !isRebroadcastCallbackPmpMessageButtonEnabled,
        noShow: !hasRebroadcastPermission,
      },
    ];

    return <ButtonMenu title={t(TK.operations)} options={buttonMenuOptions} />;
  };

  const columns: GridColDef[] = [
    useGenGridCol(TK.operations, {
      renderCell: renderOperationCell,
      hide: isHideOperationColumn,
    }),
    useGenGridCol(TK.merchantName, {
      minWidth: 150,
    }),
    useGenGridCol(TK.orderId, {
      minWidth: 150,
    }),
    useGenGridCol(TK.repaymentMode, {
      minWidth: 150,
    }),
    useGenGridCol(TK.adjustmentType),
    useGenGridCol(TK.customerNo, {
      minWidth: 150,
    }),
    useGenGridCol(TK.currency),
    useGenGridCol(TK.realTimeCredit, { minWidth: 150 }),
    useGenGridCol(
      TK.realTimeBalance,

      {
        minWidth: 150,
      }
    ),
    useGenGridCol(TK.adjustmentAmount),
    useGenGridCol(TK.status, {
      minWidth: 150,
    }),
    useGenGridCol(TK.createdBy, { minWidth: 150 }),
    useGenGridCol(TK.creationTime, {
      minWidth: 150,
    }),
    useGenGridCol(TK.lastModifiedTime, {
      minWidth: 150,
    }),
  ];

  if (listRes.isLoading || isShowLoading) {
    return <LoadingDialog forceOpen={true} />;
  }

  return (
    <>
      <RetryDialog
        isOpen={isRetryDialogOpen}
        closeDialog={() => setIsRetryDialogOpen(false)}
        refreshTable={refreshTable}
        selectedRow={selectedRow}
      />
      <DataGrid
        {...dataGridDefaults}
        rows={content}
        rowCount={count}
        columns={columns}
        page={zusParams.body.page}
        onPageChange={zusParams.setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: count },
        }}
      />
    </>
  );
}

const useListMapping = (mode: "export" | "table") => {
  const { t } = useTranslation("enumConstants");
  const listMappingTransform = useListMappingTransform(mode);
  const merchantObj = useGetMerchant();
  const { currencyList } = useCurrencyList();

  const getAdjustmentAmountDisplayValue = (value: string) => {
    if (value.includes("-")) {
      return value.replace(/\s/g, "").trim();
    }

    return `+${value}`;
  };

  const listMapping = (array: any[], omitKeys: string[] = []) => {
    const res = array.map((item: any) => {
      const decimal = getDecimal(item.currency, currencyList);
      const toDisplayAmount = displayAmountCurrying(decimal);

      const mappedResult = [
        [TK.merchantName, merchantObj[item.merchantId]],
        [TK.orderId, item.orderId],
        [TK.repaymentMode, t(EnumRepaymentMode[item.repaymentMode])],
        [TK.adjustmentType, t(EnumCreditAdjustmentType[item.adjustType])],
        [TK.customerNo, item.customerNumber],
        [TK.currency, item.currency],
        [TK.realTimeCredit, toDisplayAmount(item.realtimeCredit)],
        [TK.realTimeBalance, toDisplayAmount(item.realtimeBalance)],
        [
          TK.adjustmentAmount,
          getAdjustmentAmountDisplayValue(toDisplayAmount(item.adjustCredit)),
        ],
        [TK.status, t(EnumCreditAdjustmentStatus[item.status])],
        [TK.createdBy, item.createdBy],
        [TK.creationTime, toDisplayTime(item.createdDate)],
        [TK.lastModifiedTime, toDisplayTime(item.lastModifiedDate)],
        ["rawData", item],
      ].filter(([key]) => !omitKeys.includes(key));
      return mappedResult;
    });

    const output = res.map(listMappingTransform);
    return output;
  };
  return listMapping;
};

export default ViewTable;
