import { ChangeEvent, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Container } from "@mui/system";
import { EnumCardOrderStatus } from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master";

import { MpTextField, SingleSelection } from "../../../components";
import { useDatePicker } from "../../../components/DatePicker";
import { SelectChangeEvent } from "../../../components/MuiGenerals";
import CollapsibleFilters from "../../../features/common/filterTable/components/Filters/CollapsibleFilters";
import ToggleFilterButton from "../../../features/common/filterTable/components/Filters/CollapsibleFilters/components/ToggleFilterButton";
import useCollapsibleFilters from "../../../features/common/filterTable/components/Filters/CollapsibleFilters/hooks/useCollapsibleFilters";
import FilterSectionActionRow from "../../../features/common/filterTable/layout/FilterSectionActionRow";
import { Filter } from "../../../features/common/filterTable/types";
import {
  useGetMerchant,
  useGetProgram,
  usePermission,
  useTranslation,
} from "../../../hooks";
import { EnumYesNo } from "../../../utils/constant";
import CreateCardOrderRequestButton from "./components/CreateCardOrderRequestButton";
import CreateCardOrderRequestDialog from "./components/CreateNewCardRequestDialog";
import {
  Ifield,
  initFields,
  initZusParams,
  IsearchParam,
  PermissionKey,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from "./config";
import ProgramNameMultipleSelection from "../../../features/common/filterTable/components/Filters/ProgramNameMultipleSelection";

function ViewFilter() {
  const { t, tc } = useTranslation(translatePrefix);
  const [searchParams] = useSearchParams();
  const [
    isCreateCardOrderRequestDialogOpen,
    setIsCreateCardOrderRequestDialogOpen,
  ] = useState<boolean>(false);

  const [fields, setFields] = useState<Ifield>({
    ...initFields,
    orderId: searchParams.get("orderId") || "",
  });

  const merchantObj = useGetMerchant();
  const programObj = useGetProgram();
  const permission = usePermission();
  const { isCollapse, toggleFilterCollapse } = useCollapsibleFilters();

  const hasNewCardPermission = permission.hasPermission(
    PermissionKey.CreateRequest
  );

  const DateObj = {
    CreationTime: useDatePicker(),
    LastModifiedTime: useDatePicker(),
  };

  const zusParams = useZusParams();

  const getParams: () => IsearchParam = () => {
    const {
      reissued: rawReissued,
      programName: programNames,
      ...rest
    } = fields;
    const reissued = rawReissued === "" ? "" : rawReissued === "yes";
    const { start: createdDateFrom, end: createdDateTo } = DateObj.CreationTime;
    const { start: lastModifiedDateFrom, end: lastModifiedDateTo } =
      DateObj.LastModifiedTime;

    return {
      ...initZusParams,
      ...rest,
      reissued,
      createdDateFrom,
      createdDateTo,
      lastModifiedDateFrom,
      lastModifiedDateTo,
      programNames,
    };
  };

  const apiParams = getParams();

  const onSearch = () => {
    zusParams.setBody({ ...apiParams, page: 0 });
    zusParams.refetch();
  };

  const onReset = () => {
    setFields(initFields);
    DateObj.CreationTime.clearDate();
    DateObj.LastModifiedTime.clearDate();
  };

  const openCreateMerchantDepositRequestDialog = () => {
    setIsCreateCardOrderRequestDialogOpen(true);
  };

  const closeCreateMerchantDepositRequestDialog = () => {
    setIsCreateCardOrderRequestDialogOpen(false);
  };

  const refreshTable = () => {
    zusParams.refetch();
  };

  const onChange =
    (field: keyof typeof fields) =>
    (
      e:
        | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<string>
    ) => {
      setFields((fields) => ({ ...fields, [field]: e.target.value }));
    };

  useEffect(() => {
    if (searchParams.has("orderId")) {
      zusParams.setBody({ ...apiParams, page: 0 });
    }
  }, []);

  const filters: Filter[] = [
    {
      labelKey: TK.creationTime,
      filter: <DateObj.CreationTime.Picker type="dateTime" />,
    },
    {
      labelKey: TK.lastModifiedTime,
      filter: <DateObj.LastModifiedTime.Picker type="dateTime" />,
    },
    {
      labelKey: TK.merchantName,
      filter: (
        <SingleSelection
          label={tc("phSelection", { fieldName: t(TK.merchantName) })}
          value={fields.merchantId}
          onChange={onChange("merchantId")}
          clearSelect={() =>
            setFields((fields) => ({ ...fields, merchantId: "" }))
          }
          nameFn={(name) => name}
          isNoSorting
          enumData={merchantObj}
        />
      ),
    },
    {
      labelKey: TK.orderId,
      filter: (
        <MpTextField
          label={tc("phInputField", { fieldName: t(TK.orderId) })}
          value={fields.orderId}
          onChange={onChange("orderId")}
        />
      ),
    },
    {
      labelKey: TK.programName,
      filter: (
        <ProgramNameMultipleSelection
          value={fields.programName}
          onChange={(value: string[]) =>
            setFields((fields) => ({ ...fields, programName: value }))
          }
          onClear={() =>
            setFields((fields) => ({ ...fields, programName: [] }))
          }
        />
      ),
    },
    {
      labelKey: TK.customerNo,
      filter: (
        <MpTextField
          label={tc("phInputField", { fieldName: t(TK.customerNo) })}
          value={fields.customerNumber}
          onChange={onChange("customerNumber")}
        />
      ),
    },
    {
      labelKey: TK.status,
      filter: (
        <SingleSelection
          label={tc("phSelection", { fieldName: t(TK.status) })}
          value={fields.status}
          onChange={onChange("status")}
          enumData={EnumCardOrderStatus}
          clearSelect={() => setFields((f) => ({ ...f, status: "" }))}
        />
      ),
    },
    {
      labelKey: TK.reissued,
      filter: (
        <SingleSelection
          label={tc("phSelection", { fieldName: t(TK.reissued) })}
          value={fields.reissued}
          onChange={onChange("reissued")}
          enumData={EnumYesNo}
          isNoSorting={true}
          clearSelect={() => setFields((f) => ({ ...f, reissued: "" }))}
        />
      ),
    },
  ];

  return (
    <>
      <CreateCardOrderRequestDialog
        title={t(TK.newCard)}
        isOpen={isCreateCardOrderRequestDialogOpen}
        closeDialog={closeCreateMerchantDepositRequestDialog}
        refreshTable={refreshTable}
      />
      <Container disableGutters maxWidth={false}>
        <FilterSectionActionRow>
          <CreateCardOrderRequestButton
            label={t(TK.newCard)}
            isShow={hasNewCardPermission}
            onClick={openCreateMerchantDepositRequestDialog}
          />
          <ToggleFilterButton
            isCollapse={isCollapse}
            onClick={toggleFilterCollapse}
          />
        </FilterSectionActionRow>

        <CollapsibleFilters
          filters={filters}
          isCollapse={isCollapse}
          onSearch={onSearch}
          onReset={onReset}
        />
      </Container>
    </>
  );
}

export default ViewFilter;
