import { FeatureReports } from "@wallet-manager/pfh-node-def-types/dist/src/FeatureCode";

import api from "../../../api";
import { Ipage } from "../../../api/types";
import { Iprefix } from "../../../hooks/useTranslation";
import { initZusParams } from "../../../utils/config";
import { createZusInstance } from "../../../zustand/store";

const translatePrefix: Iprefix = "dailyTransaction";

const PermissionKey = FeatureReports.DailyTransaction;

interface IField {
    settlementService: string;
}

const initFields: IField = {
    settlementService: ""
};

interface ISearchParam {
    settlementService: string;
    processingDateFrom: string;
    processingDateTo: string;
}

const useZusParams = createZusInstance<Ipage & Partial<ISearchParam>>(
  initZusParams
);

const apiObj: {
  export: (params: any, config?: any) => any;
  table: (params: any, config?: any) => any;
} = Object.freeze({
  export: api.Report.dailyTransaction.getExport,
  table: api.Report.dailyTransaction.getAll,
});

const translateKeyObj = Object.freeze({
    processingDate: "processingDate",
    settlementService: "settlementService",
    e6ProcessingDate: "e6ProcessingDate",
    visaProcessingDate: "visaProcessingDate",
    e6SettlementService: "e6SettlementService",
    visaSettlementService: "visaSettlementService",
    e6OriginalTransactionAmount: "e6OriginalTransactionAmount",
    visaOriginalTransactionAmount: "visaOriginalTransactionAmount",
    exchangeRateFee: "exchangeRateFee",
    ukAdjustmentFee: "ukAdjustmentFee",
    fees: "fees",
    interestCharges: "interestCharges",
    total: "total",
    valueDate: "valueDate",
    visaCharges: "visaCharges",
    visaReimbursementFees: "visaReimbursementFees",
    netSettlementAmount: "netSettlementAmount",
});

const omitKeyObj = Object.freeze({
  export: [],
  table: [],
});

export {
  translatePrefix,
  PermissionKey,
  type IField,
  type ISearchParam,
  initFields,
  initZusParams,
  useZusParams,
  apiObj,
  translateKeyObj,
  omitKeyObj,
};
