import { Button } from "../MuiGenerals";

export default function OpTextButton(p: {
  text: string;
  onClick: () => void;
  isDisabled?: boolean;
  isHidden?: boolean;
  shape?: "rounded" | "pill";
  sx?: Object;
}) {
  const { isHidden = false, isDisabled = false, shape = "rounded" } = p;
  if (isHidden) return null;
  return (
    <Button
      sx={{
        borderRadius: shape === "rounded" ? "5px" : "1rem",
        // backgroundColor: "#4a90f7",
        maxWidth: "fit-content",
        flex: 1,
        ...p.sx,
      }}
      variant="contained"
      onClick={p.onClick}
      disabled={isDisabled}
    >
      {p.text}
    </Button>
  );
}

export const sxOpTextButton = {
  display: "flex",
  alignSelf: "auto",
  justifyContent: "center",
  width: "90%",
  columnGap: "1rem",
};
