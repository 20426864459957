import { EnumCardReissueReason } from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master/Enum/CardReissueReason";
import { SelectChangeEvent } from "../../../../../../components/MuiGenerals";

export const updateDefaultReissueBaseOnReissueReason = (e: SelectChangeEvent, updateField: (fieldName: string, value: string | boolean) => void) => {
    switch (e.target.value) {
        case EnumCardReissueReason.Lost.toString():
            updateField("samePin", false);
            updateField("samePan", false);
            updateField("deactivateCurrent", true);
            updateField("deactivateOldCardOnActivation", false);
            break;
        case EnumCardReissueReason.Damaged.toString():
            updateField("samePin", false);
            updateField("samePan", true);
            updateField("deactivateCurrent", false);
            updateField("deactivateOldCardOnActivation", true);
            break;
        case EnumCardReissueReason.Expired.toString():
            updateField("samePin", false);
            updateField("samePan", true);
            updateField("deactivateCurrent", false);
            updateField("deactivateOldCardOnActivation", true);
            break;
        case EnumCardReissueReason.Replaced.toString():
            updateField("samePin", false);
            updateField("samePan", true);
            updateField("deactivateCurrent", false);
            updateField("deactivateOldCardOnActivation", true);
            break;
        case EnumCardReissueReason.Fraud.toString():
            updateField("samePin", false);
            updateField("samePan", false);
            updateField("deactivateCurrent", true);
            updateField("deactivateOldCardOnActivation", false);
            break;
        default:
            break;
    }
}