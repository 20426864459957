import { convertDateStringToYearMonthDayString } from "../../../../../../utils/dateStringUtils";
import { ICountData } from "../../../../types/IGraphData";
import { getDatesInRange } from "../../../../utils/getDatesInRange";

export const getTransformedCountData = (
  filteredCountData: ICountData[] | undefined,
  dateFrom: string,
  dateTo: string
) => {
  const transformedCountData: { [key: string]: { [key: string]: number } } = {};

  filteredCountData?.forEach((item: ICountData) => {
    const { category, transactionCount, settlementDate } = item;

    let convertedSettlementDate =
      convertDateStringToYearMonthDayString(settlementDate);

    if (!transformedCountData[convertedSettlementDate]) {
      transformedCountData[convertedSettlementDate] = {};
    }

    if (!transformedCountData[convertedSettlementDate][category]) {
      transformedCountData[convertedSettlementDate][category] = 0;
    }

    transformedCountData[convertedSettlementDate][category] +=
      Number(transactionCount);
  });

  // Fill date range with empty data
  if (Object.keys(transformedCountData).length > 0) {
    const dateInRange = getDatesInRange(dateFrom, dateTo);

    dateInRange.forEach((date: string) => {
      if (!transformedCountData[date]) {
        transformedCountData[date] = {};
      }
    });
  }

  return transformedCountData;
};
