import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useState,
} from "react";

import MuiTab from "@mui/material/Tab";
import MuiTabs from "@mui/material/Tabs";

import { Box } from "../components/MuiGenerals";
import { COLOR } from "../utils/config";
import { useZusTabValueStore } from "../zustand/store";

interface TabsFace {
  children: ReactNode;
  tabsArr: { name: string; value: string }[];
  tabIndex: number;
  setTabIndex: Dispatch<SetStateAction<number>>;
}
export function Tabs(props: TabsFace) {
  const { children, tabsArr, tabIndex, setTabIndex } = props;
  const { setTabValue } = useZusTabValueStore();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
    setTabValue(tabsArr[newValue].value);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: `rgba(${COLOR.text.secondary.rgb}, 0.5)`,
          marginBottom: "1rem",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <MuiTabs value={tabIndex} onChange={handleChange} aria-label="tabs">
            {tabsArr.map(({ name }, i) => (
              <MuiTab key={i} label={name} />
            ))}
          </MuiTabs>
        </div>
      </Box>
      {children}
    </Box>
  );
}
function useTabs(tabsArr: { name: string; value: string }[] | []) {
  const [tabIndex, setTabIndex] = useState(0);
  const PreTabs = useCallback(
    (props: any) => (
      <Tabs
        {...props}
        tabsArr={tabsArr}
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
      />
    ),
    [tabIndex]
  );
  const tabValue = tabsArr[tabIndex]?.value;
  return { Tabs: PreTabs, tabValue, setTabIndex };
}
export default useTabs;
/*usage
  const tabsArr = [{ name: t("table"), value: "" }];
  const [tabIndex, setTabIndex] = useState(0);
 */
