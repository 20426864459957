import { COLOR } from "../../../../utils/config";

const CustomTooltip: React.FC<any> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const exactDate = payload[0].payload.name;

    return (
      <div
        style={{
          backgroundColor: COLOR.Chart.LineChart.TooltipContentBackground,
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        <p>{exactDate}</p>

        {payload.map((entry: any, index: any) => {
          const labelValuePair = `${entry.name}: ${entry.value}`;

          return (
            <div key={`item-${index}`}>
              <p
                style={{
                  color:
                    COLOR.Chart.LineChart.LineColor[
                      index % COLOR.Chart.LineChart.LineColor.length
                    ],
                }}
              >
                {labelValuePair}
              </p>
            </div>
          );
        })}
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
