import { useTranslation } from "../../../../../hooks";
import { Button } from "../../../../../components/MuiGenerals";

const ActionButtons = ({ handleApproveClick, handleRejectClick }: any) => {
  const { t } = useTranslation("approvalProgress");
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Button
        className={"inheritColor"}
        color={"primary"}
        variant={"contained"}
        children={t("approve_btn")}
        onClick={handleApproveClick}
        style={{ marginRight: "12px" }}
      />
      <Button
        className={"inheritColor"}
        color={"primary"}
        variant={"contained"}
        children={t("reject_btn")}
        onClick={handleRejectClick}
        style={{ marginRight: "12px" }}
      />
    </div>
  );
};

export default ActionButtons;
