import { Outlet } from "react-router";

import { LoadingDialog, LoadingProgressDialog } from "../components";

interface IProps {
  isLoadingDialogOpen: boolean;
}

export default function LoadingDialogProvider(props: IProps) {
  const { isLoadingDialogOpen } = props;

  return (
    <>
      <LoadingProgressDialog />
      <LoadingDialog forceOpen={isLoadingDialogOpen} />
      <Outlet />
    </>
  );
}
