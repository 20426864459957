import { useQuery } from "react-query";

import api from "../../api";
import { useAppDispatch, useAppSelector } from "../../reducer/hooks";
import {
  selectPartnerList,
  setPartnerList,
} from "../../reducer/partnerListSlice";

export async function useFetchPartner() {
  const dispatch = useAppDispatch();

  useQuery("partnerList", api.CreditPortal.getPartnerList, {
    staleTime: 60 * 60 * 12,
    onSuccess: (dbRes) => {
      dispatch(setPartnerList(dbRes));
    },
  });
}

export function useGetPartner() {
  const partnerList = useAppSelector(selectPartnerList);
  return partnerList.list;
}
