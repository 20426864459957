import IGraphData from "../../types/IGraphData";
import { getAmountDataFilteredByTopCategories } from "./helpers/amountGraph/getAmounDataFilteredByTopCategories";
import { getAmountGraphData } from "./helpers/amountGraph/getAmountGraphData";
import { getCategoryTotals } from "./helpers/amountGraph/getCategoryTotals";
import { getTopCategories } from "./helpers/amountGraph/getTopCategories";
import { getTransformedAmountData } from "./helpers/amountGraph/getTransformedAmountData";
import { getCategoriesCountTotals } from "./helpers/countGraph/getCategoriesCountTotals";
import { getCountGraphData } from "./helpers/countGraph/getCountGraphData";
import { getFilteredCountData } from "./helpers/countGraph/getFilteredCountData";
import { getTopCountCategories } from "./helpers/countGraph/getTopCountCategories";
import { getTransformedCountData } from "./helpers/countGraph/getTransformedCountData";

export function convertCategoryGraphDataToLineChartData(
  graphData: IGraphData,
  otherData?: any
) {
  const { countData, amountData } = graphData;

  const { zusParams } = otherData || {};

  // Amount Graph
  const categoryTotals = getCategoryTotals(amountData);

  const topCategories = getTopCategories(categoryTotals);

  const filteredAmountData = getAmountDataFilteredByTopCategories(
    amountData,
    topCategories
  );
  const transformedAmountData = getTransformedAmountData(
    filteredAmountData,
    zusParams.body.settlementDateFrom,
    zusParams.body.settlementDateTo
  );

  const amountGraphData = getAmountGraphData(
    transformedAmountData,
    topCategories
  );

  const amountGraphLine =
    topCategories.map((category) => {
      return {
        valueKey: category,
      };
    }) || [];

  // Count Graph
  const categoriesCountTotals = getCategoriesCountTotals(countData);

  const topCountCategories = getTopCountCategories(categoriesCountTotals);

  const filteredCountData = getFilteredCountData(countData, topCountCategories);

  const transformedCountData = getTransformedCountData(
    filteredCountData,
    zusParams.body.settlementDateFrom,
    zusParams.body.settlementDateTo
  );

  const countGraphData = getCountGraphData(
    transformedCountData,
    topCountCategories
  );

  const countGraphLine = topCountCategories.map((category) => {
    return {
      valueKey: category,
    };
  });

  console.group("category graph");
  console.log("amountData = ", amountData);
  console.log("amountGraphData", amountGraphData);
  console.log("transformedAmountData = ", transformedAmountData);
  console.groupEnd();

  return {
    amountGraphData: amountGraphData || [],
    amountGraphLine: amountGraphLine || [],
    countGraphData: countGraphData || [],
    countGraphLine: countGraphLine || [],
  };
}
