import { ICollapsibleFiltersConfig } from "..";

interface IProps {
  children: React.ReactNode[];
  config?: ICollapsibleFiltersConfig;
}

const FilterContainer = ({ children, config }: IProps) => {
  const { leftLabelWidth, rightLabelWidth } = config || {};
  const defaultLabelColumnWidth = "minmax(max-content, 80px)";

  const firstColumnWidth = leftLabelWidth
    ? leftLabelWidth
    : defaultLabelColumnWidth;
  const thirdColumnWidth = rightLabelWidth
    ? rightLabelWidth
    : defaultLabelColumnWidth;

  return (
    <div
      className="filters-container"
      style={{
        gridTemplateColumns: `${firstColumnWidth} minmax(0,1fr) ${thirdColumnWidth} minmax(0,1fr)`,
      }}
    >
      {children}
    </div>
  );
};

export default FilterContainer;
