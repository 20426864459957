interface GetCellTextProps {
    numberOfApproved: number;
    numberOfApprover: number;
    isAuto: boolean;
}

const getDisplayApprovalProgress = (props: GetCellTextProps) => {
    const { numberOfApproved, numberOfApprover, isAuto } = props;

    if (isAuto) {
        return "Auto";
    }
    return `${numberOfApproved}/${numberOfApprover}`;
};

export default getDisplayApprovalProgress;
