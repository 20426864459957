import { ValidatorResponse } from "../../hooks/useValidation/types";

export const largerThanZeroValidator =
  (errorMessage: string) =>
  (value: string | number): ValidatorResponse => {
    const val = Number(value);

    if (!val || val <= 0 || isNaN(val)) {
      return { isValid: false, errorMessage };
    }

    return { isValid: true, errorMessage: "" };
  };
