import { ChangeEvent, useRef, useState } from "react";

import {
  EnumAdjustmentSubType,
  EnumBalanceAdjustmentType,
} from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master";

import {
  DialogInOne,
  MpTextField,
  SingleSelection,
} from "../../../../components";
import { GridBox } from "../../../../components/Layout";
import { Box, SelectChangeEvent } from "../../../../components/MuiGenerals";
import MpTextFieldWithEndAdornment from "../../../../components/TextField/MpTextFieldWithEndAdornment";
import { useAlerting, useGetMerchant, useTranslation } from "../../../../hooks";
import { useZusDialogStore } from "../../../../zustand/store";
import {
  apiObj as api,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from "../config";

interface Ifield {
  merchantId: string;
  customerNumber: string;
  adjustmentType: string;
  subType: string;
  amount: string;
  memo: string;
  relatedTransactionId: string;
}
const initFields: Ifield = {
  merchantId: "",
  customerNumber: "",
  adjustmentType: "",
  subType: "",
  amount: "",
  memo: "",
  relatedTransactionId: "",
};

interface IapiParams {
  merchantId: number;
  customerNumber: string;
  type: number;
  amount: string;
  currencyCode: string;
  memo: string;
  transactionSubType: number;
  transactionId: string;
}

interface AvailableBalanceApiRes {
  availableCredit: number;
  overallCreditLimit: number;
  availableBalance: number;
  merchantProgram: MerchantProgram;
}

interface MerchantProgram {
  merchantId: number;
  programName: string;
  currency: string;
}

export default function CreateRequestDialog() {
  const zusDialog = useZusDialogStore();
  const { t, tc } = useTranslation(translatePrefix);
  const [fields, setFields] = useState<Ifield>(initFields);
  const [availableBalanceAndCurrency, setAvailableBalanceAndCurrency] =
    useState<{ availableBalance: number; currency: string }>();
  const zusParams = useZusParams();

  const merchantObj = useGetMerchant();

  const { alerting } = useAlerting();

  const onChange =
    (field: keyof Ifield) =>
    (
      e:
        | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<string>
    ) =>
      setFields((fields) => ({ ...fields, [field]: e.target.value }));

  const getParams: () => IapiParams = () => {
    const {
      merchantId: merchantIdStr,
      adjustmentType,
      subType,
      relatedTransactionId,
      ...rest
    } = fields;
    const merchantId = Number(merchantIdStr);

    const type = Number(adjustmentType);

    return {
      ...rest,
      merchantId,
      type,
      currencyCode: availableBalanceAndCurrency?.currency || "",
      transactionSubType: Number(subType),
      transactionId: relatedTransactionId,
    };
  };

  const apiParams = getParams();

  const handleCloseDialog = () => {
    zusDialog.close();
    setFields(initFields);
    setAvailableBalanceAndCurrency(undefined);
  };

  const onSubmit = async () => {
    const emptyFieldValueArr = Object.entries(fields).filter(
      ([field, value]) =>
        !value && field !== "memo" && field !== "relatedTransactionId"
    );
    const firstEmptyFieldArr = emptyFieldValueArr[0];
    if (firstEmptyFieldArr) {
      const [key]: Array<keyof typeof fields> = firstEmptyFieldArr;

      const isSelectionKey = key === "merchantId" || key === "adjustmentType";
      if (isSelectionKey) {
        return alerting(
          "warning",
          tc("phSelection", {
            fieldName: t(TK[key === "merchantId" ? "merchantName" : key]),
          })
        );
      }

      if (key === "amount" && !availableBalanceAndCurrency) {
        return alerting("warning", t(TK.customerNumberNotExist));
      }

      return alerting("warning", tc("phInputField", { fieldName: t(TK[key]) }));
    }

    if (
      availableBalanceAndCurrency &&
      Number(fields.adjustmentType) === EnumBalanceAdjustmentType.Withdraw &&
      Number(fields.amount) > availableBalanceAndCurrency.availableBalance
    ) {
      return alerting("warning", t(TK.balanceNotEnoughWarning));
    }

    const res = await api.createRequest(apiParams);

    if (!res) {
      return;
    }

    alerting("success", t(TK.createdRequestSuccessfully));
    handleCloseDialog();
    zusParams.refetch();
  };

  const timeoutIDRef = useRef<NodeJS.Timeout>();

  const handleCustomerNumberChange = (
    e:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
  ) => {
    clearTimeout(timeoutIDRef.current);

    onChange("customerNumber")(e);

    if (!e.target.value || !fields.merchantId) {
      setAvailableBalanceAndCurrency(undefined);
      return;
    }

    const timeoutID = setTimeout(async () => {
      const res: AvailableBalanceApiRes | undefined =
        await api.currentBalanceAndProgram({
          merchantId: fields.merchantId,
          customerNumber: e.target.value,
        });

      setAvailableBalanceAndCurrency(
        res && {
          availableBalance: res.availableBalance,
          currency: res.merchantProgram.currency,
        }
      );

      if (!res) {
        setFields((f) => ({ ...f, amount: "" }));
        alerting("warning", t(TK.customerNumberNotExist));
      }
    }, 300);
    timeoutIDRef.current = timeoutID;
  };

  const handleMerchantIdChange = async (
    e:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
  ) => {
    onChange("merchantId")(e);

    if (!fields.customerNumber) {
      setAvailableBalanceAndCurrency(undefined);
      return;
    }

    const res = await api.currentBalanceAndProgram({
      merchantId: e.target.value,
      customerNumber: fields.customerNumber,
    });

    setAvailableBalanceAndCurrency(
      res && {
        availableBalance: res.availableBalance,
        currency: res.merchantProgram.currency,
      }
    );

    if (!res) {
      setFields((f) => ({ ...f, amount: "" }));
      alerting("warning", t(TK.customerNumberNotExist));
    }
  };

  const getSubTypeEnum = () => {
    if (!fields.adjustmentType) {
      return {};
    }

    const adjustmentType = Number(
      fields.adjustmentType
    ) as EnumBalanceAdjustmentType;

    return EnumAdjustmentSubType[adjustmentType];
  };

  const SubTypeEnum = getSubTypeEnum();

  const labelElePairArr: Array<[string, JSX.Element]> = [
    [
      TK.merchantName,
      <SingleSelection
        label={tc("phSelection", { fieldName: t(TK.merchantName) })}
        value={fields.merchantId}
        onChange={handleMerchantIdChange}
        clearSelect={() => {}}
        nameFn={(name) => name}
        isNoSorting
        enumData={merchantObj}
      />,
    ],
    [
      TK.customerNumber,
      <MpTextField
        label={tc("phInputField", { fieldName: t(TK.customerNumber) })}
        value={fields.customerNumber}
        onChange={handleCustomerNumberChange}
      />,
    ],
    [
      TK.adjustmentType,
      <SingleSelection
        label={tc("phSelection", { fieldName: t(TK.adjustmentType) })}
        value={fields.adjustmentType}
        onChange={(e) =>
          setFields((prev) => ({
            ...prev,
            adjustmentType: e.target.value,
            subType: "",
          }))
        }
        clearSelect={() => {}}
        enumData={EnumBalanceAdjustmentType}
      />,
    ],
    [
      TK.subType,
      <SingleSelection
        label={tc("phSelection", { fieldName: t(TK.subType) })}
        value={fields.subType}
        onChange={onChange("subType")}
        clearSelect={() => {}}
        enumData={SubTypeEnum}
      />,
    ],
    [
      TK.currentAvailableBalance,
      <Box>
        {availableBalanceAndCurrency
          ? `${availableBalanceAndCurrency.availableBalance} ${availableBalanceAndCurrency.currency}`
          : "-"}
      </Box>,
    ],
    [
      TK.amount,
      <MpTextFieldWithEndAdornment
        label={tc("phInputField", { fieldName: t(TK.amount) })}
        value={fields.amount}
        onChange={onChange("amount")}
        mode={"number"}
        endAdornmentNode={availableBalanceAndCurrency?.currency || ""}
        disabled={!availableBalanceAndCurrency}
        decimalCount={2}
      />,
    ],
    [
      TK.memo,
      <MpTextField
        label={tc("optional")}
        value={fields.memo}
        onChange={onChange("memo")}
      />,
    ],
    [
      TK.relatedTransactionId,
      <MpTextField
        label={tc("optional")}
        value={fields.relatedTransactionId}
        onChange={onChange("relatedTransactionId")}
      />,
    ],
  ];

  const dialogConfig = {
    title: t("create_request"),
    self: {
      open: zusDialog.match("createRequestDialog"),
      onClose: handleCloseDialog,
    },
    content: <GridBox labelElePairArr={labelElePairArr} />,
    onConfirm: onSubmit,
    onCancel: handleCloseDialog,
    isLoadingDialog: true,
  };

  return <DialogInOne {...dialogConfig} />;
}
