import BigNumber from 'bignumber.js';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';

import { LoadingDialog } from '../../../components';
import { ExportBtn } from '../../../components/Button';
import {
  CustomPagination,
  NoRowsOverlay,
  TableTab
} from '../../../components/Layout';
import { Container } from '../../../components/MuiGenerals';
import {
  useAlerting,
  useGetMerchant,
  useListMappingTransform,
  usePermission,
  useTranslation
} from '../../../hooks';
import useCurrencyList from '../../../hooks/useCurrencyList';
import { useGenGridCol } from '../../../utils/ComponentHelper';
import { dataGridDefaults } from '../../../utils/constant';
import {
  displayAmountCurrying,
  downloadFiles,
  getDecimal,
  getFullApiResponse,
  toDisplayTime
} from '../../../utils/helper';
import { customSx } from '../../../utils/styling';
import {
  apiObj as api,
  omitKeyObj,
  PermissionKey,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams
} from './config';

function ViewTable() {
  const { alerting } = useAlerting();
  const { tc } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const [count, setCount] = useState(0);
  const listMapping = useListMapping("export");
  const { hasPermission } = usePermission();

  const onExport = async () => {
    if (count === 0) {
      return alerting("error", tc("no_data_export"));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      api.export({ ...zusParams.body, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiFn, count, true);
    if (rawRes.length === 0) return;
    const omitKeys = omitKeyObj.export;

    const res = listMapping(rawRes, omitKeys);
    const config = {};
    downloadFiles(`Merchant Wallet`, res, config);
  };
  return (
    <Container
      style={customSx.datagridContainer}
      maxWidth={false}
      disableGutters
    >
      <ExportBtn
        isShown={hasPermission(PermissionKey.Export)}
        onExport={onExport}
      />
      <TableTab>
        <TableList setCount={setCount} />
      </TableTab>
    </Container>
  );
}

function TableList(props: { setCount: Dispatch<SetStateAction<number>> }) {
  const { setCount } = props;

  const zusParams = useZusParams();

  const listRes = useQuery({
    queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],
    queryFn: () => api.table(zusParams.body),
  });

  const { rows = [], count = 0 } =
    (listRes.data as any as { rows: any[]; count: number }) || {};

  const omitKeys = omitKeyObj.table;

  const listMapping = useListMapping("table");

  const content: GridRowsProp = listMapping(rows, omitKeys);

  useEffect(() => setCount(count), [listRes]);

  const columns: GridColDef[] = [
    useGenGridCol(TK.merchantName, {
      minWidth: 150,
    }),
    useGenGridCol(TK.walletName, {
      minWidth: 150,
    }),
    useGenGridCol(TK.currency),
    useGenGridCol(TK.creditLimit, { minWidth: 150 }),
    useGenGridCol(TK.balance, { minWidth: 150 }),
    useGenGridCol(TK.assignedCredit, { minWidth: 150 }),
    useGenGridCol(TK.availableCredit, { minWidth: 150 }),
    useGenGridCol(TK.createdBy, {
      minWidth: 150,
    }),
    useGenGridCol(TK.creationTime, { minWidth: 150 }),
    useGenGridCol(TK.lastModifiedTime, {
      minWidth: 150,
    }),
  ];

  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;
  return (
    <>
      <DataGrid
        {...dataGridDefaults}
        rows={content}
        rowCount={count}
        columns={columns}
        page={zusParams.body.page}
        onPageChange={zusParams.setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: count },
        }}
      />
    </>
  );
}

const useListMapping = (mode: "export" | "table") => {
  const listMappingTransform = useListMappingTransform(mode);
  const merchantObj = useGetMerchant();
  const { currencyList } = useCurrencyList();

  const listMapping = (array: any[], omitKeys: string[] = []) => {
    const res = array.map((item: any) => {
      const getAvailableCredit = () => {
        const { balance, creditLimit, credit } = item;

        return BigNumber(balance)
          .plus(BigNumber(creditLimit))
          .plus(BigNumber(credit))
          .toFixed();
      };

      const availableCredit = getAvailableCredit();

      const decimal = getDecimal(item.currency, currencyList);
      const displayAmount = displayAmountCurrying(decimal);
      const mappedResult = [
        [TK.merchantName, merchantObj[item.merchantId]],
        [TK.walletName, item.walletName],
        [TK.currency, item.currency],
        [TK.creditLimit, displayAmount(item.creditLimit)],
        [TK.balance, displayAmount(item.balance)],
        [TK.assignedCredit, displayAmount(item.credit)],
        [TK.availableCredit, displayAmount(availableCredit)],
        [TK.createdBy, item.createdBy],
        [TK.creationTime, toDisplayTime(item.createdDate)],
        [TK.lastModifiedTime, toDisplayTime(item.lastModifiedDate)],
      ].filter(([key]) => !omitKeys.includes(key));
      return mappedResult;
    });

    const output = res.map(listMappingTransform);
    return output;
  };
  return listMapping;
};

export default ViewTable;
