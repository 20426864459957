import { useState } from "react";
import DialogWithOuterBox from "../../../../../../components/DialogWithOuterBox";
import Content from "./components/Content";
import { IField } from "./types";

const initFields = {
  merchantId: "",
  currency: "",
  amount: "",
};

export interface CreateMerchantDepositRequestDialogProps {
  title: string;
  isOpen: boolean;
  closeDialog: () => void;
  refreshTable: () => void;
}

const CreateMerchantDepositRequestDialog = (
  props: CreateMerchantDepositRequestDialogProps
) => {
  const { title, isOpen, closeDialog, refreshTable } = props;

  const [fields, setFields] = useState<IField>(initFields);

  const handleCloseDialog = () => {
    setFields(initFields);
    closeDialog();
  };

  return (
    <DialogWithOuterBox
      isOpen={isOpen}
      title={title}
      closeDialog={handleCloseDialog}
      maxWidth="600px"
    >
      <Content
        fields={fields}
        setFields={setFields}
        closeDialog={handleCloseDialog}
        refreshTable={refreshTable}
      />
    </DialogWithOuterBox>
  );
};

export default CreateMerchantDepositRequestDialog;
