import { useState } from "react";
import { getInitValidationResult } from "./helpers/getInitValidationResult";
import { normalizeConfig } from "./helpers/normalizeConfig";
import { getValidationResult } from "./helpers/getValidationResult";
import { getAllErrors } from "./helpers/getAllErrors";
import { ValidationConfigProps, ValidationResult } from "./types";

const useValidation = (config: ValidationConfigProps) => {
  const [isAllClean, setIsAllClean] = useState(false);
  const [validationResult, setValidationResult] = useState<ValidationResult>(
    getInitValidationResult(config)
  );
  const [allErrors, setAllErrors] = useState<string[]>([]);

  const validate = () => {
    const validationConfig = normalizeConfig(config);

    const { result, isClean } = getValidationResult(validationConfig);

    const allValidationErrors = getAllErrors(result, validationConfig);

    setValidationResult(result);
    setAllErrors(allValidationErrors);
    setIsAllClean(isClean);

    return {
      validationResult: result,
      allErrors: allValidationErrors,
      isAllClean,
    };
  };

  return {
    validate,
    validationResult,
    allErrors,
    isAllClean,
  };
};

export default useValidation;
