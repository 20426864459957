import { create } from "zustand";
import { FeatureCreditManagement } from "@wallet-manager/pfh-node-def-types/dist/src/FeatureCode";

import api from "../../../api";
import { Ipage } from "../../../api/types";
import { Iprefix } from "../../../hooks/useTranslation";
import { initZusParams } from "../../../utils/config";
import { createZusInstance } from "../../../zustand/store";

const translatePrefix: Iprefix = "cardOrderRequest";

const PermissionKey = FeatureCreditManagement.CardOrderRequest;

interface Ifield {
  merchantId: string;
  orderId: string;
  programName: string[];
  customerNumber: string;
  status: string;
  reissued: string;
}

const initFields: Ifield = {
  merchantId: "",
  orderId: "",
  programName: [],
  customerNumber: "",
  status: "",
  reissued: "",
};

interface IsearchParam {
  merchantId: string;
  orderId: string;
  programNames: string[];
  customerNumber: string;
  status: string;
  reissued: boolean | string;
  createdDateFrom: string;
  createdDateTo: string;
  lastModifiedDateFrom: string;
  lastModifiedDateTo: string;
}

const useZusParams = createZusInstance<Ipage & Partial<IsearchParam>>(
  initZusParams
);

const apiObj: {
  export: (params: any, config?: any) => any;
  table: (params: any, config?: any) => any;
  create: (params: any, config?: any) => any;
} = Object.freeze({
  export: api.CreditManagement.cardOrderRequest.getExport,
  table: api.CreditManagement.cardOrderRequest.getAll,
  create: api.CreditManagement.cardOrderRequest.postCreateCard,
});

const translateKeyObj = Object.freeze({
  merchantName: "merchant_name",
  orderId: "order_id",
  programName: "program_name",
  customerNo: "customer_no",
  cardType: "card_type",
  cardProfileName: "card_profile_name",
  status: "status",
  remark: "remark",
  reissued: "reissued",
  expiryDate: "expiry_date",
  reissueReason: "reissue_reason",
  originalCardNo: "original_card_no",
  samePan: "same_pan",
  samePin: "same_pin",
  deactivatedCurrentCard: "deactivated_current_card",
  deactivatedOriginalCardOnActivation:
    "deactivated_original_card_on_activation",
  creationTime: "creation_time",
  lastModifiedTime: "last_modified_time",
  newCard: "new_card",
});

const omitKeyObj = Object.freeze({
  export: [],
  table: [],
});

export {
  translatePrefix,
  PermissionKey,
  type Ifield,
  type IsearchParam,
  initFields,
  initZusParams,
  useZusParams,
  apiObj,
  translateKeyObj,
  omitKeyObj,
};
