import { Box } from "../../MuiGenerals";

const closeBtnStyle = {
  position: "absolute",
  top: "8px",
  right: "8px",
  color: "#7a7b7e",
  fontSize: "12px",
  cursor: "pointer",
};

interface IProps {
  handleClick: () => void;
}

const CloseBtn = ({ handleClick }: IProps) => {
  return (
    <Box sx={closeBtnStyle} onClick={handleClick}>
      X
    </Box>
  );
};

export default CloseBtn;
