import { GridRenderCellParams } from '@mui/x-data-grid';
import { translateKeyObj as TK, translatePrefix } from "../../config";
import { OpIconButton } from '../../../../../components';
import { OpDownload } from "../../../../../assets/icons";
import { useTranslation } from '../../../../../hooks';
import { useApiCall } from './useApiCall';

const DownloadCell = (params: GridRenderCellParams<any, any, any>) => {
  const monthlyStatementId: string = params.row['rawData'].id;
  const reportName: string = params.row[TK.reportName]
  const { download } = useApiCall();
  const { tc } = useTranslation(translatePrefix);
  return (
    <OpIconButton
      title={tc("download")}
      svgUrl={OpDownload}
      onClick={() => download(monthlyStatementId, reportName)}
    />
  );
}

export default DownloadCell