import { RefObject } from 'react';

import TextField, { TextFieldProps } from '@mui/material/TextField';

import { inputCharacterMaxLength } from '../../utils/constant';

type MpTextFieldProps = TextFieldProps & {
  innerRef?: RefObject<HTMLDivElement>;
};
export default function MpTextField(props: MpTextFieldProps) {
  const { innerRef, ...rest } = props;
  const newProps = {
    ...rest,
    ref: innerRef,
    inputProps: { maxLength: inputCharacterMaxLength, ...props.inputProps },
  };

  return <TextField {...newProps} />;
}
