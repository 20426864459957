import { subDays } from "date-fns";
import { ChangeEvent, useState } from "react";

import { Container } from "@mui/system";

import { MpTextField, SingleSelection } from "../../../../components";
import { useDatePicker } from "../../../../components/DatePicker";
import { SelectChangeEvent } from "../../../../components/MuiGenerals";
import CollapsibleFilters from "../../../../features/common/filterTable/components/Filters/CollapsibleFilters";
import ToggleFilterButton from "../../../../features/common/filterTable/components/Filters/CollapsibleFilters/components/ToggleFilterButton";
import useCollapsibleFilters from "../../../../features/common/filterTable/components/Filters/CollapsibleFilters/hooks/useCollapsibleFilters";
import FilterSectionActionRow from "../../../../features/common/filterTable/layout/FilterSectionActionRow";
import { Filter } from "../../../../features/common/filterTable/types";
import {
  useGetMerchant,
  useGetProgram,
  useTranslation,
} from "../../../../hooks";
import useCurrencyList from "../../../../hooks/useCurrencyList";
import {
  Ifield,
  initFields,
  initZusParams,
  IsearchParam,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from "./config";

function ViewFilter() {
  const { t, tc } = useTranslation(translatePrefix);
  const [fields, setFields] = useState<Ifield>(initFields);
  const [isSearchButtonClicked, setIsSearchButtonClicked] = useState(false);

  const merchantObj = useGetMerchant();
  const { CurrencyListEnum } = useCurrencyList();
  const programObj = useGetProgram();

  const { isCollapse, toggleFilterCollapse } = useCollapsibleFilters();

  const DateObj = {
    SettlementDate: useDatePicker({
      start: {
        defaultDate: subDays(new Date(), 1),
      },
      end: {
        defaultDate: subDays(new Date(), 1),
      },
    }),
  };

  const zusParams = useZusParams();

  const getParams: () => IsearchParam = () => {
    const { pfhNo: customerNumber, ...rest } = fields;

    const { start: settlementDateFrom, end: settlementDateTo } =
      DateObj.SettlementDate;

    return {
      ...initZusParams,
      ...rest,
      customerNumber,
      settlementDateFrom,
      settlementDateTo,
    };
  };

  const apiParams = getParams();

  const onSearch = () => {
    if (!isSearchButtonClicked) {
      setIsSearchButtonClicked(true);
    }

    zusParams.setBody({ ...apiParams, page: 0 });
    zusParams.refetch();
  };

  const onReset = () => {
    setFields(initFields);
    DateObj.SettlementDate.resetDate();
  };

  const onChange =
    (field: keyof typeof fields) =>
    (
      e:
        | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<string>
    ) => {
      setFields((fields) => ({ ...fields, [field]: e.target.value }));
    };

  const filters: Filter[] = [
    {
      labelKey: TK.settlementDate,
      filter: <DateObj.SettlementDate.Picker type="date" />,
    },
    {
      labelKey: TK.programAgentId,
      filter: (
        <MpTextField
          label={tc("phInputField", { fieldName: t(TK.programAgentId) })}
          value={fields.programAgentId}
          onChange={onChange("programAgentId")}
        />
      ),
    },
    {
      labelKey: TK.distributorAgentId,
      filter: (
        <MpTextField
          label={tc("phInputField", { fieldName: t(TK.distributorAgentId) })}
          value={fields.distributorAgentId}
          onChange={onChange("distributorAgentId")}
        />
      ),
    },

    {
      labelKey: TK.pfhNo,
      filter: (
        <MpTextField
          label={tc("phInputField", { fieldName: t(TK.pfhNo) })}
          value={fields.pfhNo}
          onChange={onChange("pfhNo")}
        />
      ),
    },
    {
      labelKey: TK.programNameDisplay,
      filter: (
        <SingleSelection
          label={tc("phSelection", { fieldName: t(TK.programNameDisplay) })}
          value={fields.programName}
          onChange={onChange("programName")}
          clearSelect={() =>
            setFields((fields) => ({ ...fields, programName: "" }))
          }
          nameFn={(name) => name}
          enumData={programObj}
        />
      ),
    },
  ];

  return (
    <Container disableGutters maxWidth={false}>
      <FilterSectionActionRow>
        <ToggleFilterButton
          isCollapse={isCollapse}
          onClick={toggleFilterCollapse}
        />
      </FilterSectionActionRow>

      <CollapsibleFilters
        isCollapse={isCollapse}
        filters={filters}
        onSearch={onSearch}
        onReset={onReset}
      />
    </Container>
  );
}

export default ViewFilter;
