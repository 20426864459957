import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import api from '../../api';

const useCustomerInfoSearch = (customerNo: string) => {
  const [merchant, setMerchant] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchMerchantByCustomerNumber = useCallback(
    _.debounce(async (customerNo: string) => {
      const data = await api.CreditPortal.getCustomerInfo(customerNo);
      if (!data) {
        setMerchant({});
        setLoading(false);
        return;
      }
      setMerchant(data);
      setLoading(false);
    }, 1000),
    []
  );

  useEffect(() => {
    if (customerNo !== "") {
      setLoading(true);
      fetchMerchantByCustomerNumber(customerNo);
    } else {
      setMerchant({});
    }
  }, [customerNo, fetchMerchantByCustomerNumber]);

  return { merchant, loading };
};

export default useCustomerInfoSearch;
