import { DataGrid } from "@mui/x-data-grid";
import { EnumApprovalStatus } from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master";
import { useTranslation } from "../../../../../../../hooks";
import {
  CustomPagination,
  NoRowsOverlay,
} from "../../../../../../../components/Layout";
import { useGenGridCol } from "../../../../../../../utils/ComponentHelper";
import { toDisplayTime } from "../../../../../../../utils/helper";
import { dataGridDefaults } from "../../../../../../../utils/constant";
import { translatePrefix } from "../../../../config";

export interface ApproveHistory {
  id: string;
  merchantId: string;
  requestRecordId: string;
  requestType: number;
  status: number;
  remark: string;
  approvalPermission: string;
  approvedBy: string;
  approvalDate: string;
  attachment: string;
  seq: number;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
}
interface ApprovalProgressDialogContentProps {
  approveHistory: ApproveHistory[];
}

const ApprovalProgressDialogContent = (
  props: ApprovalProgressDialogContentProps
) => {
  const { t } = useTranslation(translatePrefix);

  const columns = [
    useGenGridCol("approval_sequence"),
    useGenGridCol("permission"),
    useGenGridCol("operation_result"),
    useGenGridCol("operated_by"),
    useGenGridCol("operation_time"),
    useGenGridCol("remarks"),
  ];

  const createTableRows = (data: ApproveHistory[]) => {
    if (!data || data.length === 0) {
      return [];
    }
    return data.map((item) => {
      return {
        id: item.seq,
        approval_sequence: item.seq,
        permission: item.approvalPermission,
        operation_result: t(EnumApprovalStatus[item.status]),
        operated_by: item.approvedBy,
        operation_time: toDisplayTime(item.approvalDate),
        remarks: item.remark,
      };
    });
  };

  const rows = createTableRows(props.approveHistory);

  return (
    <div>
      <DataGrid
        {...dataGridDefaults}
        rows={rows}
        rowCount={props.approveHistory.length}
        columns={columns}
        page={0}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: props.approveHistory.length },
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: "approval_sequence", sort: "asc" }],
          },
        }}
      />
    </div>
  );
};

export default ApprovalProgressDialogContent;
