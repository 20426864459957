import React, { useEffect, useState } from "react";
import { GridBoxSections } from "../../../../components/Layout";
import { Box } from "../../../../components/MuiGenerals";

import { apiObj as api, PermissionKey, translateKeyObj as TK } from "../config";
import usePermission from "../../../../hooks/usePermission";
import useAlerting from "../../../../hooks/useAlerting";
import useTranslation from "../../../../hooks/useTranslation";
import { useZusDialogStore } from "../../../../zustand/store";
import { EnumCustomerDocumentType, EnumIdNumberType } from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master";
import { OpIconButton } from "../../../../components";
import { useGetMerchant } from "../../../../hooks";
import { importantStyle } from "../../../../utils/helper";
import { OpDownload, OpHidden, OpShow } from "../../../../assets/icons";
import { documentTypeMapping, idTypeMapping } from "../../../../utils/constant";

interface IDetailTabContentProp { }

interface Idocument {
    id: string;
    merchantId: string;
    merchantCustomerRef: string;
    documentType: number;
    fileS3Key: string | null;
    fileId: string;
    fileLocalPath: string | null;
    remarks: string;
    status: number;
    createdBy: string;
    createdDate: string;
}

interface IapiRes {
    merchantId: string;
    idNumber: string;
    idIssuanceDate: string;
    idExpiryDate: string;
    customer_account_info_document: Idocument[];

    residentialAddressLine1: string;
    residentialAddressLine2: string;
    residentialAddressLine3: string;
    residentialPostalCode: string;
    residentialCity: string;
    residentialCountry: string;

    deliveryAddressLine1: string;
    deliveryAddressLine2: string;
    deliveryAddressLine3: string;
    deliveryPostalCode: string;
    deliveryCity: string;
    deliveryCountry: string;
}

const initData: IapiRes = {
    merchantId: "",
    idNumber: "",
    idIssuanceDate: "",
    idExpiryDate: "",
    customer_account_info_document: [],

    residentialAddressLine1: "",
    residentialAddressLine2: "",
    residentialAddressLine3: "",
    residentialPostalCode: "",
    residentialCity: "",
    residentialCountry: "",

    deliveryAddressLine1: "",
    deliveryAddressLine2: "",
    deliveryAddressLine3: "",
    deliveryPostalCode: "",
    deliveryCity: "",
    deliveryCountry: "",
};

const DetailTabContent: React.FC<IDetailTabContentProp> = (props) => {
    const zusDialog = useZusDialogStore();
    const {
        customerNumber,
        firstName,
        lastName,
        email,
        dateOfBirth,
        idIssuedBy,
        idType,
        phoneCountryCode: rawPhoneCountryCode,
        phoneNumber,
        idNumberHash,
    } = zusDialog.meta;
    const [data, setData] = useState<IapiRes>(initData);

    const [isReady, setIsReady] = useState(false);

    const { hasPermission } = usePermission();

    const { alerting } = useAlerting();

    const { tc, t, tcc } = useTranslation("enumConstants");

    useEffect(() => {
        if (!zusDialog.match("operationDialog")) {
            return;
        }

        const fetch = async () => {
            // May need to also check the current tab value
            if (!customerNumber) {
                return;
            }

            const res = (await api.detail({ customerNumber })) as
                | IapiRes
                | undefined
                | null;
            if (!res) {
                if (res === null) {
                    alerting("error", tc("no_data_found"));
                }
                zusDialog.setMeta((meta: any) => ({ ...meta, customerNumber: "" }));
                return;
            }

            const transformedResponse = Object.fromEntries(
                Object.entries(res).map(([key, value]) => [
                    key,
                    value === "***N/A***" ? "" : value,
                ])
            ) as IapiRes;

            setData(transformedResponse);
            setIsReady(true);
        };
        fetch();
    }, [customerNumber]);

    const merchantObj = useGetMerchant();

    const initIdNumberObject = {
        value: "",
        isHidden: true,
    };
    const [idNumberObject, setIdNumberObject] = useState(initIdNumberObject);

    const downloadBtnFn = (fileId: string, fileName: string) => async () => {
        zusDialog.openExtra("loadingDialog");
        const res: Blob = await api.document({ fileId }, { responseType: "blob" });
        zusDialog.closeExtra();

        if (!res) {
            return;
        }

        // const blobAgain = new Blob([res]);

        // const blobObjUrl = URL.createObjectURL(blobAgain);

        // window.open(blobObjUrl);

        const element = document.createElement("a");

        const objUrl = URL.createObjectURL(res);

        document.body.appendChild(element); // Required for this to work in FireFox

        element.href = objUrl;

        element.download = fileName;

        element.click();
        return res;
    };

    const showIdNumber = (customerNumber: string) => async () => {
        if (idNumberObject.value) {
            setIdNumberObject((prev) => ({ ...prev, isHidden: !prev.isHidden }));
            return;
        }
        const res = await api.idNumber({ customerNumber });
        if (!res) {
            return;
        }
        setIdNumberObject((prev) => ({
            ...prev,
            isHidden: false,
            value: res.idNumber,
        }));
    };

    const dateTransform = (rawDate: unknown) => {
        if (typeof rawDate !== "string") {
            return "";
        }

        if (rawDate.split("-").length === 3) {
            return rawDate;
        }

        if (rawDate.length !== 8) {
            return "";
        }
        const year = rawDate.slice(0, 4);
        const month = rawDate.slice(4, 6);
        const day = rawDate.slice(-2);

        return year + "-" + month + "-" + day;
    };

    const phoneCountryCode = rawPhoneCountryCode || "";
    const phoneNumberDisplay = phoneNumber
        ? phoneCountryCode + " " + phoneNumber
        : "";

    const hasViewIdNumberPermssion = hasPermission(
        PermissionKey.Details.ViewIdNumber
    );
    const hasDownloadDocumentPermssion = hasPermission(
        PermissionKey.Details.DownloadDocument
    );

    const detailSection: Array<[string, JSX.Element]> = [
        [TK.merchantName, <Box>{merchantObj[data.merchantId]}</Box>],
        [TK.customerNo, <Box>{customerNumber}</Box>],
        [TK.firstName, <Box>{firstName}</Box>],
        [TK.lastName, <Box>{lastName}</Box>],
        [TK.emailAddress, <Box>{email}</Box>],
        [TK.phoneNo, <Box>{phoneNumberDisplay}</Box>],
        // [TK.dateOfBirth, <Box>{dateOfBirth}</Box>],
        [TK.identificationDocumentType, <Box>{t(EnumIdNumberType[idType])}</Box>],
        [TK.placeOfIssue, <Box>{tcc(idIssuedBy)}</Box>],
        [
            TK.identificationNo,
            <>
                {idNumberHash && (
                    <>
                        <Box sx={{ width: importantStyle("max-content") }}>
                            {idNumberObject.isHidden ? "********" : idNumberObject.value}
                        </Box>

                        {hasViewIdNumberPermssion && (
                            <OpIconButton
                                sxBox={{
                                    width: importantStyle("1.4rem"),
                                    marginLeft: importantStyle("12px"),
                                }}
                                title={""}
                                svgUrl={idNumberObject.isHidden ? OpHidden : OpShow}
                                onClick={showIdNumber(customerNumber)}
                            />
                        )}
                    </>
                )}
            </>,
        ],
        [
            TK.issuanceDateOfIdentificationDocument,
            <Box>{dateTransform(data.idIssuanceDate)}</Box>,
        ],
        [
            TK.expiryDateOfIdentificationDocument,
            <Box>{dateTransform(data.idExpiryDate)}</Box>,
        ],
        [
            TK.uploadedDocument,
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    ">div:nth-last-of-type(n + 2)": {
                        marginBottom: importantStyle("4px"),
                    },
                }}
            >
                {data.customer_account_info_document.map(
                    ({ fileId, documentType, fileS3Key, fileLocalPath }, i) => {
                        const filePath = fileS3Key || fileLocalPath;
                        const filePathArr = filePath?.split(".");
                        const fileExtension =
                            filePathArr && filePathArr[filePathArr.length - 1];
                        const fileName =
                            documentTypeMapping[EnumCustomerDocumentType[documentType]] +
                            "_" +
                            idTypeMapping[EnumIdNumberType[idType]] +
                            "_" +
                            customerNumber +
                            (fileExtension ? "." + fileExtension : "");
                        return (
                            <Box
                                key={i}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%",
                                }}
                            >
                                <Box>{fileName}</Box>
                                <Box>
                                    {hasDownloadDocumentPermssion && (
                                        <OpIconButton
                                            sxBox={{
                                                width: importantStyle("1.2rem"),
                                                marginLeft: importantStyle("12px"),
                                            }}
                                            title={""}
                                            svgUrl={OpDownload}
                                            onClick={downloadBtnFn(fileId, fileName)}
                                        />
                                    )}
                                </Box>
                            </Box>
                        );
                    }
                )}
            </Box>,
        ],
    ];

    const residentAddressSection: Array<[string, JSX.Element]> = [
        [TK.addressLine1, <Box>{data.residentialAddressLine1}</Box>],
        [TK.addressLine2, <Box>{data.residentialAddressLine2}</Box>],
        [TK.addressLine3, <Box>{data.residentialAddressLine3}</Box>],
        [TK.postalCode, <Box>{data.residentialPostalCode}</Box>],
        [TK.city, <Box>{data.residentialCity}</Box>],
        [TK.country, <Box>{tcc(data.residentialCountry)}</Box>],
    ];
    const deliveryAddressSection: Array<[string, JSX.Element]> = [
        [TK.addressLine1, <Box>{data.deliveryAddressLine1}</Box>],
        [TK.addressLine2, <Box>{data.deliveryAddressLine2}</Box>],
        [TK.addressLine3, <Box>{data.deliveryAddressLine3}</Box>],
        [TK.postalCode, <Box>{data.deliveryPostalCode}</Box>],
        [TK.city, <Box>{data.deliveryCity}</Box>],
        [TK.country, <Box>{tcc(data.deliveryCountry)}</Box>],
    ];
    const sectionHeaderList = [
        TK.details,
        TK.residentAddress,
        TK.deliveryAddress,
    ];

    const labelElePairArrList: [string, JSX.Element][][] = [
        detailSection,
        residentAddressSection,
        deliveryAddressSection,
    ];
    return isReady ? <GridBoxSections
        sectionHeaderList={sectionHeaderList}
        labelElePairArrList={labelElePairArrList}
    /> : <></>;
}

export default DetailTabContent;
