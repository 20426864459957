import { isNil } from "lodash";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useQuery } from "react-query";

import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
} from "@mui/x-data-grid";
import {
  EnumBalanceAdjustmentStatus,
  EnumBalanceAdjustmentType,
  EnumBalanceAdjustRequestType,
} from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master";
import { EnumAllE6TransactionSubType } from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Statement";

import { LoadingDialog } from "../../../components";
import { ButtonMenu, ExportBtn } from "../../../components/Button";
import {
  CustomPagination,
  NoRowsOverlay,
  TableTab,
} from "../../../components/Layout";
import { Container } from "../../../components/MuiGenerals";
import ApprovalProgressDialog from "../../../features/Approval/ApprovalProgress/ApprovalProgressDialog";
import ApprovalProgressTableCell from "../../../features/Approval/ApprovalProgress/ApprovalProgressTableCell";
import ApproveRejectDialog from "../../../features/Approval/ApproveReject/ApproveRejectDialog";
import getApproveProgress from "../../../features/Approval/helpers/getApproveProgress";
import IApproveRejectDataProps from "../../../features/Approval/types/IApproveRejectDataProps";
import {
  useAlerting,
  useGetMerchant,
  useListMappingTransform,
  usePermission,
  useTranslation,
} from "../../../hooks";
import useCurrencyList from "../../../hooks/useCurrencyList";
import { useAppSelector } from "../../../reducer/hooks";
import { selectProfile } from "../../../reducer/profileSlice";
import { useGenGridCol } from "../../../utils/ComponentHelper";
import { dataGridDefaults } from "../../../utils/constant";
import {
  displayAmountCurrying,
  downloadFiles,
  getDecimal,
  getFullApiResponse,
  toDisplayTime,
} from "../../../utils/helper";
import { customSx } from "../../../utils/styling";
import { useZusDialogStore } from "../../../zustand/store";
import OperationDialog from "./components/OperationDialog";
import {
  apiObj as api,
  omitKeyObj,
  PermissionKey,
  TableApiResType,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from "./config";

function ViewTable() {
  const { alerting } = useAlerting();
  const { tc } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const [count, setCount] = useState(0);
  const { hasPermission } = usePermission();
  const listMapping = useListMapping("export");

  const onExport = async () => {
    if (count === 0) {
      return alerting("error", tc("no_data_export"));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      api.export({ ...zusParams.body, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiFn, count, true);
    if (rawRes.length === 0) return;
    const omitKeys = omitKeyObj.export;

    const res = listMapping(rawRes, omitKeys);
    const config = {};
    downloadFiles(`Balance Adjustment Request`, res, config);
  };
  return (
    <Container
      style={customSx.datagridContainer}
      maxWidth={false}
      disableGutters
    >
      <ExportBtn
        isShown={hasPermission(PermissionKey.Export)}
        onExport={onExport}
      />
      <TableTab>
        <TableList setCount={setCount} />
      </TableTab>
    </Container>
  );
}

function TableList(props: { setCount: Dispatch<SetStateAction<number>> }) {
  const { setCount } = props;

  const zusParams = useZusParams();
  const zusDialog = useZusDialogStore();
  const [isLoading, setIsLoading] = useState(false);

  const { hasPermissionMultipleEither, hasPermission } = usePermission();

  const { t, tc } = useTranslation(translatePrefix);
  const profile = useAppSelector(selectProfile);
  const listMapping = useListMapping("table");

  const listRes = useQuery({
    queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],
    queryFn: () => api.table(zusParams.body),
  });

  const { rows = [], count = 0 } =
    (listRes.data as any as { rows: any[]; count: number }) || {};

  const omitKeys = omitKeyObj.table;

  const content: GridRowsProp = listMapping(rows, omitKeys);

  useEffect(() => setCount(count), [listRes]);

  const renderOperationCell = (params: GridRenderCellParams<any, any, any>) => {
    const merchantName = params.row[TK.merchantName];
    const merchantId = params.row.rawData?.merchantId;
    const orderId = params.row[TK.orderId];
    const customerNumber = params.row[TK.customerNumber];
    const adjustmentType = params.row[TK.adjustmentType];
    const currency = params.row[TK.currency];
    const amount = params.row[TK.amount];
    const status = params.row.rawData?.status;
    const requestId = params.row.rawData?.id;
    const createdBy = params.row.rawData?.createdBy;
    const approvalsCount = params.row.rawData?.approvalsCount;
    const approvalsRequired = params.row.rawData?.approvalsRequired;

    const isStucking = status === EnumBalanceAdjustmentStatus.Stucking;
    const isPending = status === EnumBalanceAdjustmentStatus.Pending;

    const handleRetryBtnClick = () => {
      zusDialog.open("operationDialog", {
        requestId,
        merchantName,
        orderId,
        customerNumber,
        adjustmentType,
        currency,
        amount,
        mode: "retry",
      });
    };

    const handleApproveRejectBtnClick = async () => {
      setIsLoading(true);
      const res = await api.approvalDetail({
        id: requestId,
        merchantId,
      });
      setIsLoading(false);
      if (!res) return;

      zusDialog.open("approveRejectDialog", {
        createdBy,
        operator: profile.email,
        numberOfApproved: approvalsCount,
        numberOfApprover: approvalsRequired,
        approveHistory: res || [],
        rows: [
          merchantName,
          orderId,
          customerNumber,
          adjustmentType,
          currency,
          amount,
        ],
      });
    };

    return (
      <ButtonMenu
        title={t(TK.operation)}
        options={[
          {
            name: t(TK.retry),
            onClickFn: handleRetryBtnClick,
            isDisabled: !isStucking,
            noShow: !hasPermission(PermissionKey.Retry),
          },
          {
            name: t(TK.approveReject),
            onClickFn: handleApproveRejectBtnClick,
            isDisabled: !isPending || !(approvalsRequired > 0),
            noShow: !hasPermissionMultipleEither([
              PermissionKey.Approve.prefix,
              PermissionKey.Reject,
            ]),
          },
        ]}
      />
    );
  };
  const onApprovalProgressButtonClick = async (
    params: GridRenderCellParams<any, any, any>
  ) => {
    setIsLoading(true);
    const res = await api.approvalDetail({
      id: params.row.rawData.id,
      merchantId: params.row.rawData.merchantId,
    });
    setIsLoading(false);
    if (!res) return;
    zusDialog.open("approveProgress", {
      approveHistory: res,
    });
  };

  const columns: GridColDef[] = [
    useGenGridCol(TK.operation, {
      renderCell: renderOperationCell,
      minWidth: 112,
    }),
    useGenGridCol(TK.merchantName, {
      minWidth: 150,
    }),
    useGenGridCol(TK.orderId, {
      minWidth: 150,
    }),
    useGenGridCol(TK.approvalProgress, {
      minWidth: 120,
      renderCell: (params) => {
        return (
          <ApprovalProgressTableCell
            params={params}
            onButtonClick={() => onApprovalProgressButtonClick(params)}
          />
        );
      },
    }),
    useGenGridCol(TK.requestType),
    useGenGridCol(TK.customerNumber, {
      minWidth: 150,
    }),
    useGenGridCol(TK.adjustmentType, { minWidth: 150 }),
    useGenGridCol(TK.subType, { minWidth: 150 }),
    useGenGridCol(
      TK.currency,

      { minWidth: 100 }
    ),
    useGenGridCol(TK.amount),
    useGenGridCol(TK.status),
    useGenGridCol(TK.memo),
    useGenGridCol(TK.createdBy, {
      minWidth: 150,
    }),
    useGenGridCol(TK.creationTime, { minWidth: 150 }),
    useGenGridCol(TK.lastModifiedBy, {
      minWidth: 150,
    }),
    useGenGridCol(TK.lastModifiedTime, {
      minWidth: 150,
    }),
  ];

  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;
  const refreshTable = () => {
    zusParams.refetch();
  };
  return (
    <>
      <OperationDialog />
      <ApproveRejectDialog
        title={t(TK.approveRejectDialogTitle)}
        isOpen={zusDialog.match("approveRejectDialog")}
        closeDialog={() => {
          zusDialog.close();
        }}
        refreshTable={refreshTable}
        PermissionKey={PermissionKey}
        approveFn={api.approveBalanceAdjustment}
        rejectFn={api.rejectBalanceAdjustment}
        rowHeaders={[
          t(TK.merchantName),
          t(TK.orderId),
          t(TK.customerNumber),
          t(TK.adjustmentType),
          t(TK.currency),
          t(TK.amount),
        ]}
        data={zusDialog.meta as IApproveRejectDataProps}
      />
      <ApprovalProgressDialog
        title={t(TK.approveRejectDialogTitle)}
        isOpen={zusDialog.match("approveProgress")}
        closeDialog={() => {
          zusDialog.close();
        }}
        approveHistory={zusDialog.meta.approveHistory || []}
        isLoading={isLoading}
      />
      <DataGrid
        {...dataGridDefaults}
        initialState={{
          columns: {
            columnVisibilityModel: {
              [TK.operation]: hasPermissionMultipleEither([
                PermissionKey.Retry,
                // PermissionKey.ConfirmFailure, // TODO: use when confirm failure api ready
                PermissionKey.Approve.prefix,
                PermissionKey.Reject,
              ]),
            },
          },
        }}
        rows={content}
        rowCount={count}
        columns={columns}
        page={zusParams.body.page}
        onPageChange={zusParams.setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: count },
        }}
      />
    </>
  );
}

const useListMapping = (mode: "export" | "table") => {
  const listMappingTransform = useListMappingTransform(mode);
  const merchantObj = useGetMerchant();
  const { currencyList } = useCurrencyList();
  const { t } = useTranslation("enumConstants");

  const listMapping = (array: any[], omitKeys: string[] = []) => {
    const res = array.map((item: TableApiResType) => {
      const decimal = getDecimal(item.currency, currencyList);
      const toDisplayAmount = displayAmountCurrying(decimal);
      const approveProgress = getApproveProgress(
        item.approvalsRequired,
        item.approvalsCount
      );

      const displaySubType = () => {
        if (!item.transactionSubType) {
          return "";
        }

        return;
      };

      const subTypeDisplayValue = !isNil(item.transactionSubType)
        ? EnumAllE6TransactionSubType[item.transactionSubType].split("_")[1]
        : "";

      const displayRequestType = t(
        EnumBalanceAdjustRequestType[item.requestType]
      );

      const mappedResult = [
        ["rawData", item],
        [TK.merchantName, merchantObj[item.merchantId]],
        [TK.orderId, item.orderId],
        [TK.approvalProgress, approveProgress],
        [TK.requestType, displayRequestType],
        [TK.customerNumber, item.customerNumber],
        [TK.adjustmentType, t(EnumBalanceAdjustmentType[item.adjustType])],
        [TK.subType, t(subTypeDisplayValue)],
        [TK.currency, item.currency],
        [TK.amount, toDisplayAmount(item.amount)],
        [TK.status, t(EnumBalanceAdjustmentStatus[item.status])],
        [TK.memo, item.memo],
        [TK.createdBy, item.createdBy],
        [TK.creationTime, toDisplayTime(item.createdDate)],
        [TK.lastModifiedBy, item.modifiedBy],
        [TK.lastModifiedTime, toDisplayTime(item.lastModifiedDate)],
      ].filter(([key]) => !omitKeys.includes(key as string));
      return mappedResult;
    });

    const output = res.map(listMappingTransform);
    return output;
  };
  return listMapping;
};

export default ViewTable;
