import { IAmountData } from "../../../types/ICustomerGraphData";
import { getLineChartXAxisDate } from "../../../utils/getXAxisDate";

export const getAmountGraphData = (
  transformedAmountData: Record<string, Record<string, number>>,
  topCustomers: string[]
) => {
  const amountGraphData = Object.keys(transformedAmountData).map(
    (settlementDate) => {
      const data = transformedAmountData[settlementDate];
      const notShownCustomers = topCustomers.filter((customer) => {
        return !Object.keys(transformedAmountData[settlementDate]).includes(
          customer
        );
      });

      return {
        name: settlementDate,
        ...data,
        ...notShownCustomers.reduce((acc: any, category: any) => {
          acc[category] = 0;
          return acc;
        }, {}),
        xAxisName: getLineChartXAxisDate(
          settlementDate,
          Object.keys(transformedAmountData).length > 14
        ),
      };
    }
  );

  // sort by name
  amountGraphData.sort((a, b) => {
    return new Date(a.name).getTime() - new Date(b.name).getTime();
  });

  return amountGraphData;
};
