import { IAmountData } from "../../../types/ICustomerGraphData";

export const getFilteredAmountData = (
  amountData: IAmountData[] | undefined,
  topCustomers: string[]
) => {
  const filteredAmountData = amountData?.filter((item: IAmountData) =>
    topCustomers.includes(item.customerNumber)
  );

  return filteredAmountData;
};
