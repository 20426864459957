import { FeatureCreditManagement } from "@wallet-manager/pfh-node-def-types/dist/src/FeatureCode";

import api from "../../../api";
import { Ipage } from "../../../api/types";
import { Iprefix } from "../../../hooks/useTranslation";
import { initZusParams } from "../../../utils/config";
import { createZusInstance } from "../../../zustand/store";

const translatePrefix: Iprefix = "creditAdjustmentRequest";
const PermissionKey = FeatureCreditManagement.CreditAdjustmentRequest;

interface Ifield {
  orderId: string;
  adjustType: string;
  merchantId: string;
  customerNo: string;
  currencies: string[];
  statuses: string[];
}

const initFields: Ifield = {
  orderId: "",
  adjustType: "",
  merchantId: "",
  customerNo: "",
  currencies: [],
  statuses: [],
};

interface IsearchParam {
  orderId: string;
  adjustType: string;
  merchantId: string;
  customerNumbers: string[];
  currencies: string[];
  statuses: string[];
  createdDateFrom: string;
  createdDateTo: string;
  lastModifiedDateFrom: string;
  lastModifiedDateTo: string;
}

const useZusParams = createZusInstance<Ipage & Partial<IsearchParam>>(
  initZusParams
);

const apiObj: {
  export: (params: any, config?: any) => any;
  table: (params: any, config?: any) => any;
} = Object.freeze({
  export: api.CreditManagement.creditAdjustmentRequest.getExport,
  table: api.CreditManagement.creditAdjustmentRequest.getAll,
});

const translateKeyObj = Object.freeze({
  merchantName: "merchant_name",
  orderId: "order_id",
  repaymentMode: "repayment_mode",
  adjustmentType: "adjustment_type",
  customerNo: "customer_no",
  currency: "currency",
  realTimeCredit: "real_time_credit",
  realTimeBalance: "real_time_balance",
  adjustmentAmount: "adjustment_amount",
  status: "status",
  createdBy: "created_by",
  creationTime: "creation_time",
  lastModifiedTime: "last_modified_time",
  operations: "operations",
  retry: "retry",
  rebroadcastCallbackPmpMessage: "rebroadcast_callback_pmp_message",
});

const omitKeyObj = Object.freeze({
  export: ["rawData"],
  table: [],
});

export {
  translatePrefix,
  PermissionKey,
  type Ifield,
  type IsearchParam,
  initFields,
  initZusParams,
  useZusParams,
  apiObj,
  translateKeyObj,
  omitKeyObj,
};
