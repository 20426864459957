import { ValidationConfig, ValidationResult } from "../types";

export const getAllErrors = (
  result: ValidationResult,
  config: ValidationConfig[]
) => {
  const configKeys = config.map((item) => item.key);
  const allErrors: string[] = [];

  for (let i = 0; i < configKeys.length; i++) {
    const resultItem = result[configKeys[i]];

    if (!resultItem) {
      continue;
    }

    const { isValid, errorMessage } = resultItem;

    if (!isValid) {
      for (let j = 0; j < errorMessage.length; j++) {
        allErrors.push(errorMessage[j]);
      }
    }
  }

  return allErrors;
};
