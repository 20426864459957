import addHours from "date-fns/addHours";
import dateFormat from "date-fns/format";
import { useEffect, useMemo, useRef, useState } from "react";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CalendarPickerView } from "@mui/x-date-pickers/CalendarPicker";
import { CalendarOrClockPickerView } from "@mui/x-date-pickers/internals/models";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";

import { useTranslation } from "../hooks";
import { store } from "../reducer/store";
import { dateInFormat, dateTimeInFormat } from "../utils/config";
import { searchEndOfDay, searchEndOfSecond, toDBTime } from "../utils/helper";
import { Box } from "./MuiGenerals";
import { MpTextField } from "./TextField";

type PropsFace = {
  label?: string;
  timeValue: Date | null;
  minEndTime?: any;
  maxStartTime?: any;
  disableFuture?: boolean;
  setTimeValue: React.Dispatch<React.SetStateAction<Date | null>>;
  sx?: any;
  inputFormat?: string;
} & (
  | { type: "date"; views?: CalendarPickerView[] }
  | { type: "dateTime"; views?: CalendarOrClockPickerView[] }
);
export default function TimePicker(props: PropsFace) {
  const {
    type,
    label,
    setTimeValue,
    timeValue = null,
    minEndTime,
    maxStartTime,
    views,
    disableFuture = false,
    sx,
    inputFormat,
  } = props;
  const [timeShow, setTimeShow] = useState<Date | null | undefined>(timeValue);
  const [isClockView, setIsClockView] = useState(false);
  useEffect(() => {
    setTimeShow(timeValue);
  }, [timeValue]);
  const hasViews = views && views.length > 0;
  const dateViews =
    hasViews && type === "date" ? views : (["day"] as CalendarPickerView[]);
  const dateTimeViews =
    hasViews && type === "dateTime"
      ? views
      : (["day", "hours", "minutes", "seconds"] as CalendarOrClockPickerView[]);
  const handleChange = (newValue: Date | null) => {
    if (type === "dateTime") {
      const isAM = (newValue?.getHours() || 0) < 12;
      const amBtn = document.querySelector(".MuiClock-amButton");
      const amBtnText = amBtn?.querySelector(".MuiTypography-caption");
      const pmBtn = document.querySelector(".MuiClock-pmButton");
      const pmBtnText = pmBtn?.querySelector(".MuiTypography-caption");

      if (isAM) {
        if (pmBtnText?.classList.contains("Mui-selected")) {
          pmBtnText?.classList.remove("Mui-selected");
        }
        if (!amBtnText?.classList.contains("Mui-selected")) {
          amBtnText?.classList.add("Mui-selected");
        }
      } else {
        if (amBtnText?.classList.contains("Mui-selected")) {
          amBtnText?.classList.remove("Mui-selected");
        }
        if (!pmBtnText?.classList.contains("Mui-selected")) {
          pmBtnText?.classList.add("Mui-selected");
        }
      }
    }

    setTimeShow(newValue);
  };

  const onAccept = () => {
    if (!timeShow) return;
    setTimeValue(timeShow);
  };

  useEffect(() => {
    const amBtn = document.querySelector(".MuiClock-amButton");
    const amBtnText = amBtn?.querySelector(".MuiTypography-caption");
    amBtnText?.classList.add("Mui-selected");
  }, [isClockView]);

  const selectionRef = useRef<HTMLInputElement>(null);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {type === "date" && (
        <MobileDatePicker
          inputRef={selectionRef}
          onClose={() => {
            const focusedDivElement = selectionRef.current;
            setTimeout(() => focusedDivElement?.blur(), 200);
          }}
          views={dateViews}
          disableFuture={disableFuture}
          label={label}
          inputFormat={inputFormat || dateInFormat}
          value={timeShow}
          onAccept={onAccept}
          onChange={handleChange}
          minDate={minEndTime}
          maxDate={maxStartTime}
          renderInput={(params) => <MpTextField sx={sx} {...params} />}
        />
      )}
      {type === "dateTime" && (
        <MobileDateTimePicker
          inputRef={selectionRef}
          onClose={() => {
            const focusedDivElement = selectionRef.current;
            setTimeout(() => focusedDivElement?.blur(), 200);
          }}
          onViewChange={(view) =>
            view === "hours" || view === "minutes" || view === "seconds"
              ? setIsClockView(true)
              : setIsClockView(false)
          }
          views={dateTimeViews}
          disableFuture={disableFuture}
          label={label}
          inputFormat={inputFormat || dateTimeInFormat}
          value={timeShow}
          onAccept={onAccept}
          onChange={handleChange}
          minDateTime={minEndTime}
          maxDateTime={maxStartTime}
          renderInput={(params) => <MpTextField sx={sx} {...params} />}
        />
      )}
    </LocalizationProvider>
  );
}

export type maybeDate = Date | null;
interface useDatePickerFace {
  start: {
    defaultDate: maybeDate;
    label?: String;
  };
  end: {
    defaultDate: maybeDate;
    label?: String;
  };
  isTimezoneConvert: boolean;
  isEndOfSecond: boolean;
  inputFormat?: string;
}
//to put default date
//useDatePicker({ start: { defaultDate: new Date() } })
export function useDatePicker(inputConfig?: Partial<useDatePickerFace>) {
  const defaultConfig = {
    start: {
      defaultDate: null,
      label: "Time Start",
    },
    end: {
      defaultDate: null,
      label: "Time End",
    },
    isTimezoneConvert: true,
    isEndOfSecond: true,
  };
  const config = { ...defaultConfig, ...inputConfig };
  const { t } = useTranslation("common");
  const [dateStart, setDateStart] = useState<maybeDate>(
    config.start.defaultDate
  );
  const [dateEnd, setDateEnd] = useState<maybeDate>(config.end.defaultDate);
  const clearDate = () => {
    setDateStart(null);
    setDateEnd(null);
  };
  const resetDate = () => {
    setDateStart(config.start.defaultDate);
    setDateEnd(config.end.defaultDate);
  };
  const hyphenStyle = {
    verticalAlign: "bottom",
    fontSize: "2rem",
  };

  const DateRangePicker = useMemo(
    () => (p: any) => {
      const handleDateRangeStartPickerChange = (value: any) => {
        setDateStart(value);
        p.onChange && p.onChange(value);
      };

      const handleDateRangeEndPickerChange = (value: any) => {
        setDateEnd(value);
        p.onChange && p.onChange(value);
      };

      return (
        <div className="datePickerObj">
          <TimePicker
            type={p.type}
            label={p.startLabel ?? t("start_date")}
            timeValue={dateStart}
            setTimeValue={handleDateRangeStartPickerChange}
            maxStartTime={dateEnd}
            views={p.views}
            inputFormat={config.inputFormat}
          />
          <span style={hyphenStyle}> - </span>
          <TimePicker
            type={p.type}
            label={p.endLabel ?? t("end_date")}
            timeValue={dateEnd}
            setTimeValue={handleDateRangeEndPickerChange}
            minEndTime={dateStart}
            views={p.views}
            inputFormat={config.inputFormat}
          />
        </div>
      );
    },
    [dateStart, dateEnd]
  );

  const SingleDatePicker = useMemo(
    () => (p: any) => {
      return (
        <div className="datePickerObj">
          <Box
            sx={{
              ".MuiFormControl-root": {
                width: "100% !important",
              },
            }}
          >
            {dateStart ? (
              <TimePicker
                type={p.type}
                label={p.startLabel ?? t("start_date")}
                timeValue={dateStart}
                setTimeValue={setDateStart}
                maxStartTime={dateEnd}
              />
            ) : (
              <TimePicker
                type={p.type}
                label={p.startLabel ?? t("start_date")}
                timeValue={dateEnd}
                setTimeValue={setDateEnd}
                maxStartTime={dateEnd}
              />
            )}
          </Box>
        </div>
      );
    },
    [dateStart, dateEnd]
  );

  const localUTCdiff = Number(store.getState().profile.timezone) * -1 || 0;
  const usedUTCDiff = config.isTimezoneConvert ? localUTCdiff : 0;
  const searchEndOfTime = config.isEndOfSecond
    ? searchEndOfSecond
    : searchEndOfDay;

  const startTime = toDBTime(dateStart && addHours(dateStart, usedUTCDiff));
  const endTime = toDBTime(
    dateEnd && addHours(searchEndOfTime(dateEnd) || 0, usedUTCDiff)
  );

  const output = {
    start: startTime
      ? config.inputFormat
        ? dateFormat(new Date(startTime), config.inputFormat)
        : startTime.slice(0, -4) + "000Z"
      : "",
    end: endTime
      ? config.inputFormat
        ? dateFormat(new Date(endTime), config.inputFormat)
        : endTime.slice(0, -4) + "999Z"
      : "",
  };

  return {
    Picker: DateRangePicker,
    SingleDatePicker: SingleDatePicker,
    setDateStart,
    setDateEnd,
    start: output.start,
    end: output.end,
    clearDate,
    resetDate,
  };
}
