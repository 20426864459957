import React from 'react';
import { Box } from '../../../../../../components/MuiGenerals';

interface IFinancialTabBlockProps {
    title: string;
    children: JSX.Element;
}

const FinancialTabBlock: React.FC<IFinancialTabBlockProps> = ({ title, children }) => {
    return (
        <Box sx={{
            margin: '1rem',
        }}>
            <Box sx={{
                fontSize: '1.5rem',
                fontWeight: 'bold',
            }}>
                {title}
            </Box>
            <Box sx={{
                borderBottom: 'solid 1px #E1E4EA',
                marginBottom: '1rem',
                marginTop: '1rem',
            }}></Box>
            {children}
        </Box>
    );
};

export default FinancialTabBlock;
