import Papa from 'papaparse';
import { useEffect, useState } from 'react';

// const mimeType = /csv/i;

function useFileUpload() {
  const [file, setFile] = useState(null);
  const [fileDataURL, setFileDataURL] = useState<any>();
  const [fileContent, setFileContent] = useState<any>();
  const onFileChange = (e: any) => {
    const theFile = e.target.files[0];
    // if (!theFile.type.match(mimeType)) {
    //   alert("Image mime type is not valid");
    //   return;
    // }
    setFile(theFile);
  };

  useEffect(() => {
    let fileReader: FileReader,
      isCancel = false;
    if (!file) return;
    fileReader = new FileReader();
    fileReader.onload = (e) => {
      if (!e.target) return;

      const fileContent = e.target.result;
      setFileContent(fileContent);
      const { result } = e.target;
      if (result && !isCancel) {
        fileReader.readAsText(file);
        setFileDataURL(result as any);
      }
    };
    Papa.parse(file, {
      complete: (results) => {
        setFileContent(results.data);
      },
    });
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [file]);

  return {
    fileContent,
    setFileContent,
    file,
    setFile,
    fileDataURL, //for image preview
    setFileDataURL,
    onFileChange,
  };
}
export default useFileUpload;
/* usage
import { useFileUpload } from "../../hooks";
const { onFileChange, fileContent } = useFileUpload();
useEffect(() => {
  if (!fileContent) return;
  const json= fileContent.map(xxx)
  setJson(json)
}, [fileContent]);

<input
  type="file"
  accept=".csv"
  onChange={onFileChange}
  ref={fileUploader}
/>
 */
