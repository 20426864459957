import { Ipage } from "../../../../api/types";
import { initZusParams } from "../../../../utils/config";
import { createZusInstance } from "../../../../zustand/store";

const translatePrefix = "byPfhNoConsumption";

interface Ifield {
    settlementDateFrom: string;
    settlementDateTo: string;
    customerNumber: string;
}

const lastSevenDays = new Date();
lastSevenDays.setDate(lastSevenDays.getDate() - 7);
const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

const initFields: Ifield = {
    settlementDateFrom: lastSevenDays.toISOString(),
    settlementDateTo: yesterday.toISOString(),
    customerNumber: "",
};

interface IsearchParam {
    settlementDateFrom: string;
    settlementDateTo: string;
    customerNumber: string;
}

const ColumnKeys = {
    pfhNo: "customerNumber",
    consumptionAmountCountHkd: "totalCount",
};

const Columns: string[] = [
    ColumnKeys.pfhNo,
    ColumnKeys.consumptionAmountCountHkd,
];

const useZusParams = createZusInstance<Ipage & Partial<IsearchParam>>(
    { ...initZusParams, ...initFields }
);

export { useZusParams, translatePrefix, ColumnKeys, Columns };
