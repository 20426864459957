import React from "react";

import DashboardPanel from "../../../../components/Dashboard/DashboardPanel";
import DashboardGrid from "../../../../components/Dashboard/DashboardGrid";
import { useTranslation } from "../../../../hooks";
import { Iprefix } from "../../../../hooks/useTranslation";
import { mapChartTypeToChartComponent } from "./helpers/mapChartTypeToChartComponent";
import { Box } from "../../../../components/MuiGenerals";

export interface IChartBoardChart {
  type: string;
  data: any;
  title: string;
  width: number;
  legendAlign?: "left" | "center" | "right";
  dataKey?: string[] | string;
  line?: any[];
  nameKey: string;
  isLoading: boolean;
  xs: number;
  md: number;
  height: number;
}

interface ChartBoardProps {
  charts: IChartBoardChart[];
  translatePrefix: Iprefix;
}

const ChartBoard: React.FC<ChartBoardProps> = ({ charts, translatePrefix }) => {
  const { t } = useTranslation(translatePrefix);

  return (
    <>
      <DashboardPanel spacing={2}>
        {charts.map((chart, index) => (
          <DashboardGrid
            key={index}
            xs={chart.xs}
            md={chart.md}
            height={chart.height}
            isNoRecord={chart?.data?.length === 0 || !chart?.data}
            isLoading={chart.isLoading}
            title={chart.title}
            content={
              <Box
                sx={{
                  marginTop: 1.5,
                }}
              >
                {mapChartTypeToChartComponent(chart)}
              </Box>
            }
          />
        ))}
      </DashboardPanel>
    </>
  );
};

export default ChartBoard;
