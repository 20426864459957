import React from "react";

import { Box } from "../../../../components/MuiGenerals";
import { customSx } from "../../../../utils/styling";
import { useTranslation } from "../../../../hooks";
import { COLOR } from "../../../../utils/config";

const FilterSectionActionRow = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { tc } = useTranslation("common");

  return (
    <Box
      sx={{
        "& .MuiButton-root": {
          // TODO:
          color: COLOR.text.secondary.hex,
          borderColor: COLOR.text.secondary.hex,
        },

        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "12px 0",
      }}
    >
      <div
        style={{
          color: COLOR.text.primary.hex,
          fontSize: "20px",
          fontWeight: "bold",
        }}
      >
        {tc("filter")}
      </div>

      <div
        style={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        {children}{" "}
      </div>
    </Box>
  );
};

export default FilterSectionActionRow;
