export function getDatesInRange(dateFrom: string, dateTo: string): string[] {
  const startDate = new Date(dateFrom);
  const endDate = new Date(dateTo);
  const dateArray: string[] = [];

  let currentDate = startDate;
  while (currentDate <= endDate) {
    dateArray.push(currentDate.toISOString().split("T")[0]);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dateArray;
}
