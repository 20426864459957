import { isNumber } from "lodash";
import getDisplayApprovalProgress from "./getDisplayApprovalProgress";
import getIsAutoApprove from "./getIsAutoApprove";

export default function getApproveProgress(approvalsRequired: number, approvalsCount: number){
    if(!isNumber(approvalsRequired) || !isNumber(approvalsCount) || approvalsRequired <= 0 || approvalsCount < 0){
        return "";
    }

    const isAutoApprove = getIsAutoApprove({
        numberOfApprover: approvalsRequired,
    });

    const approveProgress = getDisplayApprovalProgress({
        numberOfApproved: approvalsCount,
        numberOfApprover: approvalsRequired,
        isAuto: isAutoApprove,
    });

    return approveProgress;
}