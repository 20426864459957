import { useLocaleState } from "react-admin";
import { useQuery } from "react-query";

import api from "../api";
import { useAppDispatch, useAppSelector } from "../reducer/hooks";
import { selectProfile, setAccountSetting } from "../reducer/profileSlice";

export default function useAccountSetting() {
  const dispatch = useAppDispatch();
  const profile = useAppSelector(selectProfile);
  const [_, setLocaleState] = useLocaleState();
  useQuery("userProfile", api.CreditPortal.getUserProfile, {
    staleTime: 60 * 60 * 12,
    onSuccess: (dbRes) => {
      const hasDBprofile = !!dbRes;
      const localSetting = {
        timezone: profile.timezone,
        lang: profile.lang,
      };
      const { lang, timezone } = hasDBprofile ? dbRes : localSetting;
      dispatch(setAccountSetting({ lang, timezone }));
      setLocaleState(lang);
    },
  });
}
