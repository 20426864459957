import { FeatureCreditManagement } from "@wallet-manager/pfh-node-def-types/dist/src/FeatureCode";

import api from "../../../api";
import { Ipage } from "../../../api/types";
import { Iprefix } from "../../../hooks/useTranslation";
import { initZusParams } from "../../../utils/config";
import { createZusInstance } from "../../../zustand/store";

const translatePrefix: Iprefix = "balanceAdjustmentRequest";

const PermissionKey = FeatureCreditManagement.BalanceAdjustmentRequest;

interface Ifield {
  merchantId: string;
  orderId: string;
  customerNumber: string;
  adjustmentType: string;
  statuses: string[];
}

const initFields: Ifield = {
  merchantId: "",
  orderId: "",
  customerNumber: "",
  adjustmentType: "",
  statuses: [],
};

interface IsearchParam {
  merchantId: string;
  orderId: string;
  customerNumbers: string[];
  adjustType: string;
  statuses: string[];
  createdDateFrom: string;
  createdDateTo: string;
  lastModifiedDateFrom: string;
  lastModifiedDateTo: string;
}

const useZusParams = createZusInstance<Ipage & Partial<IsearchParam>>(
  initZusParams
);

const apiObj: {
  export: (params: any, config?: any) => any;
  table: (params: any, config?: any) => any;
  retry: (params: { requestId: string }, config?: any) => any;
  confirmFailure: (params: any, config?: any) => any;
  createRequest: (
    params: {
      merchantId: number;
      customerNumber: string;
      amount: string;
      currencyCode: string;
      type: number;
      transactionSubType: number;
      transactionId: string;
    },
    config?: any
  ) => any;
  currentBalanceAndProgram: (
    params: { merchantId: string; customerNumber: string },
    config?: any
  ) => any;
  approveBalanceAdjustment: (params: any, config?: any) => any;
  rejectBalanceAdjustment: (params: any, config?: any) => any;
  approvalDetail: (params: any, config?: any) => any;
} = Object.freeze({
  export: api.CreditManagement.balanceAdjustmentRequest.getExport,
  table: api.CreditManagement.balanceAdjustmentRequest.getAll,
  retry: api.CreditManagement.balanceAdjustmentRequest.postRetry,
  confirmFailure: api.CreditManagement.balanceAdjustmentRequest.getAll, // TODO: use real api
  createRequest:
    api.CreditManagement.balanceAdjustmentRequest.postCreateRequest,
  currentBalanceAndProgram:
    api.CreditManagement.balanceAdjustmentRequest.getOffering,
  approveBalanceAdjustment:
    api.CreditManagement.balanceAdjustmentRequest.approveBalanceAdjustment,
  rejectBalanceAdjustment:
    api.CreditManagement.balanceAdjustmentRequest.rejectBalanceAdjustment,
  approvalDetail:
    api.CreditManagement.balanceAdjustmentRequest.getApprovalDetail,
});

const translateKeyObj = Object.freeze({
  operation: "operation",
  retry: "retry",
  // Approve Table
  approveReject: "approve_reject",
  approvalProgress: "approval_progress",
  // Approve Reject Dialog
  approveRejectDialogTitle: "approve_reject_dialog_title",
  rejectSuccess: "reject_success",
  approveFail: "approve_fail",
  rejectFail: "reject_fail",
  requestApproved: "request_approved",
  approveSuccess: "approve_success",
  approve: "approve",
  reject: "reject",

  retryDialogTitle: "retry_dialog_title",
  confirmFailure: "confirm_failure",
  confirmFailureDialogTitle: "confirm_failure_dialog_title",
  merchantName: "merchant_name",
  orderId: "order_id",
  customerNumber: "customer_number",
  adjustmentType: "adjustment_type",
  subType: "sub_type",
  currency: "currency",
  amount: "amount",
  status: "status",
  remark: "remark",

  createdBy: "created_by",
  creationTime: "creation_time",
  lastModifiedBy: "last_modified_by",
  lastModifiedTime: "last_modified_time",
  retrySuccess: "retry_success",
  confirmFailureSuccess: "confirm_failure_success",
  currentAvailableBalance: "current_available_balance",
  balanceNotEnoughWarning: "balance_not_enough_warning",
  createdRequestSuccessfully: "created_request_successfully",
  customerNumberNotExist: "customer_number_not_exist",
  memo: "memo",
  relatedTransactionId: "related_transaction_id",
  requestType: "request_type",
});

const omitKeyObj = Object.freeze({
  export: ["rawData"],
  table: [],
});

type TableApiResType = {
  id: number;
  merchantId: number;
  orderId: string;
  customerNumber: string;
  adjustType: number;
  currency: string;
  decimals: number;
  amount: string;
  status: number;
  memo: string;
  attributes: Attribute[];
  step: number;
  realtimeCredit: string;
  realtimeBalance: string;
  processBy: string;
  errorReason: string;
  trialCount: number;
  authCode: string;
  approvalsRequired: number;
  approvalsCount: number;
  createdBy: string;
  createdDate: string;
  modifiedBy: string;
  lastModifiedDate: string;
  transactionSubType: number;
  requestType: number;
};

interface Attribute {
  name: string;
  value: string;
}

export {
  translatePrefix,
  PermissionKey,
  type Ifield,
  type IsearchParam,
  initFields,
  initZusParams,
  useZusParams,
  apiObj,
  translateKeyObj,
  omitKeyObj,
  type TableApiResType,
};
