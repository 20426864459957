import { useTranslate } from "react-admin";

import Stack from "@mui/material/Stack";

import { COLOR } from "../../utils/config";

export default function NoRowsOverlay() {
  const translate = useTranslate();
  const t = (key: string) => translate(`component.${key}`);
  return (
    <Stack
      height="100%"
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: COLOR.background.table.rgba(0.5),
        color: COLOR.text.secondary.hex,
      }}
    >
      {t("noRecords")}
    </Stack>
  );
}
