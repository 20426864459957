import ActionButtons from './ActionButtons';
import CollapseArea from './CollapseArea';
import FilterContainer from './FilterContainer';
import FilterWrapper from './FilterWrapper';

interface IProps {
  isCollapse?: boolean;
  allFilters: React.ReactNode[];
  onSearch: () => void;
  onReset: () => void;
}

const CollapseAllFilterView = (props: IProps) => {
  const { isCollapse, allFilters, onSearch, onReset } = props;

  return (
    <CollapseArea isCollapse={isCollapse}>
      <FilterWrapper>
        <FilterContainer>{allFilters}</FilterContainer>
      </FilterWrapper>

      <ActionButtons onSearch={onSearch} onReset={onReset} />
    </CollapseArea>
  );
};

export default CollapseAllFilterView;
