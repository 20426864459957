import React, { useMemo } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

import { COLOR } from "../../../utils/config";

interface IDoughnutChartProps {
    data: any[];
    dataKey: string;
    onMouseEnter?: (event: any, data: any) => void;
    width?: number;
    height?: number;
}

const DoughnutChart: React.FC<IDoughnutChartProps> = ({
    data,
    dataKey,
    onMouseEnter,
    width = 400,
    height = 400,
}) => {
    return (
        <ResponsiveContainer width={width} height={height} style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}>
            <PieChart key={Math.random()} width={width} height={height}>
                <Pie
                    dataKey={dataKey}
                    data={data}
                    cx={width / 2}
                    cy={height / 3}
                    innerRadius={width * 0.3}
                    outerRadius={width * 0.4}
                    fill="#8884d8"
                    onMouseEnter={onMouseEnter}
                    paddingAngle={5}
                >
                    {data.map((entry, index) => {
                        return (
                            <Cell
                                key={`cell-${index}`}
                                fill={
                                    COLOR.Chart.DoughnutChart.ChartColor[
                                    index % COLOR.Chart.DoughnutChart.ChartColor.length
                                    ]
                                }
                            />
                        );
                    })}
                </Pie>
            </PieChart>
        </ResponsiveContainer >
    );
};

export default DoughnutChart;
