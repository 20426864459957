import { useQuery } from "react-query";

import api from "../../api";
import { useAppDispatch, useAppSelector } from "../../reducer/hooks";
import {
  selectProgramList,
  setProgramList,
} from "../../reducer/programListSlice";

export async function useFetchProgram() {
  const dispatch = useAppDispatch();

  useQuery("programList", api.CreditPortal.getProgramList, {
    staleTime: 60 * 60 * 12,
    onSuccess: (dbRes) => {
      dispatch(setProgramList(dbRes));
    },
  });
}

export function useGetProgram(merchantId?: string) {
  const { allProgramList, propramByMerchantList } =
    useAppSelector(selectProgramList);

  return merchantId === undefined
    ? allProgramList
    : propramByMerchantList[merchantId];
}
