import {
  GeneralBtn,
  MpTextField,
  SingleSelection,
} from "../../../../../../../components";
import FlexCenterBox from "../../../../../../../components/Layout/FlexCenterBox";
import {
  useAlerting,
  useGetMerchant,
  useTranslation,
} from "../../../../../../../hooks";
import useCurrencyList from "../../../../../../../hooks/useCurrencyList";
import useValidation from "../../../../../../../hooks/useValidation";
import { ValidationConfigProps } from "../../../../../../../hooks/useValidation/types";
import { getDecimal } from "../../../../../../../utils/helper";
import { checkValueDecimalValidator } from "../../../../../../../utils/validators/checkValueDecimalValidator";
import { largerThanZeroValidator } from "../../../../../../../utils/validators/largerThanZeroValidator";
import { requiredValidator } from "../../../../../../../utils/validators/requiredValidator";
import {
  apiObj as api,
  translateKeyObj,
  translatePrefix,
} from "../../../../config";
import { IField } from "../types";
import ButtonWrapper from "./ButtonWrapper";
import Row from "./Row";
import RowTitle from "./RowTitle";

export interface DialogContentProps {
  fields: IField;
  setFields: React.Dispatch<React.SetStateAction<IField>>;
  closeDialog: () => void;
  refreshTable: () => void;
}

enum ValidationFieldKeys {
  MerchantId = "merchantId",
  Currencies = "currency",
  Amount = "amount",
}

const Content = (props: DialogContentProps) => {
  const { fields, setFields, closeDialog, refreshTable } = props;

  const merchantObj = useGetMerchant();
  const { CurrencyListEnum, currencyList } = useCurrencyList();

  const { alerting } = useAlerting();
  const { t, tc, translate } = useTranslation(translatePrefix);

  const DecimalToBeChecked = getDecimal(fields.currency, currencyList);

  const validationConfig: ValidationConfigProps = [
    {
      key: ValidationFieldKeys.MerchantId,
      value: fields.merchantId,
      validators: [
        requiredValidator(
          translate("validation.selectRequired", {
            fieldName: t("merchant_name"),
          })
        ),
      ],
    },
    {
      key: ValidationFieldKeys.Currencies,
      value: fields.currency,
      validators: [
        requiredValidator(
          translate("validation.selectRequired", {
            fieldName: t("currency"),
          })
        ),
      ],
    },
    {
      key: ValidationFieldKeys.Amount,
      value: fields.amount,
      validators: [
        requiredValidator(
          translate("validation.inputRequired", {
            fieldName: t("amount"),
          })
        ),
        largerThanZeroValidator(
          translate("validation.shouldBeLargerThanZero", {
            fieldName: t("amount"),
          })
        ),
        checkValueDecimalValidator(
          translate("validation.invalidDecimal", {
            currency: fields.currency,
            decimal: DecimalToBeChecked,
          }),
          {
            decimal: DecimalToBeChecked,
          }
        ),
      ],
    },
  ];

  const { validate } = useValidation(validationConfig);

  const handleConfirm = async () => {
    const { allErrors } = validate();

    if (allErrors.length > 0) {
      return alerting("warning", allErrors[0]);
    }

    const res = await api.createWithdrawRequest({
      merchantId: Number(fields.merchantId),
      currency: fields.currency,
      amount: fields.amount,
    });

    if (!res) return;

    alerting("success", t("create_request_success"));
    refreshTable();
    closeDialog();
  };

  const handleCancel = () => {
    closeDialog();
  };

  return (
    <FlexCenterBox isColumn>
      <Row>
        <RowTitle>{t("merchant_name")}</RowTitle>
        <SingleSelection
          label={tc("phSelection", {
            fieldName: t(translateKeyObj.merchantName),
          })}
          value={fields.merchantId}
          onChange={(e) =>
            setFields((fields) => ({ ...fields, merchantId: e.target.value }))
          }
          clearSelect={() =>
            setFields((fields) => ({ ...fields, merchantId: "" }))
          }
          nameFn={(name) => name}
          isNoSorting
          enumData={merchantObj}
        />
      </Row>
      <Row>
        <RowTitle>{t("currency")}</RowTitle>
        <SingleSelection
          label={tc("phSelection", { fieldName: t(translateKeyObj.currency) })}
          value={fields.currency}
          onChange={(e) =>
            setFields((fields) => ({ ...fields, currency: e.target.value }))
          }
          clearSelect={() =>
            setFields((fields) => ({ ...fields, currency: "" }))
          }
          nameFn={(name) => name}
          enumData={CurrencyListEnum}
        />
      </Row>
      <Row>
        <RowTitle>{t("amount")}</RowTitle>
        <MpTextField
          label={tc("phInputField", { fieldName: t(translateKeyObj.amount) })}
          value={fields.amount}
          onChange={(e) =>
            setFields((fields) => ({
              ...fields,
              amount: e.target.value,
            }))
          }
        />
      </Row>

      <FlexCenterBox
        style={{
          marginTop: "24px",
        }}
      >
        <ButtonWrapper isHideMargin>
          <GeneralBtn label="confirm" onClick={handleConfirm} />
        </ButtonWrapper>
        <ButtonWrapper>
          <GeneralBtn label="cancel" color="secondary" onClick={handleCancel} />
        </ButtonWrapper>
      </FlexCenterBox>
    </FlexCenterBox>
  );
};

export default Content;
