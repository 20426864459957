import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useQuery } from "react-query";

import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
} from "@mui/x-data-grid";
import { EnumPreCreatedCardOrderStatus } from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master";
import { FeatureCreditManagement } from "@wallet-manager/pfh-node-def-types/dist/src/FeatureCode";

import { OpRedirect } from "../../../assets/icons";
import { LoadingDialog } from "../../../components";
import { ExportBtn, OpIconButton } from "../../../components/Button";
import {
  CustomPagination,
  NoRowsOverlay,
  TableTab,
} from "../../../components/Layout";
import { Box, Container, Tooltip } from "../../../components/MuiGenerals";
import {
  useAlerting,
  useGetMerchant,
  useListMappingTransform,
  usePermission,
  useTranslation,
} from "../../../hooks";
import { useGenGridCol } from "../../../utils/ComponentHelper";
import { COLOR } from "../../../utils/config";
import { dataGridDefaults } from "../../../utils/constant";
import {
  copyToClipboard,
  downloadFiles,
  getFullApiResponse,
  toDisplayTime,
} from "../../../utils/helper";
import { customSx } from "../../../utils/styling";
import {
  apiObj as api,
  omitKeyObj,
  PermissionKey,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from "./config";

function ViewTable() {
  const { alerting } = useAlerting();
  const { tc } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const [count, setCount] = useState(0);
  const listMapping = useListMapping("export");
  const { hasPermission } = usePermission();

  const onExport = async () => {
    if (count === 0) {
      return alerting("error", tc("no_data_export"));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      api.export({ ...zusParams.body, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiFn, count, true);
    if (rawRes.length === 0) return;
    const omitKeys = omitKeyObj.export;

    const res = listMapping(rawRes, omitKeys);
    const config = {};
    downloadFiles(`Pre-Create Card Request`, res, config);
  };

  return (
    <Container
      style={customSx.datagridContainer}
      maxWidth={false}
      disableGutters
    >
      <ExportBtn
        isShown={hasPermission(PermissionKey.Export)}
        onExport={onExport}
      />

      <TableTab>
        <TableList setCount={setCount} />
      </TableTab>
    </Container>
  );
}

function TableList(props: { setCount: Dispatch<SetStateAction<number>> }) {
  const { setCount } = props;

  const zusParams = useZusParams();

  const { hasPermission } = usePermission();
  const { alerting } = useAlerting();
  const { t } = useTranslation("common");

  const listMapping = useListMapping("table");

  const listRes = useQuery({
    queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],

    queryFn: () => api.table(zusParams.body),
  });

  const { rows = [], count = 0 } =
    (listRes.data as any as { rows: any[]; count: number }) || {};

  const omitKeys = omitKeyObj.table;
  const content: GridRowsProp = listMapping(rows, omitKeys);

  useEffect(() => setCount(count), [listRes]);

  const redirectPermission = FeatureCreditManagement.CardList.prefix;

  const OrderIdCell = (params: GridRenderCellParams<any, any, any>) => {
    const orderId = params.row[TK.orderId];
    const redirectHandler = () =>
      window.open(
        `/cardList?orderId=${orderId}`,
        "_blank",
        "noopener,noreferrer"
      );

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: hasPermission(redirectPermission)
            ? "space-between"
            : "center",
          width: "100%",
        }}
      >
        <Tooltip title={orderId}>
          <Box
            sx={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              color: COLOR.text.primary.hex,
              textDecoration: "underline",
              cursor: "pointer",
              width: "80%",
            }}
            onClick={() => {
              copyToClipboard(orderId);
              alerting("success", t("copied"));
            }}
          >
            {orderId}
          </Box>
        </Tooltip>
        {hasPermission(redirectPermission) && (
          <OpIconButton
            title={""}
            size="1rem"
            svgUrl={OpRedirect}
            onClick={redirectHandler}
          />
        )}
      </Box>
    );
  };

  const columns: GridColDef[] = [
    useGenGridCol(TK.merchantName, {
      minWidth: 150,
    }),
    useGenGridCol(TK.orderId, {
      minWidth: 120,
      renderCell: OrderIdCell,
    }),
    useGenGridCol(TK.partnerName),
    useGenGridCol(TK.programName),
    useGenGridCol(TK.cardProfileName, {
      minWidth: 150,
    }),
    useGenGridCol(TK.orderProgress),
    useGenGridCol(TK.status, { minWidth: 150 }),
    useGenGridCol(TK.createdBy, { minWidth: 150 }),
    useGenGridCol(TK.creationTime, {
      minWidth: 150,
    }),
    // useGenGridCol(TK.lastModifiedBy, { minWidth: 150 }),
    useGenGridCol(TK.lastModifiedTime, {
      minWidth: 150,
    }),
    useGenGridCol(TK.remarks, {
      minWidth: 150,
    }),
  ];
  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;
  return (
    <>
      <DataGrid
        {...dataGridDefaults}
        rows={content}
        rowCount={count}
        columns={columns}
        page={zusParams.body.page}
        onPageChange={zusParams.setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: count },
        }}
      />
    </>
  );
}

const useListMapping = (mode: "export" | "table") => {
  const { t } = useTranslation("enumConstants");
  const listMappingTransform = useListMappingTransform(mode);
  const merchantObj = useGetMerchant();

  const listMapping = (array: any[], omitKeys: string[] = []) => {
    const res = array.map((item: any) => {
      const orderProgressDisplayValue = `${item.progress}/${item.count}`;

      const mappedResult = [
        [TK.merchantName, merchantObj[item.merchantId]],
        [TK.orderId, item.orderId],
        [TK.partnerName, item.partnerName],
        [TK.programName, item.programName],
        [TK.cardProfileName, item.cardProfileName],
        [TK.orderProgress, orderProgressDisplayValue],
        [TK.status, t(EnumPreCreatedCardOrderStatus[item.status])],
        [TK.createdBy, item.createdBy],
        [TK.creationTime, toDisplayTime(item.createdDate)],
        // [TK.lastModifiedBy, item.modifiedBy],
        [TK.lastModifiedTime, toDisplayTime(item.lastModifiedDate)],
        [TK.remarks, item.remark],
      ].filter(([key]) => !omitKeys.includes(key));
      return mappedResult;
    });

    const output = res.map(listMappingTransform);
    return output;
  };
  return listMapping;
};

export default ViewTable;
