import { DialogInOne, LoadingDialog } from "../../../../components";
import ApprovalProgressDialogContent from "./components/Content";

interface ApprovalProgressDialogProps {
  title: string;
  isOpen: boolean;
  closeDialog: () => void;
  isLoading?: boolean;
  approveHistory: any;
}

const ApprovalProgressDialog = (props: ApprovalProgressDialogProps) => {
  const { title, isOpen, closeDialog, approveHistory, isLoading = false} = props;
  const dialogConfig = {
    title,
    self: {
      open: isOpen,
      onClose: () => {
        closeDialog();
      },
    },
    content: <ApprovalProgressDialogContent approveHistory={approveHistory} />,
    onConfirm: () => {},
    onCancel: () => {
      closeDialog();
    },
    isLoadingDialog: true,
    isConfirmHidden: true,
    size: "md" as any,
  };

  return <>
          <LoadingDialog forceOpen={isLoading} />
          <DialogInOne {...dialogConfig} />
        </>;
};

export default ApprovalProgressDialog;
