import { FeatureCreditManagement } from "@wallet-manager/pfh-node-def-types/dist/src/FeatureCode";

import api from "../../../api";
import { Ipage } from "../../../api/types";
import { Iprefix } from "../../../hooks/useTranslation";
import { initZusParams } from "../../../utils/config";
import { createZusInstance } from "../../../zustand/store";

const translatePrefix: Iprefix = "customerAccount";

const PermissionKey = FeatureCreditManagement.CustomerAccount;

interface Ifield {
  merchantId: string;
  partnerName: string;
  programName: string[];
  customerNumber: string;
  merchantCustomerReference: string;
  currencies: string[];
  programAgentId: string;
  distributorAgentId: string;
}

const initFields: Ifield = {
  merchantId: "",
  partnerName: "",
  programName: [],
  customerNumber: "",
  merchantCustomerReference: "",
  currencies: [],
  programAgentId: "",
  distributorAgentId: "",
};

interface IsearchParam {
  merchantId: string;
  partnerName: string;
  programNames: string[];
  customerNumbers: string[];
  merchantCustomerRefs: string[];
  currencies: string[];
  createdDateFrom: string;
  createdDateTo: string;
  lastModifiedDateFrom: string;
  lastModifiedDateTo: string;
  programAgentId: string;
  distributorAgentId: string;
}

const useZusParams = createZusInstance<Ipage & Partial<IsearchParam>>(
  initZusParams
);

const apiObj: {
  export: (params: any, config?: any) => any;
  table: (params: any, config?: any) => any;
  detail: (params: { customerNumber: string }, config?: any) => any;
  getOffering: (
    params: { customerNumber: string; merchantId: string },
    config?: any
  ) => any;
  idNumber: (params: { customerNumber: string }, config?: any) => any;
  document: (params: { fileId: string }, config?: any) => any;
} = Object.freeze({
  export: api.CreditManagement.customerAccount.getExport,
  table: api.CreditManagement.customerAccount.getAll,
  detail: api.CreditManagement.customerAccount.getDetail,
  getOffering: api.CreditManagement.customerAccount.getOffering,
  idNumber: api.CreditManagement.customerAccount.getIdNumber,
  document: api.CreditManagement.customerAccount.getDocument,
});

const translateKeyObj = Object.freeze({
  operation: "operation",
  details: "details",
  merchantName: "merchant_name",
  partnerName: "partner_name",
  programName: "program_name",
  programAgentId: "program_agent_id",
  distributorAgentId: "distributor_agent_id",
  customerNo: "customer_no",
  merchantCustomerReference: "merchant_customer_reference",
  currency: "currency",
  maxCreditLimit: "max_credit_limit",
  createdBy: "created_by",
  creationTime: "creation_time",
  lastModifiedTime: "last_modified_time",
  residentAddress: "resident_address",
  firstName: "first_name",
  lastName: "last_name",
  emailAddress: "email_address",
  phoneNo: "phone_no",
  dateOfBirth: "date_of_birth",
  identificationDocumentType: "identification_document_type",
  placeOfIssue: "place_of_issue",
  identificationNo: "identification_no",
  issuanceDateOfIdentificationDocument:
    "issuance_date_of_identification_document",
  expiryDateOfIdentificationDocument: "expiry_date_of_identification_document",
  uploadedDocument: "uploaded_document",
  addressLine1: "address_line_1",
  addressLine2: "address_line_2",
  addressLine3: "address_line_3",
  postalCode: "postal_code",
  city: "city",
  country: "country",
  month: "month",
  deliveryAddress: "delivery_address",
  statementDate: 'statement_date',
  reportName: 'report_name',
  from: "from",
  to: "to",
  limitResultTo: "limit_result_to",
});

const omitKeyObj = Object.freeze({
  export: ["rawData"],
  table: [],
});

const checkPermissionColumnList: Array<{
  fieldName: string;
  permissionKey: string;
}> = [
  {
    fieldName: translateKeyObj.operation,
    permissionKey: PermissionKey.Details.prefix,
  },
];

export {
  translatePrefix,
  PermissionKey,
  type Ifield,
  type IsearchParam,
  initFields,
  initZusParams,
  useZusParams,
  apiObj,
  translateKeyObj,
  omitKeyObj,
  checkPermissionColumnList,
};
