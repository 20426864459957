import { convertDateStringToYearMonthDayString } from "../../../../../utils/dateStringUtils";
import { IAmountData } from "../../../types/ICustomerGraphData";
import { getDatesInRange } from "../../../utils/getDatesInRange";

interface IProps {
  filteredAmountData: IAmountData[] | undefined;
  resultKey: keyof IAmountData;
  dateFrom: string;
  dateTo: string;
}

export const getTransformedAmountData = (props: IProps) => {
  const { filteredAmountData, resultKey, dateFrom, dateTo } = props;

  // grouping data by settlementDate
  const transformedAmountData: { [key: string]: { [key: string]: number } } =
    {};
  filteredAmountData?.forEach((item: IAmountData) => {
    const { customerNumber, settlementDate } = item;

    let convertedSettlementDate =
      convertDateStringToYearMonthDayString(settlementDate);

    if (!transformedAmountData[convertedSettlementDate]) {
      transformedAmountData[convertedSettlementDate] = {};
    }

    transformedAmountData[convertedSettlementDate][customerNumber] = Number(
      item[resultKey]
    );
  });

  // Fill date range with empty data
  if (Object.keys(transformedAmountData).length > 0) {
    const dateInRange = getDatesInRange(dateFrom, dateTo);
    console.log("dateFrom = ", dateFrom);
    console.log("dateTo = ", dateTo);
    dateInRange.forEach((date: string) => {
      if (!transformedAmountData[date]) {
        transformedAmountData[date] = {};
      }
    });
  }

  return transformedAmountData;
};
