import React, { useMemo } from "react";

import { Theme } from "@mui/system/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

import { useTranslation } from "../../hooks";
import { useZusTranslatePrefixStore } from "../../zustand/store";
import { Box } from "../MuiGenerals";

export default function GridBox(props: {
  labelElePairArr: Array<[string, JSX.Element]>;
  columnCount?: number;
  containerSx?: SxProps<Theme>;
}) {
  const { labelElePairArr, columnCount = 2, containerSx } = props;
  const { translatePrefix } = useZusTranslatePrefixStore();
  const { t } = useTranslation(translatePrefix);

  const gridTemplateColumns = useMemo(() => {
    let result = "";
    for (let i = 0; i < columnCount; i++) {
      if (i) {
        result += " ";
      }

      const gap = `${20 * (columnCount * 2 - 1)}px`;

      const eachLabelWidth = `calc((100% - ${gap}) / ${columnCount} * 0.4)`;
      const eachContentWidth = `calc((100% - ${gap}) / ${columnCount} * 0.6)`;
      // const eachElementMinSize = `calc(${70 / columnCount}% - (20px * 3))`;
      // result += `minmax(${eachLabelWidth},${eachLabelWidth}) minmax(${eachContentWidth},${eachContentWidth})`;
      result += `minmax(min-content,max-content) minmax(${eachContentWidth},auto)`;
    }
    return result;
  }, [columnCount]);

  return (
    <Box
      sx={{
        ".MuiInputLabel-shrink": {
          display: "none",
        },
        display: "grid",
        gridTemplateColumns,
        columnGap: "20px",
        fontSize: "0.6875rem",
        ...containerSx,
      }}
    >
      {labelElePairArr.map(([label, element], i) => {
        const isInputElement = !!element.props.label;
        const inputLabelStyleAddOn: Record<string, string> = isInputElement
          ? { alignItems: "end", lineHeight: "25px" }
          : {};

        return (
          <React.Fragment key={i}>
            <Box
              sx={{
                margin: "12px 0px",
                display: "flex",
                alignItems: "center",
                ...inputLabelStyleAddOn,
              }}
            >
              <Box>{t(label)}:</Box>
            </Box>
            <Box
              sx={{
                margin: isInputElement ? "12px 0px" : 0,
                display: "flex",
                alignItems: "center",
                ">div": { width: "100%", margin: 0 },
              }}
            >
              {element}
            </Box>
          </React.Fragment>
        );
      })}
    </Box>
  );
}
