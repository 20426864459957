import FlexCenterBox from "../../../../../components/Layout/FlexCenterBox";
import IApproveRejectContentProps from "../../../types/IApproveRejectContentProps";
import Row from "./Row";

const Content = (props: IApproveRejectContentProps) => {
  const { rows, rowHeaders } = props;
  return (
    <FlexCenterBox isColumn style={{ marginTop: "24px"}}>
      {
        rowHeaders?.map((header:any, index:number) => {
          return <Row key={index} title={header} content={Array.isArray(rows) && rows.length > index ? rows[index] : ""} />
        })
      }
    </FlexCenterBox>
  );
};

export default Content;
