import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { COLOR } from '../../../utils/config';

interface IDashboardPanelProps {
  children: React.ReactNode;
  title?: string;
  spacing: number;
}

const DashboardPanel: React.FC<IDashboardPanelProps> = ({ children, title, spacing }) => {
  return (
    <Box
      sx={{
        mb: 2,
        mt: 2,
        width: '100%',
        ml: 1,
        mr: 1,
        maxWidth: 'calc(100vw - 64px)',
      }}
    >
      {title && (
        <Box sx={{
          overflow: 'hidden',
        }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {title}
          </Typography>
        </Box>
      )}
      <Grid container spacing={spacing} columns={{ xs: 4, sm: 8, md: 12 }}>
        {children}
      </Grid>
    </Box>
  );
};

export default DashboardPanel;
