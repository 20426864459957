import { Box, Button } from "../../../../../components/MuiGenerals";
import { customSx } from "../../../../../utils/styling";

interface IProps {
  label: string;
  isShow: boolean;
  handleClick: () => void;
}

const CreateMerchantDepositRequestButton = (props: IProps) => {
  const { label, isShow, handleClick } = props;

  if (!isShow) return <></>;

  return (
    <Box sx={customSx.createRequestBtn}>
      <Button onClick={handleClick} color={"secondary"} variant={"outlined"}>
        {label}
      </Button>
    </Box>
  );
};

export default CreateMerchantDepositRequestButton;
