import { GridColDef } from "@mui/x-data-grid"
import { useGenGridCol } from "../../../../../utils/ComponentHelper";

import { translateKeyObj as TK } from "../../config";

export const useColumn = (renderDownloadCell: any) => {

  const columns: GridColDef[] = [
    useGenGridCol(TK.operation, { renderCell: renderDownloadCell }),
    useGenGridCol(TK.programName, { minWidth: 150 }),
    useGenGridCol(TK.month),
    useGenGridCol(TK.statementDate, { minWidth: 150 }),
    useGenGridCol(TK.reportName, { minWidth: 150 }),
    useGenGridCol(TK.creationTime),
    useGenGridCol(TK.lastModifiedTime,{ minWidth: 150 })
  ]
  return {
    columns
  }

}