import React, { useEffect, useState } from 'react';

import { DialogInOne } from '../../../../../components';
import ReissueCardDialogContent from './components/ReissueCardDialogContent';

import { useAlerting, useGetMerchant, usePermission, useTranslation } from '../../../../../hooks';
import { ICardData } from '../../types/ICard';
import { PermissionKey, translatePrefix } from '../../config';
import api from '../../../../../api';
import { EnumCardReissueReason } from '@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master';

interface ReissueCardDialogProps {
    title: string;
    isOpen: boolean;
    closeDialog: () => void;
    refreshTable: () => void;
    cardData: ICardData;
}

const ReissueCardDialog: React.FC<ReissueCardDialogProps> = ({
    title,
    isOpen,
    closeDialog,
    refreshTable,
    cardData,
}) => {
    const merchantMap = useGetMerchant();
    const { hasPermission } = usePermission();
    const { tc, translate, t } = useTranslation(translatePrefix);
    const { alerting } = useAlerting();


    const hasPermissionToReissue = hasPermission(PermissionKey.Reissue);
    const [reissueField, setReissueField] = useState<IReissueInput>({
        merchantId: Number(merchantMap[cardData?.merchant_name]),
        reissueReason: "",
        samePin: false,
        samePan: false,
        deactivateCurrent: false,
        deactivateOldCardOnActivation:  false,
        customerNumber: cardData?.customer_no,
        cardNumber: cardData?.card_no,
    });

    const updateField = (fieldName: string, value: string | boolean| number) => {
        setReissueField((f: any) => ({ ...f, [fieldName]: value }));
    };

    const onSubmit = async () => {
        if(!hasPermissionToReissue) {
            return alerting("warning", tc("alert_no_permission"));
        }
        if(!reissueField.reissueReason) {
            return alerting("warning", translate("validation.inputRequired", {
                fieldName: t("reissue_reason"),
            }));
        }
        const data: IReissueInput = {
            merchantId: reissueField.merchantId,
            reissueReason: Number(reissueField.reissueReason),
            customerNumber: reissueField.customerNumber,
            cardNumber: reissueField.cardNumber,
        }

        if (data.reissueReason === EnumCardReissueReason.Replaced) {
            data.samePan = reissueField.samePan;
            data.deactivateCurrent = reissueField.deactivateCurrent;
            data.deactivateOldCardOnActivation = reissueField.deactivateOldCardOnActivation;
        }

        const res = await api.CreditManagement.cardList.postReissueCard(data);

        if (!res){
            return;
        }

        alerting("success", t("reissue_success"));
        refreshTable();
        closeDialog();
    };

    useEffect(() => {
        setReissueField({
            merchantId: Number(merchantMap[cardData?.merchant_name]),
            reissueReason: "",
            samePin: false,
            samePan: false,
            deactivateCurrent: false,
            deactivateOldCardOnActivation:  false,
            customerNumber: cardData?.customer_no,
            cardNumber: cardData?.card_no
    })}, [cardData])

    const dialogConfig = {
        title,
        self: {
            open: isOpen,
            onClose: () => {
            closeDialog();
        },
        },
        content: <ReissueCardDialogContent cardData={cardData} reissueField={reissueField} updateField={updateField}/>,
        onConfirm: async() => {
            await onSubmit()
        },
        onCancel: () => {
            closeDialog();
        },
        isLoadingDialog: true,
        isConfirmHidden: false,
        size: "md" as any,
    };

    return <DialogInOne {...dialogConfig} />;
}

export default ReissueCardDialog;
