import { RefObject } from 'react';

import TextField, { TextFieldProps } from '@mui/material/TextField';

import { inputCharacterMaxLength } from '../../utils/constant';
import { InputAdornment } from '../MuiGenerals';

type MpTextFieldProps = TextFieldProps & {
  innerRef?: RefObject<HTMLDivElement>;
  count: number;
};
export default function MpTextFieldMultiple(props: MpTextFieldProps) {
  const { innerRef, count, ...rest } = props;
  const newProps = {
    ...rest,
    ref: innerRef,
    inputProps: { maxLength: inputCharacterMaxLength, ...props.inputProps },
    InputProps: {
      endAdornment: <InputAdornment position="end">{count}</InputAdornment>,
      ...props.InputProps,
    },
  };

  return <TextField {...newProps} />;
}
