import { getLineChartXAxisDate } from "../../../../utils/getXAxisDate";

export const getCountGraphData = (
  transformedCountData: Record<string, Record<string, number>>,
  topCountCategories: string[]
) => {
  const countGraphData = Object.keys(transformedCountData).map(
    (settlementDate) => {
      const notShownCategories = topCountCategories.filter((category) => {
        return !Object.keys(transformedCountData[settlementDate]).includes(
          category
        );
      });

      // if there is no data for a category, add it with 0 value
      const data = transformedCountData[settlementDate];
      return {
        name: settlementDate,
        ...data,
        ...notShownCategories.reduce((acc: any, category: any) => {
          acc[category] = 0;
          return acc;
        }, {}),
        xAxisName: getLineChartXAxisDate(
          settlementDate,
          Object.keys(transformedCountData).length > 14
        ),
      };
    }
  );

  // sort by name
  countGraphData.sort((a, b) => {
    return new Date(a.name).getTime() - new Date(b.name).getTime();
  });

  return countGraphData;
};
