import { EPtRepaymentSchedule } from "@wallet-manager/pfh-node-def-types/dist/src/Endpoint/CreditPortal";

import axios from "../axiosInstance";

async function getAll(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };

  return await axios.get(
    EPtRepaymentSchedule.EptRepaymentSchedule.getAll,
    input
  );
}

async function getExport(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };

  return await axios.get(
    EPtRepaymentSchedule.EptRepaymentSchedule.export,
    input
  );
}

async function retry(
  params: {
    merchantId: number;
    customerNumber: string;
    orderId: string;
  },
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };

  return await axios.post(
    EPtRepaymentSchedule.EptRepaymentSchedule.retry,
    input
  );
}

async function rebroadcastCallbackPmpMessage(
  params: {
    merchantId: number;
    merchantOrderId: string;
  },
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };

  return await axios.post(
    EPtRepaymentSchedule.EptRepaymentSchedule.reBroadcast,
    input
  );
}

export default {
  getAll,
  getExport,
  retry,
  rebroadcastCallbackPmpMessage,
};
