import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
} from "@mui/x-data-grid";

import { LoadingDialog } from "../../../components";
import { ExportBtn } from "../../../components/Button";
import {
  CustomPagination,
  NoRowsOverlay,
  TableTab,
  SearchFirstDataGridOverlay
} from "../../../components/Layout";
import { Container } from "../../../components/MuiGenerals";
import {
  useAlerting,
  useListMappingTransform,
  usePermission,
  useTranslation,
} from "../../../hooks";
import { useGenGridCol } from "../../../utils/ComponentHelper";
import { dataGridDefaults } from "../../../utils/constant";
import {
  downloadFiles,
  getFullApiResponse,
  toDisplayTime,
} from "../../../utils/helper";
import { customSx } from "../../../utils/styling";
import {
  apiObj as api,
  omitKeyObj,
  PermissionKey,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from "./config";
import { EnumDailyTransactionReportType } from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Statement/Enum/DailyTransactionReportType";
import { generateTotalRow } from "./helpers/generateTotalRows";
import { createExport } from "./helpers/createExportCsv";
import { combineInterestAndCharge } from "./helpers/combineInterestAndCharge";

function ViewTable({isSearched}: { isSearched: boolean }) {
  const { alerting } = useAlerting();
  const { tc, t } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const [count, setCount] = useState(0);

  const listMapping = useListMapping("table");
  const { hasPermission } = usePermission();

  const onExport = async () => {
    if (count === 0) {
      return alerting("error", tc("no_data_export"));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      api.export({ ...zusParams.body, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiFn, count, true);
    if (rawRes.length === 0) return;
    const omitKeys = omitKeyObj.export;

    const res = listMapping(rawRes, omitKeys);
    
    const config = {
      isRawString: true,
    };
    
    const rawCSV = createExport(t, res);
    downloadFiles(`Daily_Transaction`, rawCSV, config);
  };

  return (
    <Container
      style={customSx.datagridContainer}
      maxWidth={false}
      disableGutters
    >
      <ExportBtn
        isShown={hasPermission(PermissionKey.Export)}
        onExport={onExport}
      />
      <TableTab>
        <TableList setCount={setCount} isSearched={isSearched}/>
      </TableTab>
    </Container>
  );
}

function TableList(props: { setCount: Dispatch<SetStateAction<number>>, isSearched: boolean }) {
  const { t } = useTranslation(translatePrefix);
  const { setCount, isSearched } = props;

  const zusParams = useZusParams();

  const listMapping = useListMapping("table");
  const listRes = useQuery({
    queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],
    queryFn: () => {
      if(!isSearched) {
        return;
      }
      return api.table(zusParams.body)
    },
  });

  const { rows = [], count = 0 } =
    (listRes.data as any as { rows: any[]; count: number }) || {};

  const omitKeys = omitKeyObj.table;

  const content: GridRowsProp = listMapping(rows, omitKeys);
  const totalRow = generateTotalRow(
    [ TK.e6OriginalTransactionAmount,
      TK.exchangeRateFee,
      TK.fees,
      TK.interestCharges, 
      TK.netSettlementAmount, 
      TK.total, 
      TK.ukAdjustmentFee, 
      TK.visaOriginalTransactionAmount,
      TK.visaReimbursementFees,
      TK.visaCharges,
  ], [...content]);
  totalRow["id"] = count + 1;
  totalRow[TK.e6ProcessingDate] = t("total");
  totalRow[TK.visaProcessingDate] = t("total");

  useEffect(() => setCount(count), [listRes]);

  const E6_GROUP_ID = t("e6");
  const VISA_GROUP_ID = t("visa");

  const columns: GridColDef[] = [
        useGenGridCol(TK.e6ProcessingDate, {
            minWidth: 150,
        }),
        useGenGridCol(TK.e6SettlementService, {
            minWidth: 150,
        }),
        useGenGridCol(TK.e6OriginalTransactionAmount, {
            minWidth: 150,
        }),
        useGenGridCol(TK.exchangeRateFee, {
            minWidth: 150,
        }),
        useGenGridCol(TK.ukAdjustmentFee, {
            minWidth: 150,
        }),
        useGenGridCol(TK.fees, {
            minWidth: 150,
        }),
        useGenGridCol(TK.interestCharges, {
            minWidth: 150,
        }),
        useGenGridCol(TK.total, {
            minWidth: 150,
        }),
        useGenGridCol(TK.visaProcessingDate, {
            minWidth: 150,
        }),
        useGenGridCol(TK.valueDate, {
            minWidth: 150,
        }),
        useGenGridCol(TK.settlementService, {
            minWidth: 150,
        }),
        useGenGridCol(TK.visaOriginalTransactionAmount, {
            minWidth: 150,
        }),
        useGenGridCol(TK.visaCharges, {
            minWidth: 150,
        }),
        useGenGridCol(TK.visaReimbursementFees, {
            minWidth: 150,
        }),
        useGenGridCol(TK.netSettlementAmount, {
            minWidth: 150,
        }),
    ];
    const groupColumns = [{
      groupId: E6_GROUP_ID,
      headerClassName: "column-group",
      children:[
          {
              field: TK.e6ProcessingDate,
          },
          {
              field: TK.e6SettlementService,
          },
          {
              field: TK.e6OriginalTransactionAmount,
          },
          {
              field: TK.exchangeRateFee,
          },
          {
              field: TK.ukAdjustmentFee,
          },
          {
              field: TK.fees,
          },
          {
              field: TK.interestCharges,
          },
          {
              field: TK.total,
          }
      ]
  }, {
      groupId: VISA_GROUP_ID,
      headerClassName: "column-group",
      children:[
          {
              field: TK.visaProcessingDate,
          },
          {
              field: TK.valueDate,
          },
          {
              field: TK.settlementService,
          },
          {
              field: TK.visaOriginalTransactionAmount,
          },
          {
              field: TK.visaCharges,
          },
          {
              field: TK.visaReimbursementFees,
          },
          {
              field: TK.netSettlementAmount,
          }
      ]
  }];

  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;

  return (
    <>
      <DataGrid
        {...dataGridDefaults}
        rows={isSearched && content.length > 0 ? [...content, totalRow]: []}
        sx={{
          ".column-group":{
            fontWeight: "bold",
            fontSize: "1.2rem",
          }
        }}
        rowCount={count}
        columns={columns}
        page={zusParams.body.page}
        onPageChange={zusParams.setPage}
        experimentalFeatures = {{
            columnGrouping: true,
        }}
        columnGroupingModel={groupColumns}
        components={{
          NoRowsOverlay: isSearched ? NoRowsOverlay: SearchFirstDataGridOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: count },
        }}
      />
    </>
  );
}

const useListMapping = (mode: "export" | "table") => {
  const { t } = useTranslation("enumConstants");
  const listMappingTransform = useListMappingTransform(mode);

  const listMapping = (array: any[], omitKeys: string[] = []) => {
    const res = array.map((item: any) => {
      const mappedResult = [
        [TK.e6ProcessingDate, toDisplayTime(item?.settlementDate)],
        [TK.e6SettlementService, t(EnumDailyTransactionReportType[item?.type])],
        [TK.e6OriginalTransactionAmount, item?.amount],
        [TK.exchangeRateFee, item?.atmFeeAmount],
        [TK.ukAdjustmentFee, item?.gbFeeAmount],
        [TK.fees, item?.feeAmount],
        [TK.interestCharges, combineInterestAndCharge(item?.interest, item?.charges)],
        [TK.total, item?.totalAmount],
        [TK.visaProcessingDate, toDisplayTime(item?.analysisVisaDailyTransactionReport?.settlementDate)],
        [TK.valueDate, toDisplayTime(item?.analysisVisaDailyTransactionReport?.updatedAt)],
        [TK.settlementService, t(EnumDailyTransactionReportType[item?.analysisVisaDailyTransactionReport?.type])],
        [TK.visaOriginalTransactionAmount, item?.analysisVisaDailyTransactionReport?.amount],
        [TK.visaCharges, item?.analysisVisaDailyTransactionReport?.charges],
        [TK.visaReimbursementFees, item?.analysisVisaDailyTransactionReport?.reimbursementFees],
        [TK.netSettlementAmount, item.analysisVisaDailyTransactionReport?.netSettlementAmount],
      ].filter(([key]) => !omitKeys.includes(key));
      return mappedResult;
    });

    const output = res.map(listMappingTransform);
    return output;
  };
  return listMapping;
};

export default ViewTable;
