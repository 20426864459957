import axios from "../../axiosInstance";

import { EptDataAnalysis } from "@wallet-manager/pfh-node-def-types/dist/src/Endpoint/CreditPortal";

async function getData( params: { settlementDateFrom: string; settlementDateTo: string, customerNumber: string },
    config?: any
  ) {
    const input = {
      params,
      ...config,
    };
    return await axios.get<never, any>(
        EptDataAnalysis.EptDataAnalysis.getConsumptionByPFHNumber,
      input
    );
}

async function getGraph(  
    params: { settlementDateFrom: string; settlementDateTo: string },
    config?: any
  ) {
    const input = {
      params,
      ...config,
    };
    return await axios.get<never, any>(
        EptDataAnalysis.EptDataAnalysis.getConsumptionByPFHNumberGraph,
      input
    );
}

export default{
    getData,
    getGraph
}