interface IProps {
    numberOfApprover: number;
}

const getIsAutoApprove = (props: IProps) => {
    const { numberOfApprover } = props;

    return numberOfApprover === 0;
}

export default getIsAutoApprove;
