import React from 'react';

import { Box, Button } from '../../../../../../components/MuiGenerals';
import { translatePrefix } from '../../../config';
import { useTranslation } from '../../../../../../hooks';

interface ActionButtonsProps {
    onConfirm: () => Promise<void>;
    closeDialog: () => void;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
    onConfirm,
    closeDialog
}) => {
    const { tc } = useTranslation(translatePrefix);
    return (
        <Box sx={{
            display:"flex",
            flexDirection:"row",
            width:"50%",
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <Button
                variant="contained"
                color="primary"
                sx={{mt: 2, mr: 2}}
                onClick={()=>{
                    onConfirm();
                }}
            >
                {tc('confirm')}
            </Button>
            <Button
                variant="contained"
                color="secondary"
                sx={{ mt: 2, mr: 2 }}
                onClick={closeDialog}
            >
                {tc('cancel')}
            </Button>
        </Box>
    )
}

export default ActionButtons;
