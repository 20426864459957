import { FeatureCreditManagement } from "@wallet-manager/pfh-node-def-types/dist/src/FeatureCode";

import api from "../../../api";
import { Ipage } from "../../../api/types";
import { Iprefix } from "../../../hooks/useTranslation";
import { initZusParams } from "../../../utils/config";
import { createZusInstance } from "../../../zustand/store";

const translatePrefix: Iprefix = "preCreateCardRequest";

const PermissionKey = FeatureCreditManagement.PreCreateCardRequest;

interface Ifield {
  merchantId: string;
  partnerName: string;
  programName: string[];
  cardProfileName: string;
  orderId: string;
  statuses: string[];
}

const initFields: Ifield = {
  merchantId: "",
  partnerName: "",
  programName: [],
  cardProfileName: "",
  orderId: "",
  statuses: [],
};

interface IsearchParam {
  merchantId: string;
  partnerName: string;
  programNames: string[];
  cardProfileName: string;
  orderId: string;
  statuses: string[];
  createdDateFrom: string;
  createdDateTo: string;
  lastModifiedDateFrom: string;
  lastModifiedDateTo: string;
}

const useZusParams = createZusInstance<Ipage & Partial<IsearchParam>>(
  initZusParams
);

const apiObj: {
  export: (params: any, config?: any) => any;
  table: (params: any, config?: any) => any;
} = Object.freeze({
  export: api.CreditManagement.preCreateCardRequest.getExport,
  table: api.CreditManagement.preCreateCardRequest.getAll,
});

const translateKeyObj = Object.freeze({
  merchantName: "merchant_name",
  orderId: "order_id",
  partnerName: "partner_name",
  programName: "program_name",
  cardProfileName: "card_profile_name",
  orderProgress: "order_progress",
  status: "status",
  createdBy: "created_by",
  creationTime: "creation_time",
  // lastModifiedBy: "last_modified_by",
  lastModifiedTime: "last_modified_time",
  createRequest: "create_request",
  noOfPreCreateCard: "no_of_pre_create_card",
  remarks: "remarks",
});

const omitKeyObj = Object.freeze({
  export: [],
  table: [],
});

export {
  PermissionKey,
  translatePrefix,
  type Ifield,
  type IsearchParam,
  initFields,
  initZusParams,
  useZusParams,
  apiObj,
  translateKeyObj,
  omitKeyObj,
};
