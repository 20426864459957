const Row = ({ title, content }: { title: string; content?: string }) => {
  return (
    <div
      style={{
        display: "flex",
        marginBottom: "24px",
        width: "70%",
      }}
    >
      <div
        style={{
          marginRight: "48px",
          whiteSpace: "nowrap",
        }}
      >
        {title}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          flex: 1,
          textAlign: "right",
        }}
      >
        {content || ""}
      </div>
    </div>
  );
};

export default Row;
