import { Typography } from '../../../../../../../components/MuiGenerals';
import '../index.css';

const FilterWithLabel = (props: {
  label: string;
  filter: React.ReactNode;
  isFullWidth?: boolean;
}) => {
  const { label, filter, isFullWidth } = props;

  return (
    <>
      <div className="label">
        <Typography variant="subtitle2">{label}</Typography>
      </div>
      <div className="filter">{filter}</div>
    </>
  );
};

export default FilterWithLabel;
