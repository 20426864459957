import { ICountData } from "../../../../types/IGraphData";

export const getFilteredCountData = (
  countData: ICountData[] | undefined,
  topCountCategories: string[]
) => {
  const filteredCountData = countData?.filter((item: ICountData) =>
    topCountCategories.includes(item.category)
  );

  return filteredCountData;
};
