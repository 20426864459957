import { useEffect, useState } from "react";

import { FilterTable } from "../../../components/Layout";
import { useZusTranslatePrefixStore } from "../../../zustand/store";
import { translatePrefix, useZusParams } from "./config";
import ViewFilter from "./ViewFilter";
import ViewTable from "./ViewTable";

function DailyTransaction() {
  const { setTranslatePrefix } = useZusTranslatePrefixStore();

  const zusParams = useZusParams();
  const [isSearched, setIsSearched] = useState(false);

  useEffect(() => {
    setTranslatePrefix(translatePrefix);
    return () => zusParams.clear();
  }, []);

  return <FilterTable filter={<ViewFilter setIsSearched={setIsSearched}/>} table={<ViewTable isSearched={isSearched}/>} />;
}

export default DailyTransaction;