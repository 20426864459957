import { IndexXAxisPair } from "../helpers/createIndexXAxisPairs";

const CustomXAxisTickFormatter = (
  tick: any,
  index: any,
  IndexXAxisPairs: IndexXAxisPair[]
) => {
  const targetIndex = IndexXAxisPairs.findIndex(
    (indexXAxisPair) => indexXAxisPair.index === index
  );
  const isNeedToDisplay = targetIndex > -1;

  const result = isNeedToDisplay ? IndexXAxisPairs[targetIndex].xAxisName : "";

  return result;
};

export default CustomXAxisTickFormatter;
