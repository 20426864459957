import { ICollapsibleFiltersConfig } from '..';
import ActionButtons from './ActionButtons';
import CollapseArea from './CollapseArea';
import FilterContainer from './FilterContainer';
import FilterWrapper from './FilterWrapper';

interface ICollapseSomeFilterViewProps {
  isCollapse?: boolean;
  requiredFilters: React.ReactNode[];
  collapsibleFilters: React.ReactNode[];
  onSearch: () => void;
  onReset: () => void;
  config?: ICollapsibleFiltersConfig;
}

const CollapseSomeFilterView = (props: ICollapseSomeFilterViewProps) => {
  const { isCollapse, requiredFilters, collapsibleFilters, onSearch, onReset, config } = props;

  return (
    <FilterWrapper>
      <FilterContainer config={config}>{requiredFilters}</FilterContainer>

      <CollapseArea isCollapse={isCollapse}>
        <FilterContainer config={config}>{collapsibleFilters}</FilterContainer>
      </CollapseArea>

      <ActionButtons onSearch={onSearch} onReset={onReset} />
    </FilterWrapper>
  );
};

export default CollapseSomeFilterView;
