import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { ProfileLoginFace, syncLocalStorage } from "../reducer/profileSlice";
import { PERSIST_STORE_KEY } from "../reducer/store";

export default function useReduxSync() {
  const dispatch = useDispatch();
  useEffect(() => {
    window.addEventListener("storage", (e) => {
      const localStoragePersistKey = `persist:${PERSIST_STORE_KEY}`;

      if (e.key !== localStoragePersistKey) {
        return;
      }

      const { profile: oldProfileObjStr } = e.oldValue
        ? JSON.parse(e.oldValue)
        : { profile: undefined };
      const { profile: newProfileObjStr } = JSON.parse(e.newValue!);

      if (newProfileObjStr === oldProfileObjStr) {
        return;
      }

      const newProfileObj: ProfileLoginFace = JSON.parse(newProfileObjStr);

      dispatch(syncLocalStorage(newProfileObj));
    });
  }, []);
}
