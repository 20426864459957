import {
  EPtCustomerAccount,
  EPtCustomerAccountInfo,
} from "@wallet-manager/pfh-node-def-types/dist/src/Endpoint/CreditPortal";

import axios from "../axiosInstance";
import { IGetNetworkMessageResponse } from "../types";
import { ResponseType } from "axios";

const endPoint = {
  ...EPtCustomerAccount.EPtCustomerAccount,
  ...EPtCustomerAccountInfo.EPtCustomerAccountInfo,
};

async function getAll(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(endPoint.getAll, input);
}
async function getExport(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(endPoint.export, input);
}
async function getDetail(params: { customerNumber: string }, config?: any) {
  const input = {
    params,
    ...config,
  };
  return await axios.get(endPoint.getOne, input);
}
async function getIdNumber(params: { customerNumber: string }, config?: any) {
  const input = {
    params,
    ...config,
  };
  return await axios.get(endPoint.getIdNumber, input);
}
async function getDocument(params: { fileId: string }, config?: any) {
  const input = {
    params,
    ...config,
  };

  return await axios.get(endPoint.getDocument, input);
}

async function getOffering(params: { customerNumber: string, merchantId: string }, config?: any) {
  const input = {
    params,
    ...config,
  };

  return await axios.get(endPoint.getOffering, input);
}

async function getNetworkMessage(
  params: {
    startTime: string;
    endTime: string;
    limit: number;
    merchantId: string;
    customerId: string;
  },
  config?: any
) {
  const input = {
    params: {
      ...params,
      ascending: false,
    },
    ...config,
  };

  return await axios.get<never, IGetNetworkMessageResponse>(
    EPtCustomerAccountInfo.EPtCustomerAccountInfo.getNetworkMessage,
    input
  );
}

async function getMonthlyStatement(
  params: {
    merchantId: string;
    programName: string;
    customerNumber: string;
    limit: number;
    offset: number;
  }
) {
  const input = {
    params: {
      ...params,
    }
  }

  return await axios.get(
    EPtCustomerAccountInfo.EPtCustomerAccountInfo.getMonthlyStatement,
    input
  );
}

async function downloadMonthlyStatement (
  params: {
    monthlyStatementId: string;
  }
) {
  const input = {
    params: {
      ...params,
    }, 
    responseType: 'blob' as ResponseType

  }
  return await axios.get(
    EPtCustomerAccountInfo.EPtCustomerAccountInfo.downloadMonthlyStatement,
    input
  )
}

export default {
  getAll,
  getExport,
  getDetail,
  getIdNumber,
  getDocument,
  getOffering,
  getNetworkMessage,
  getMonthlyStatement,
  downloadMonthlyStatement
};
