import React from "react";
import Fade from "@mui/material/Fade";
import { Box, Modal } from "../MuiGenerals";
import OuterBox from "./components/OuterBox";
import Title from "./components/Title";
import CloseBtn from "./components/CloseBtn";
import InnerBox from "./components/InnerBox";

interface IProps {
  isOpen: boolean;
  title: string;
  isShowCloseBtn?: boolean;
  maxWidth?: string;
  children: React.ReactNode | string;
  closeDialog: () => void;
}

export const containerStyle = {
  width: "100%",
  height: "100%",
  position: "relative",
  color: "#ffffff",
  pointerEvents: "none",
};

const DialogWithOuterBox = (props: IProps) => {
  const {
    isShowCloseBtn = true,
    maxWidth,
    isOpen,
    title,
    children,
    closeDialog,
  } = props;

  return (
    <Modal open={isOpen} onClose={closeDialog} closeAfterTransition>
      <Fade in={isOpen}>
        <Box sx={containerStyle}>
          <OuterBox maxWidth={maxWidth}>
            <Title>{title}</Title>
            {isShowCloseBtn && <CloseBtn handleClick={closeDialog} />}

            <InnerBox>{children}</InnerBox>
          </OuterBox>
        </Box>
      </Fade>
    </Modal>
  );
};

export default DialogWithOuterBox;
