export interface IndexXAxisPair {
  index: number;
  xAxisName: string;
}

export const createIndexXAxisPairs = (data: any): IndexXAxisPair[] => {
  // x-axis name is unique
  const indexXAxisPairs: IndexXAxisPair[] = [];

  data.forEach((dataPoint: any) => {
    const isXAxisNameNotExist =
      indexXAxisPairs.find((pair) => pair.xAxisName === dataPoint.xAxisName) ===
      undefined;

    if (isXAxisNameNotExist) {
      indexXAxisPairs.push({
        index: data.indexOf(dataPoint),
        xAxisName: dataPoint.xAxisName,
      });
    }
  });

  return indexXAxisPairs;
};
