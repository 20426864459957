import { EptCustomerSpendingReport } from "@wallet-manager/pfh-node-def-types/dist/src/Endpoint/CreditPortal";

import axios from "../axiosInstance";

const { CustomerDailySpendingReport, CustomerMonthlySpendingReport } =
  EptCustomerSpendingReport;

const dailyReport = {
  getAll: async (params: any, config?: any) => {
    const { pageSize, page, ...rest } = params;
    const input = {
      params: {
        ...rest,
        limit: pageSize,
        offset: pageSize * page,
      },
      ...config,
    };
    return await axios.get(CustomerDailySpendingReport.getAll, input);
  },

  getExport: async (params: any, config?: any) => {
    const { pageSize, page, ...rest } = params;
    const input = {
      params: {
        ...rest,
        limit: pageSize,
        offset: pageSize * page,
      },
      ...config,
    };
    return await axios.get(CustomerDailySpendingReport.export, input);
  },
};
const monthlyReport = {
  getAll: async (params: any, config?: any) => {
    const { pageSize, page, ...rest } = params;
    const input = {
      params: {
        ...rest,
        limit: pageSize,
        offset: pageSize * page,
      },
      ...config,
    };
    return await axios.get(CustomerMonthlySpendingReport.getAll, input);
  },

  getExport: async (params: any, config?: any) => {
    const { pageSize, page, ...rest } = params;
    const input = {
      params: {
        ...rest,
        limit: pageSize,
        offset: pageSize * page,
      },
      ...config,
    };
    return await axios.get(CustomerMonthlySpendingReport.export, input);
  },
};

export default {
  dailyReport,
  monthlyReport,
};
