const CustomizedDot = (props: any) => {
    const { cx, cy, stroke, payload, value, dotColor } = props;
    return (
        <svg fill={dotColor} >
            <circle r={5} cx={cx} cy={cy} stroke={"#FFFFFF"} />
        </svg>
    );
};

export default CustomizedDot;
