import {
  AlerterState,
  close,
  open,
  setRedirectTo as setRedirectToFn,
} from "../reducer/alerterSlice";
import { useAppDispatch } from "../reducer/hooks";

function useAlerting() {
  const dispatch = useAppDispatch();

  const alerting = (
    severity: AlerterState["severity"],
    text: string,
    config?: object
  ) => {
    const params = {
      text,
      severity,
      ...config,
    };
    dispatch(close());
    setTimeout(() => {
      dispatch(open(params));
    }, 100);
  };

  const closeAlert = () => {
    dispatch(close());
  };

  const setRedirectTo = (url: string) => dispatch(setRedirectToFn(url));

  return { alerting, setRedirectTo, closeAlert };
}

export default useAlerting;
/*usage
const { alerting } = useAlerting();
alerting("success", "an alert!")
 */
