import { useQuery } from "react-query";

import api from "../../api";
import { useAppDispatch, useAppSelector } from "../../reducer/hooks";
import {
  selectMerchantList,
  setMerchantList,
} from "../../reducer/merchantListSlice";

export async function useFetchMerchant() {
  const dispatch = useAppDispatch();

  useQuery("merchantList", api.CreditPortal.getMerchantList, {
    staleTime: 60 * 60 * 12,
    onSuccess: (dbRes) => {
      dispatch(setMerchantList(dbRes));
    },
  });
}

export function useGetMerchant() {
  const merchantList = useAppSelector(selectMerchantList);
  return merchantList.list;
}
