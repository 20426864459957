import { cond } from "lodash";
import { useTranslate } from "react-admin";

import { GridColDef } from "@mui/x-data-grid";

import { Tooltip, Typography } from "../components/MuiGenerals";
import { useAlerting, useTranslation } from "../hooks";
import { useZusTranslatePrefixStore } from "../zustand/store";
import { COLOR } from "./config";
import { tableConfig } from "./constant";
import { copyToClipboard } from "./helper";

const sx = {
  cell: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  addressCell: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: COLOR.text.primary.hex,
    cursor: "pointer",
    textDecoration: "underline",
  },
  label: {
    padding: "25px 0px 0px 0px",
  },
};

export function useGenFilterField(array: [string, any][]) {
  const { translatePrefix } = useZusTranslatePrefixStore();
  const { t } = useTranslation(translatePrefix);
  const fieldArr = array.map(([label, content]: any) => (
    <>
      <Typography variant="subtitle2" sx={sx.label}>
        {t(label)}
      </Typography>
      <div className="filterFieldInput">{content}</div>
    </>
  ));
  return fieldArr;
}

export function useGenGridCol(
  field: string,
  rest?: Partial<GridColDef>
): GridColDef {
  const translate = useTranslate();
  const { translatePrefix } = useZusTranslatePrefixStore();
  const { t } = useTranslation(translatePrefix);
  const { alerting } = useAlerting();
  const isDateField = (field: string) => tableConfig.dateFields.includes(field);
  const defaultCase = () => true;
  const isAddress = (field: string) =>
    tableConfig.addressFields.includes(field);
  const isFreeTextField = (field: string) =>
    tableConfig.freeTextFields.includes(field);
  const renderCell = (params: any) => {
    const { value = "", field = "" } = params;
    const isBoolean = (v: any) => typeof v === "boolean";
    const content = cond([
      [(v: any) => v === "0", () => "0"],
      [(v: any) => v === 0, () => 0],
      [(v: any) => !v, () => ""],
      [isBoolean, (v: any) => (v ? "yes" : "no")],
      [defaultCase, (v: any) => v],
    ])(value);
    return (
      <Tooltip title={content}>
        <span
          style={isAddress(field) ? sx.addressCell : sx.cell}
          className="csutable-cell-trucate"
          onClick={(e) => cellOnClick(e, field, value)}
        >
          {content}
        </span>
      </Tooltip>
    );
  };
  const minWidth = cond([
    [isAddress, () => tableConfig.addressWidth],
    [isDateField, () => tableConfig.dateWidth],
    [isFreeTextField, () => tableConfig.freeTextWidth],
    [defaultCase, () => tableConfig.defaultWidth],
  ])(field);

  const cellOnClick = (e: any, field: string, value: string) => {
    if (isAddress(field)) {
      copyToClipboard(value);
      alerting("success", translate(`common.copied`));
    }
  };
  return {
    field,
    headerName: t(field),
    flex: 1,
    minWidth,
    renderCell,
    headerAlign: "center",
    align: "center",
    sortable: false,
    ...rest,
  };
}
