import { Button } from "../../../../../components/MuiGenerals";
import { DialogInOne, MpTextField } from "../../../../../components";
import { useTranslation } from "../../../../../hooks";

interface IProps {
  title: string;
  isOpen: boolean;
  closePrompt: () => void;
  handleConfirmClick: () => void;
  remarksInput: string;
  setRemarksInput: (value: string) => void;
}

const RemarkTextPromptPopup = (props: IProps) => {
  const {
    title,
    isOpen,
    closePrompt,
    handleConfirmClick,
    remarksInput,
    setRemarksInput,
  } = props;

  const { t, tc } = useTranslation("approvalProgress");

  const dialogConfig = {
    title,
    self: {
      open: isOpen,
      onClose: () => {
        closePrompt();
      },
    },
    content: (
      <div>
        <>
          <div style={{ width: "100%" }}>
            {
              <MpTextField
                fullWidth
                label={tc("phInputField", { fieldName: t("remarks") })}
                value={remarksInput}
                onChange={(e) => setRemarksInput(e.target.value)}
              />
            }
          </div>
        </>
      </div>
    ),
    onConfirm: () => {},
    onCancel: () => {
      closePrompt();
    },
    isLoadingDialog: true,
    isConfirmHidden: true,
    size: "sm" as any,
    actionButtons: (
      <>
        <Button
          className={"inheritColor"}
          color={"primary"}
          variant={"contained"}
          children={t("confirm_btn")}
          onClick={handleConfirmClick}
          style={{ marginRight: "12px" }}
        />
      </>
    ),
  };

  return <DialogInOne {...dialogConfig} />;
};

export default RemarkTextPromptPopup;
