import { IAmountData } from "../../../types/ICustomerGraphData";

export const getCustomerTotals = (
  amountData: IAmountData[] | undefined,
  resultKey: keyof IAmountData
) => {
  const customerTotals: { [key: string]: number } = {};

  amountData?.forEach((item: IAmountData) => {
    const { customerNumber } = item;
    if (!customerTotals[customerNumber]) {
      customerTotals[customerNumber] = 0;
    }
    customerTotals[customerNumber] += Number(item[resultKey]);
  });

  return customerTotals;
};
