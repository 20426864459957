import { useState } from "react";
import api from "../../../../../api";
import { DialogInOne, LoadingDialog } from "../../../../../components";
import { useAlerting, useTranslation } from "../../../../../hooks";
import { useZusTranslatePrefixStore } from "../../../../../zustand/store";
import { translateKeyObj } from "../../config";
import Content from "./Content";

interface RetryDialogProps {
  isOpen: boolean;
  closeDialog: () => void;
  refreshTable: () => void;
  selectedRow: any;
}

const RetryDialog = (props: RetryDialogProps) => {
  const { isOpen, closeDialog, refreshTable, selectedRow } = props;

  const [isShowLoading, setIsShowLoading] = useState(false);

  const { translatePrefix } = useZusTranslatePrefixStore();
  const { t } = useTranslation(translatePrefix);
  const { alerting } = useAlerting();

  const closeDialogAndRefresh = () => {
    closeDialog();
    refreshTable();
  };

  const handleConfirm = async () => {
    setIsShowLoading(true);

    const res = await api.CreditManagement.creditAdjustmentRequest.retry({
      requestId: Number(selectedRow?.rawData.id),
    });

    setIsShowLoading(false);

    if (!res) return;

    alerting("success", t("retryDialog.successMsg"));
    closeDialogAndRefresh();
  };

  const handleCancel = () => {
    closeDialog();
  };

  const dialogConfig = {
    title: t("retryDialog.title"),
    self: {
      open: isOpen,
      onClose: () => {
        closeDialog();
      },
    },
    content: (
      <Content
        merchantName={selectedRow?.[translateKeyObj.merchantName]}
        orderId={selectedRow?.[translateKeyObj.orderId]}
        customerNumber={selectedRow?.[translateKeyObj.customerNo]}
        adjustmentType={selectedRow?.[translateKeyObj.adjustmentType]}
        currency={selectedRow?.[translateKeyObj.currency]}
        amount={selectedRow?.[translateKeyObj.adjustmentAmount]}
      />
    ),
    onConfirm: handleConfirm,
    onCancel: handleCancel,
  };

  return (
    <>
      <LoadingDialog forceOpen={isShowLoading} />
      <DialogInOne {...dialogConfig} />
    </>
  );
};

export default RetryDialog;
