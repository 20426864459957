import balanceAdjustmentRequest from "./balanceAdjustmentRequest";
import cardList from "./cardList";
import cardOrderRequest from "./cardOrderRequest";
import cardTransaction from "./cardTransaction";
import creditAdjustmentRequest from "./creditAdjustmentRequest";
import customerAccount from "./customerAccount";
import customerWallet from "./customerWallet";
import feeWallet from "./feeWallet";
import merchantDepositRequest from "./merchantDepositRequest";
import merchantWallet from "./merchantWallet";
import preCreateCardRequest from "./preCreateCardRequest";
import repaymentSchedule from "./repaymentSchedule";
import walletTransaction from "./walletTransaction";

export default {
  creditAdjustmentRequest,
  cardOrderRequest,
  balanceAdjustmentRequest,
  customerAccount,
  cardList,
  preCreateCardRequest,
  merchantDepositRequest,
  merchantWallet,
  customerWallet,
  feeWallet,
  cardTransaction,
  walletTransaction,
  repaymentSchedule,
};
