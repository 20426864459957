import React, { useMemo } from "react";
import { Box } from "../../../../components/MuiGenerals";
import { COLOR } from "../../../../utils/config";
import ChartBoard, { IChartBoardChart } from "../ChartBoard";
import TableBoard from "../TableBoard";
import useTranslation, { Iprefix } from "../../../../hooks/useTranslation";
import SettlementDateBar from "../SettlementDateBar";
import TableSearchBar from "../TableSearchBar";
import { Button, Typography, Collapse } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { debounce } from "lodash";

interface DataAnalysisBoardProps {
  charts: IChartBoardChart[];
  tableData: any;
  isChartLoading?: boolean;
  tableColumnKeys: any[];
  reloadGraphFunction: any;
  reloadTableFunction: any;
  zusParams: any;
  translatePrefix: Iprefix;
  tableResultMappingFunction?: any;
}

const DataAnalysisBoard: React.FC<DataAnalysisBoardProps> = ({
  charts,
  tableData,
  tableColumnKeys,
  translatePrefix,
  zusParams,
  tableResultMappingFunction,
  reloadGraphFunction,
  reloadTableFunction,
  isChartLoading,
}) => {
  const [isExpand, setIsExpand] = React.useState(true);
  const { t, tc } = useTranslation(translatePrefix);
  return (
    <>
      <SettlementDateBar
        translatePrefix={translatePrefix}
        reloadFunction={reloadGraphFunction}
      />
      <Box
        sx={{
          backgroundColor: COLOR.background.secondary.hex,
          borderRadius: 2,
          paddingTop: 1,
          paddingBottom: 1,
          paddingLeft: 2,
          paddingRight: 3.5,
          mt: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: 1,
            paddingTop: 1,
            paddingBottom: 1,
          }}
        >
          <Typography
            sx={{
              fontSize: "1.2rem",
            }}
          >
            {t("chartPanelTitle")}
          </Typography>
          <Box>
            <Button
              onClick={() => {
                setIsExpand(!isExpand);
              }}
              variant="contained"
              sx={{
                paddingLeft: 1,
              }}
            >
              {isExpand ? (
                <ArrowDropUpIcon sx={{ mr: 0.5 }} />
              ) : (
                <ArrowDropDownIcon sx={{ mr: 0.5 }} />
              )}
              {isExpand ? tc("collapse") : tc("expand")}
            </Button>
          </Box>
        </Box>
        <Collapse in={isExpand}>
          {useMemo(
            () => (
              <ChartBoard charts={charts} translatePrefix={translatePrefix} />
            ),
            [isChartLoading]
          )}
        </Collapse>
      </Box>
      <Box
        sx={{
          backgroundColor: COLOR.background.secondary.hex,
          borderRadius: 2,
          mt: 2,
        }}
      >
        <Box sx={{ padding: 2 }}>
          <TableSearchBar
            translatePrefix={translatePrefix}
            onChange={debounce((e: React.ChangeEvent<HTMLInputElement>) => {
              reloadTableFunction(e.target.value);
            }, 800)}
          />
        </Box>
        <Box sx={{ mb: 1, borderBottom: "1px solid #FFFFFF" }} />
        <Box
          sx={{
            paddingBottom: 2,
            paddingLeft: 2,
            paddingRight: 2,
          }}
        >
          <TableBoard
            translatePrefix={translatePrefix}
            data={tableData}
            columnKeys={tableColumnKeys}
            zusParams={zusParams}
            mappingFunction={tableResultMappingFunction}
          />
        </Box>
      </Box>
    </>
  );
};

export default DataAnalysisBoard;
