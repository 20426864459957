import React, { useEffect } from "react";
import { FilterTable } from "../../../../../components/Layout";
import ViewFilter from "./components/ViewFilter";
import ViewTable from "./components/ViewTable";

const NetworkMessageTabContent = () => {
  return (
    <FilterTable
      filter={<ViewFilter />}
      table={<ViewTable />}
      divideLineBackgroundColor="#515664"
    />
  );
};

export default NetworkMessageTabContent;
