import errorCode from '../../utils/errorCode.json';

const json = {
  errorCode: errorCode.zh_cn,
  common: {},
  enumConstants: {},
  yubiKeyHook: {},
  menu: {},
};

export default json;
