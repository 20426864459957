import React from 'react';

import Menu from '@mui/material/Menu';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { MenuItem, Tooltip, IconButton } from '../../MuiGenerals';

interface IButtonMenuProps{
    options: {
        name: string;
        onClickFn: (event?: React.MouseEvent<HTMLElement>) => void;
        noShow?: boolean;
        isDisabled?: boolean;
      }[];
      title?: string;
}

const ButtonMenu: React.FC<IButtonMenuProps> = ({
    title,
    options,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
        <Tooltip title={title}>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreHorizIcon />
            </IconButton>
        </Tooltip>
        <Menu
            MenuListProps={{
                'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
        >
            {options.map(({ name, onClickFn, noShow, isDisabled }) => {
            const handleOnClick = () => {
                onClickFn();
                handleClose();
            };

            return (
                !noShow && (
                <MenuItem key={name} onClick={handleOnClick} disabled={isDisabled}>
                    {name}
                </MenuItem>
            )
            );
        })}
        </Menu>
    </>
    );
}

export default ButtonMenu;
