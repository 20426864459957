import React, { ReactNode } from "react";
import { Box } from "../../MuiGenerals";

const outerBoxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#43454b",
  width: "80%",
  padding: "24px",
  pointerEvents: "auto",
};

interface IProps {
  children: ReactNode;
  maxWidth?: string;
}

const OuterBox = ({ children, maxWidth = "800px" }: IProps) => {
  return <Box sx={{ ...outerBoxStyle, maxWidth }}>{children}</Box>;
};

export default OuterBox;
