import React, { ReactNode } from "react";

const style = {};

interface IProps {
  children: ReactNode;
  isHideMargin?: boolean;
}

const ButtonWrapper = ({ children, isHideMargin }: IProps) => {
  return (
    <div style={{ ...style, marginLeft: isHideMargin ? "0px" : "24px" }}>
      {children}
    </div>
  );
};

export default ButtonWrapper;
