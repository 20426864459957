import { COLOR } from "../../utils/config";

export default function DivideLine({
  backgroundColor,
}: {
  backgroundColor?: string;
}) {
  const lineStyle = {
    position: "relative" as const,
    width: "calc(100% + 4rem + 2px)",
    height: " 1rem",
    backgroundColor: backgroundColor || COLOR.background.primary.hex,
    left: "calc(-2rem - 1px)",
    borderBottom: `1px solid ${COLOR.text.secondary.rgba(0.5)}`,
    borderTop: `1px solid ${COLOR.text.secondary.rgba(0.5)}`,
  };
  return <div style={lineStyle} />;
}
