import { useState } from "react";

import api from "../../../api";
import {
  DialogInOne,
  MpTextField,
  MpTextFieldNumberOnly,
  SingleSelection,
} from "../../../components";
import { GridBox } from "../../../components/Layout";
import { SelectChangeEvent } from "../../../components/MuiGenerals";
import {
  useAlerting,
  useGetCardProfileName,
  useGetMerchant,
  useGetProgram,
  useTranslation,
} from "../../../hooks";
import { useZusDialogStore } from "../../../zustand/store";
import { translateKeyObj as TK, translatePrefix, useZusParams } from "./config";

interface Ifield {
  merchantId: string;
  programName: string;
  cardProfileName: string;
  noOfPreCreateCard: string;
  remarks: string;
}
const initFields: Ifield = {
  merchantId: "",
  programName: "",
  cardProfileName: "",
  noOfPreCreateCard: "",
  remarks: "",
};

interface IapiParams {
  merchantId: number;
  programName: string;
  cardProfileName: string;
  count: number;
  remarks: string;
}

export default function CreateRequestDialog() {
  const zusDialog = useZusDialogStore();
  const { t, tc } = useTranslation(translatePrefix);
  const [fields, setFields] = useState<Ifield>(initFields);
  const zusParams = useZusParams();

  const merchantObj = useGetMerchant();

  const { merchantId, programName } = fields;
  const programObj = useGetProgram(merchantId) || {};
  const cardProfileNameObj =
    useGetCardProfileName({ merchantId, programName }) || {};

  const { alerting } = useAlerting();

  const onChange =
    (field: keyof Ifield) =>
    (
      e:
        | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<string>
    ) =>
      setFields((fields) => ({ ...fields, [field]: e.target.value }));

  const getParams: () => IapiParams = () => {
    const { merchantId: merchantIdStr, noOfPreCreateCard, ...rest } = fields;
    const merchantId = Number(merchantIdStr);
    return { ...rest, merchantId, count: Number(noOfPreCreateCard) };
  };

  const apiParams = getParams();

  const onSubmit = async () => {
    const emptyFieldValueArr = Object.entries(fields).filter(
      ([field, value]) => !value && field !== "remarks"
    );
    const firstEmptyFieldArr = emptyFieldValueArr[0];
    if (firstEmptyFieldArr) {
      const [key]: Array<keyof typeof fields> = firstEmptyFieldArr;

      const isSelectionKey = key === "merchantId" || key === "programName";
      if (isSelectionKey) {
        return alerting(
          "warning",
          tc("phSelection", {
            fieldName: t(TK[key === "merchantId" ? "merchantName" : key]),
          })
        );
      }
      return alerting("warning", tc("phInputField", { fieldName: t(TK[key]) }));
    }

    if (!Number(apiParams.count)) {
      return alerting("warning", t("zero_card_warning"));
    }
    const res =
      await api.CreditManagement.preCreateCardRequest.postCreateRequest(
        apiParams
      );

    if (!res) {
      return;
    }

    alerting(
      "success",
      tc("successCreate", { target: t("pre_create_card_request") })
    );
    setFields(initFields);
    zusDialog.close();
    zusParams.refetch();
  };

  const labelElePairArr: Array<[string, JSX.Element]> = [
    [
      TK.merchantName,
      <SingleSelection
        label={tc("phSelection", { fieldName: t(TK.merchantName) })}
        value={fields.merchantId}
        onChange={(e) => {
          onChange("merchantId")(e);
          setFields((fields) => ({
            ...fields,
            programName: "",
            cardProfileName: "",
          }));
        }}
        clearSelect={() =>
          setFields((fields) => ({ ...fields, merchantId: "" }))
        }
        nameFn={(name) => name}
        isNoSorting
        enumData={merchantObj}
      />,
    ],
    [
      TK.programName,
      <SingleSelection
        label={tc("phSelection", { fieldName: t(TK.programName) })}
        value={fields.programName}
        onChange={(e) => {
          onChange("programName")(e);
          setFields((fields) => ({
            ...fields,
            cardProfileName: "",
          }));
        }}
        clearSelect={() =>
          setFields((fields) => ({ ...fields, programName: "" }))
        }
        nameFn={(name) => name}
        enumData={programObj}
      />,
    ],
    [
      TK.cardProfileName,
      <SingleSelection
        label={tc("phSelection", { fieldName: t(TK.cardProfileName) })}
        value={fields.cardProfileName}
        onChange={onChange("cardProfileName")}
        clearSelect={() =>
          setFields((fields) => ({ ...fields, cardProfileName: "" }))
        }
        nameFn={(name) => name}
        enumData={cardProfileNameObj}
      />,
    ],
    [
      TK.noOfPreCreateCard,
      <MpTextFieldNumberOnly
        label={tc("phInputField", { fieldName: t(TK.noOfPreCreateCard) })}
        value={fields.noOfPreCreateCard}
        onChange={onChange("noOfPreCreateCard")}
        maxNumber={1000}
        integerOnly
      />,
    ],
    [
      TK.remarks,
      <MpTextField
        label={tc("phInputFieldOptional", { fieldName: t(TK.remarks) })}
        value={fields.remarks}
        onChange={onChange("remarks")}
      />,
    ],
  ];

  const dialogConfig = {
    title: t("create_request"),
    self: {
      open: zusDialog.match("createRequestDialog"),
      onClose: () => {
        zusDialog.close();
        setFields(initFields);
      },
    },
    content: <GridBox labelElePairArr={labelElePairArr} />,
    onConfirm: onSubmit,
    onCancel: () => {
      zusDialog.close();
      setFields(initFields);
    },
    isLoadingDialog: true,
  };

  return <DialogInOne {...dialogConfig} />;
}
