import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useQuery } from "react-query";

import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { EnumCardOrderStatus, EnumCardReissueReason } from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master";

import { LoadingDialog } from "../../../components";
import { ExportBtn } from "../../../components/Button";
import {
  CustomPagination,
  NoRowsOverlay,
  TableTab,
} from "../../../components/Layout";
import { Container } from "../../../components/MuiGenerals";
import {
  useAlerting,
  useGetMerchant,
  useListMappingTransform,
  usePermission,
  useTranslation,
} from "../../../hooks";
import { useGenGridCol } from "../../../utils/ComponentHelper";
import { dataGridDefaults } from "../../../utils/constant";
import {
  convertTrueFalseToYesNo,
  downloadFiles,
  getFullApiResponse,
  toDisplayTime,
} from "../../../utils/helper";
import { customSx } from "../../../utils/styling";
import {
  apiObj as api,
  omitKeyObj,
  PermissionKey,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from "./config";

function ViewTable() {
  const { alerting } = useAlerting();
  const { tc } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const [count, setCount] = useState(0);

  const listMapping = useListMapping("export");
  const { hasPermission } = usePermission();

  const onExport = async () => {
    if (count === 0) {
      return alerting("error", tc("no_data_export"));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      api.export({ ...zusParams.body, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiFn, count, true);
    if (rawRes.length === 0) return;
    const omitKeys = omitKeyObj.export;

    const res = listMapping(rawRes, omitKeys);
    const config = {};
    downloadFiles(`Card_Order_Request`, res, config);
  };

  return (
    <Container
      style={customSx.datagridContainer}
      maxWidth={false}
      disableGutters
    >
      <ExportBtn
        isShown={hasPermission(PermissionKey.Export)}
        onExport={onExport}
      />
      <TableTab>
        <TableList setCount={setCount} />
      </TableTab>
    </Container>
  );
}

function TableList(props: { setCount: Dispatch<SetStateAction<number>> }) {
  const { setCount } = props;

  const zusParams = useZusParams();

  const listMapping = useListMapping("table");

  const listRes = useQuery({
    queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],
    queryFn: () => api.table(zusParams.body),
  });

  const { rows = [], count = 0 } =
    (listRes.data as any as { rows: any[]; count: number }) || {};

  const omitKeys = omitKeyObj.table;
  const content: GridRowsProp = listMapping(rows, omitKeys);

  useEffect(() => setCount(count), [listRes]);

  const columns: GridColDef[] = [
    useGenGridCol(TK.merchantName, {
      minWidth: 150,
    }),
    useGenGridCol(TK.orderId, {
      minWidth: 150,
    }),
    useGenGridCol(TK.programName,{
      minWidth: 150,
    }),
    useGenGridCol(TK.customerNo),
    useGenGridCol(TK.cardType),
    useGenGridCol(TK.cardProfileName, {
      minWidth: 150,
    }),
    useGenGridCol(TK.status, { minWidth: 150 }),
    useGenGridCol(TK.remark),
    useGenGridCol(TK.reissued),
    useGenGridCol(TK.expiryDate),
    useGenGridCol(TK.reissueReason),
    useGenGridCol(TK.originalCardNo, { minWidth: 150 }),
    useGenGridCol(TK.samePan),
    useGenGridCol(TK.samePin),
    useGenGridCol(TK.deactivatedCurrentCard, { minWidth: 150 }),
    useGenGridCol(TK.deactivatedOriginalCardOnActivation, { minWidth: 150 }),
    useGenGridCol(TK.creationTime, {
      minWidth: 150,
    }),
    useGenGridCol(TK.lastModifiedTime, {
      minWidth: 150,
    }),
  ];
  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;
  return (
    <>
      <DataGrid
        {...dataGridDefaults}
        rows={content}
        rowCount={count}
        columns={columns}
        page={zusParams.body.page}
        onPageChange={zusParams.setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: count },
        }}
      />
    </>
  );
}

const useListMapping = (mode: "export" | "table") => {
  const { t } = useTranslation("enumConstants");
  const listMappingTransform = useListMappingTransform(mode);
  const merchantObj = useGetMerchant();

  const listMapping = (array: any[], omitKeys: string[] = []) => {
    const res = array.map((item: any) => {
      const cardExpiryDisplayValue =
        item.expiry === "" ? "" : item.expiry.slice(0, 4) + "/" + item.expiry.slice(-2);
      const mappedResult = [
        [TK.merchantName, merchantObj[item.merchantId]],
        [TK.orderId, item.orderId],
        [TK.programName, item.programName],
        [TK.customerNo, item.customerNumber],
        [TK.cardType, item.type],
        [TK.cardProfileName, item.cardProfileName],
        [TK.status, t(EnumCardOrderStatus[item.status])],
        [TK.remark, item.remark],
        [TK.reissued, t(convertTrueFalseToYesNo(item.reissue))],
        [TK.expiryDate, cardExpiryDisplayValue],
        [TK.reissueReason, t(EnumCardReissueReason[item.reissueReason])],
        [TK.originalCardNo, item.oldCardNumber],
        [TK.samePan, t(convertTrueFalseToYesNo(item.samePan))],
        [TK.samePin, t(convertTrueFalseToYesNo(item.samePin))],
        [TK.deactivatedCurrentCard, t(convertTrueFalseToYesNo(item.deactivateCurrent))],
        [
          TK.deactivatedOriginalCardOnActivation,
          t(convertTrueFalseToYesNo(item.deactivateOnNewCardActivation)),
        ],
        [TK.creationTime, toDisplayTime(item.createdDate)],
        [TK.lastModifiedTime, toDisplayTime(item.lastModifiedDate)],
      ].filter(([key]) => !omitKeys.includes(key));
      return mappedResult;
    });

    const output = res.map(listMappingTransform);
    return output;
  };
  return listMapping;
};

export default ViewTable;
