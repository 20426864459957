import { DataGrid, GridRenderCellParams, GridRowsProp } from "@mui/x-data-grid";
import { dataGridDefaults } from "../../../../../utils/constant";
import {
  CustomPagination,
  NoRowsOverlay,
  TableTab
} from '../../../../../components/Layout'
import { useListMapping } from "./useListMapping";
import {  TABLE_ROWS_PER_PAGE, useApiCall } from "./useApiCall";
import { useColumn } from "./useColumn";
import { LoadingDialog } from "../../../../../components";
import DownloadCell from "./DownloadCell";

const MonthlyStatementTabContent = () => {

  const renderDownloadCell =
    (params: GridRenderCellParams<any, any, any>) => <DownloadCell {...params} />
  const { columns } = useColumn(renderDownloadCell);

  const listMapping = useListMapping();
  const {
    page,
    listRes,
    setPage
  } = useApiCall();
  const { data = [] } = (listRes as any as { data: any[] }) || {};
  const content: GridRowsProp = listMapping(data);
  const count = listRes ? data.length : 0;

  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;

  return (
    <TableTab>
      <DataGrid
        {...dataGridDefaults}
        rows={content}
        rowCount={count}
        columns={columns}
        page={page}
        onPageChange={setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: count },
        }}
        pageSize={TABLE_ROWS_PER_PAGE}
      />
    </TableTab>
  );
}

export default MonthlyStatementTabContent
