import { ChangeEvent, cloneElement, useState } from "react";

import { Box, Container } from "@mui/system";
import { EnumDepositStatus } from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master";
import {
  GeneralBtn,
  MpTextField,
  MultipleSelection,
  SingleSelection,
} from "../../../components";
import { useDatePicker } from "../../../components/DatePicker";
import { SelectChangeEvent } from "../../../components/MuiGenerals";
import CreateMerchantDepositRequestButton from "./components/CreateMerchantDepositRequest/CreateMerchantDepositRequestButton";
import CreateMerchantDepositRequestDialog from "./components/CreateMerchantDepositRequest/CreateMerchantDepositRequestDialog";
import { useGetMerchant, usePermission, useTranslation } from "../../../hooks";
import useCurrencyList from "../../../hooks/useCurrencyList";
import { useGenFilterField } from "../../../utils/ComponentHelper";
import { customSx } from "../../../utils/styling";
import {
  Ifield,
  initFields,
  initZusParams,
  IsearchParam,
  PermissionKey,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from "./config";
import useCollapsibleFilters from "../../../features/common/filterTable/components/Filters/CollapsibleFilters/hooks/useCollapsibleFilters";
import { Filter } from "../../../features/common/filterTable/types";
import FilterSectionActionRow from "../../../features/common/filterTable/layout/FilterSectionActionRow";
import CollapsibleFilters from "../../../features/common/filterTable/components/Filters/CollapsibleFilters";
import ToggleFilterButton from "../../../features/common/filterTable/components/Filters/CollapsibleFilters/components/ToggleFilterButton";

function ViewFilter() {
  const { t, tc } = useTranslation(translatePrefix);
  const [fields, setFields] = useState<Ifield>(initFields);
  const [
    isCreateMerchantDepositRequestDialogOpen,
    setIsCreateMerchantDepositRequestDialogOpen,
  ] = useState<boolean>(false);

  const { hasPermission } = usePermission();
  const merchantObj = useGetMerchant();
  const { CurrencyListEnum } = useCurrencyList();
  const { isCollapse, toggleFilterCollapse } = useCollapsibleFilters();

  const DateObj = {
    CreationTime: useDatePicker(),
    LastModifiedTime: useDatePicker(),
  };

  const zusParams = useZusParams();

  const isShowCreateRequestButton = hasPermission(PermissionKey.CreateRequest);

  const getParams: () => IsearchParam = () => {
    const { start: createdDateFrom, end: createdDateTo } = DateObj.CreationTime;
    const { start: lastModifiedDateFrom, end: lastModifiedDateTo } =
      DateObj.LastModifiedTime;

    return {
      ...initZusParams,
      ...fields,
      createdDateFrom,
      createdDateTo,
      lastModifiedDateFrom,
      lastModifiedDateTo,
    };
  };

  const apiParams = getParams();

  const onSearch = () => {
    zusParams.setBody({ ...apiParams, page: 0 });
    zusParams.refetch();
  };

  const onReset = () => {
    setFields(initFields);
    DateObj.CreationTime.clearDate();
    DateObj.LastModifiedTime.clearDate();
  };

  const onChange =
    (field: keyof typeof fields) =>
    (
      e:
        | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<string>
    ) => {
      setFields((fields) => ({ ...fields, [field]: e.target.value }));
    };

  const refreshTable = () => {
    zusParams.refetch();
  };

  const openCreateMerchantDepositRequestDialog = () => {
    setIsCreateMerchantDepositRequestDialogOpen(true);
  };

  const closeCreateMerchantDepositRequestDialog = () => {
    setIsCreateMerchantDepositRequestDialogOpen(false);
  };

  const fieldEleArr = useGenFilterField([
    [TK.creationTime, <DateObj.CreationTime.Picker type="dateTime" />],
    [TK.lastModifiedTime, <DateObj.LastModifiedTime.Picker type="dateTime" />],
    [
      TK.orderId,
      <MpTextField
        label={tc("phInputField", { fieldName: t(TK.orderId) })}
        value={fields.orderId}
        onChange={onChange("orderId")}
      />,
    ],
    [
      TK.merchantName,
      <SingleSelection
        label={tc("phSelection", { fieldName: t(TK.merchantName) })}
        value={fields.merchantId}
        onChange={onChange("merchantId")}
        clearSelect={() =>
          setFields((fields) => ({ ...fields, merchantId: "" }))
        }
        nameFn={(name) => name}
        isNoSorting
        enumData={merchantObj}
      />,
    ],

    [
      TK.currency,
      <MultipleSelection
        label={tc("phSelection", { fieldName: t(TK.currency) })}
        value={fields.currencies}
        onChange={(currencies) =>
          setFields((fields) => ({ ...fields, currencies }))
        }
        nameFn={(name) => name}
        enumData={CurrencyListEnum}
      />,
    ],
    [
      TK.status,
      <MultipleSelection
        label={tc("phSelection", { fieldName: t(TK.status) })}
        value={fields.statuses}
        onChange={(statuses) =>
          setFields((fields) => ({ ...fields, statuses }))
        }
        enumData={EnumDepositStatus}
      />,
    ],
  ]);

  const filters: Filter[] = [
    {
      labelKey: TK.creationTime,
      filter: <DateObj.CreationTime.Picker type="dateTime" />,
    },
    {
      labelKey: TK.lastModifiedTime,
      filter: <DateObj.LastModifiedTime.Picker type="dateTime" />,
    },
    {
      labelKey: TK.orderId,
      filter: (
        <MpTextField
          label={tc("phInputField", { fieldName: t(TK.orderId) })}
          value={fields.orderId}
          onChange={onChange("orderId")}
        />
      ),
    },
    {
      labelKey: TK.merchantName,
      filter: (
        <SingleSelection
          label={tc("phSelection", { fieldName: t(TK.merchantName) })}
          value={fields.merchantId}
          onChange={onChange("merchantId")}
          clearSelect={() =>
            setFields((fields) => ({ ...fields, merchantId: "" }))
          }
          nameFn={(name) => name}
          isNoSorting
          enumData={merchantObj}
        />
      ),
    },
    {
      labelKey: TK.currency,
      filter: (
        <MultipleSelection
          label={tc("phSelection", { fieldName: t(TK.currency) })}
          value={fields.currencies}
          onChange={(currencies) =>
            setFields((fields) => ({ ...fields, currencies }))
          }
          nameFn={(name) => name}
          enumData={CurrencyListEnum}
        />
      ),
    },
    {
      labelKey: TK.status,
      filter: (
        <MultipleSelection
          label={tc("phSelection", { fieldName: t(TK.status) })}
          value={fields.statuses}
          onChange={(statuses) =>
            setFields((fields) => ({ ...fields, statuses }))
          }
          enumData={EnumDepositStatus}
        />
      ),
    },
  ];

  return (
    <>
      <CreateMerchantDepositRequestDialog
        isOpen={isCreateMerchantDepositRequestDialogOpen}
        title={t("create_request")}
        refreshTable={refreshTable}
        closeDialog={closeCreateMerchantDepositRequestDialog}
      />
      <Container disableGutters maxWidth={false}>
        <FilterSectionActionRow>
          <CreateMerchantDepositRequestButton
            isShow={isShowCreateRequestButton}
            label={t("create_request")}
            handleClick={openCreateMerchantDepositRequestDialog}
          />
          <ToggleFilterButton
            isCollapse={isCollapse}
            onClick={toggleFilterCollapse}
          />
        </FilterSectionActionRow>

        <CollapsibleFilters
          isCollapse={isCollapse}
          filters={filters}
          onSearch={onSearch}
          onReset={onReset}
        />
      </Container>
    </>
  );
}

export default ViewFilter;
