import React, { useEffect, useState } from 'react';
import { Box } from '../../../../../components/MuiGenerals';
import { useTranslation } from '../../../../../hooks';
import { useZusDialogStore } from '../../../../../zustand/store';
import { apiObj as api, translateKeyObj as TK } from "../../config";
import BalanceBlock from './components/BalanceBlock';

interface IOfferingTabContentProps {
}

const OfferingTabContent: React.FC<IOfferingTabContentProps> = ({ }) => {
    const { t } = useTranslation('customerAccount');
    const zusDialog = useZusDialogStore();
    const {
        customerNumber,
        merchantId,
    } = zusDialog.meta;

    const [balanceData, setBalanceData] = useState<any>(null);

    async function fetchBalanceData() {
        const res = await api.getOffering({ customerNumber, merchantId });
        if (res) {
            setBalanceData(res);
        }
    }

    useEffect(() => {
        fetchBalanceData();
    }, []);

    return (
        <Box>
            <BalanceBlock balanceData={balanceData} />
        </Box>
    );
}

export default OfferingTabContent;
